import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import RenderInput from "../shared/RenderInput";
import { bindActionCreators } from "redux";
import { patchSchool } from "../actions";
import { handleChange } from "../shared/utils";


class SchoolSettings extends Component {
  state = {
    showUpload: false,
    images: [],

    // Form fields state -> don't use non nullish empty values or initialize form value will be ignored for these in value field
    schoolName: '',
    schoolWebsite: '',
  };

  handleBlur = () => {
    const value = {};
    const fieldName = event.target.name;
    value[fieldName] = event.target.value;
    this.props.patchSchool(this.props.school.pk, value);
    // TODO: to be hot reloaded, the name field should update the relevant redux state pocket
  };

  settingsForm = () => {
    return (
      <Form>
        <Row>
          <Col md={4}>
            <RenderInput
              label="School Name"
              name="schoolName"
              placeholder="School Name"
              value={this.state.schoolName}
              initializedValue={this.props.school?.schoolName}
              type="input"
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Col>
          <Col md={4}>
            <RenderInput
              label="School Website"
              name="schoolWebsite"
              placeholder="School Website"
              value={this.state.schoolWebsite}
              initializedValue={this.props.school?.schoolWebsite}
              type="input"
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Col>
        </Row>
      </Form>
    );
  };

  toggleProfileImage = () => {
    this.setState({ showUpload: !this.state.showUpload });
  };

  showUploadModal() {
    return (
      <Modal
        show={this.state.showUpload}
        bsSize="large"
        onHide={() => this.toggleProfileImage()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose a School Mascot Image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <input
            type="file"
            id="single"
            onChange={(e) => {
              const file = e.target.files[0];
              const formData = new FormData();
              formData.append("image", file);

              const url = `/image_upload?user_type=SCHOOL`;
              const token = localStorage.getItem("token");

              axios
                .put(url, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/x-www-form-urlencoded",
                  },
                })
                .then(() => {
                  caches
                    .keys()
                    .then((keyList) =>
                      Promise.all(keyList.map((key) => caches.delete(key)))
                    );
                  window.location.reload();
                  caches
                    .keys()
                    .then((keyList) =>
                      Promise.all(keyList.map((key) => caches.delete(key)))
                    );
                });
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }

  render () {
    if (this.props?.school) {
      return (
          <div style={{marginTop: "20px"}}>
            <div>
              <p>
                <strong>Update School Mascot Image</strong>
              </p>
            </div>
            <img
                src={this.props.logoPath}
                alt="logo"
                onError={this.addDefaultImage}
                className="logoStyle"
                onClick={() => {
                  this.toggleProfileImage();
                }}
            />
            {this.showUploadModal()}
            {this.settingsForm()}
          </div>
      );
    }
    return <p>No School found, please contact the Findfit team.</p>
  }
}

function mapStateToProps(state) {
  return {
    users: state.sharedReducer.users,
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    school: state.employerReducer.school,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      patchSchool,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSettings);
