
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

// Bootstrap Resources
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Panel, Row } from 'react-bootstrap';

// Import Helpers
import { barOptions } from '../../shared/utils';

// App Resources
import RenderInput from '../../shared/RenderInput';
import {
    getCurrentUser,
    postLanguage,
    getRawLanguages
} from '../../actions';
import moment from "moment";


const RenderLanguageSelect = ({ label, name, initializedValue, type, onChange, options=null }) => {
    // TODO: Add error checking

    if (!Array.isArray(options)) return null;

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={initializedValue ? initializedValue : null}
              onChange={onChange}
            >
                {
                    options.map(item => (
                        <option value={ item.pk } key={ item.pk }>{ item.languageNameEnglish }</option>
                    ))
                }
            </FormControl>
        </FormGroup>
    );
};

const RenderBarsSelect = ({ label, name, initializedValue, type, onChange, options=null }) => {
    // TODO: Add error checking

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={initializedValue ? initializedValue : null}
              onChange={onChange}
            >
                {
                    options.map(item => (
                        <option value={ item.pk } key={ item.pk }>{ item.level }</option>
                    ))
                }
            </FormControl>
        </FormGroup>
    );
};


class LanguageNewPanel extends Component {
    state = {
        editing: !this.props.hasExistingData,
        remarksPlaceholder: 'Remarks',
        localLanguage: {
            resumeVersion: null,
            language: 1,
            speaking: 1,
            reading: 1,
            writing: 1,
            remarks: ''
        }
    };

    async componentDidMount () {
        await this.props.getRawLanguages();
        await this.props.getCurrentUser();
        this.setState({
            localLanguage: {
                ...this.state.localLanguage,
                resumeVersion: this.props.resume.pk,
                user: this.props.currentUser.id
            }
        });
    }

    resetForms = () => {
        /* Resets state back to initial */
        this.setState({
            localLanguage: {
                resumeVersion: this.state.localLanguage.resumeVersion,
                language: 1,
                speaking: 1,
                reading: 1,
                writing: 1,
                remarks: ''
            }
        })
    };


    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
        this.resetForms();
    };

    // TODO: Repeated code between dialogs scream HOC
    updateField = (field, value) => {
        const modified = cloneDeep(this.state.localLanguage);
        modified[field] = value;
        this.setState({ localLanguage: modified })
    };

    onSubmit = () => {
        // if doesn't exist create one.
        this.props.postLanguage(this.state.localLanguage);
        this.toggleEdit();
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };
    
    renderForm = () => {
        const {
            language,
            speaking,
            reading,
            writing,
            remarks
        } = this.state.localLanguage;

        const rawLanguages = this.props.rawLanguages.data ? this.props.rawLanguages.data.results : null;

        return (
            <Panel.Body>
                <Form>
                    <Row>
                        <Col md={3}>
                            <RenderLanguageSelect
                              label='Language*'
                              name='language'
                              initializedValue={language}
                              type="select"
                              onChange={this.handleChange}
                              options={rawLanguages}
                            />
                        </Col>
                        <Col md={3}>
                            <RenderBarsSelect
                              label='Speaking*'
                              name='speaking'
                              initializedValue={speaking}
                              type="select"
                              onChange={this.handleChange}
                              options={barOptions}
                            />
                        </Col>
                        <Col md={3}>
                            <RenderBarsSelect
                              label='Reading*'
                              name='reading'
                              initializedValue={reading}
                              type="select"
                              onChange={this.handleChange}
                              options={barOptions}
                            />
                        </Col>
                        <Col md={3}>
                            <RenderBarsSelect
                              label='Writing*'
                              name='writing'
                              initializedValue={writing}
                              type="select"
                              onChange={this.handleChange}
                              options={barOptions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RenderInput
                              label='Remarks'
                              name='remarks'
                              type='textarea'
                              placeholder={this.state.remarksPlaceholder}
                              initializedValue={remarks}
                              onChange={this.handleChange}
                              rows={3}
                            />
                        </Col>
                    </Row>
                    <div className="modal-space-between-confirm-buttons">
                        <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                        <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.toggleEdit()}>Cancel</Button>
                    </div>
                </Form>
            </Panel.Body>
        );
    };

    render () {
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading onClick={() => this.toggleEdit()}>
                    <Row>
                        <Col md={11}>
                            <Glyphicon glyph="plus" />
                            <p><strong>{' '}Add a New Language</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                { this.state.editing ? this.renderForm() : null }
            </Panel>
        )
    }
}


function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        rawLanguages: state.resumeReducer.rawLanguages
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postLanguage,
            getCurrentUser,
            getRawLanguages
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageNewPanel);
