import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

// Component Specific Imports
import {
  editJobFair,
  getEmployersAttendingJobFairs,
  deleteEmployerJobFairMap,
  acceptEmployerJobFairMap,
} from "../actions";

// Bootstrap
import {
  Button,
  Row,
  Glyphicon,
  Modal,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import moment from "moment";
import RenderInput from "../shared/RenderInput";
import { cloneDeep } from "lodash";
import DatePicker from "react-datepicker/es";
import { is_form_valid, fixTimezoneOffset } from "../shared/utils";

const SCHOOL_ORGANIZER_TYPE = 1;

class SchoolJobFairTable extends Component {
  state = {
    startDate: Date.now(), // local state independent of localJobFair's nested date field only used for the calendar
    endDate: Date.now(), // local state independent of localJobFair's nested date field only used for the calendar
    localJobFair: {
      name: "",
      startDate: moment(Date.now()).format("YYYY-MM-DD"),
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
      organizer_id: null,
      organizer_type: SCHOOL_ORGANIZER_TYPE,
    },
  };

  componentDidUpdate(prevProps, _) {
    if (prevProps.editJobFairOpen !== this.props.editJobFairOpen) {
      if (this.props.jobFairList) {
        const jobFair = this.props.jobFairList.find(
          (eachJobFair) => eachJobFair.id === this.props.selectedJobFairId
        );

        if (jobFair) {
          this.setState({
            localJobFair: {
              ...this.state.localJobFair,
              name: jobFair.name,
              startDate: jobFair.startDate,
              endDate: jobFair.endDate,
            },
            startDate: fixTimezoneOffset(jobFair.startDate),
            endDate: fixTimezoneOffset(jobFair.endDate),
          });
          this.props.getEmployersAttendingJobFairs(jobFair.id);
        }
      }
    }
  }

  updateField = (field, value) => {
    const modifiedLocalJobFair = cloneDeep(this.state.localJobFair);
    modifiedLocalJobFair[field] = value;
    this.setState({ localJobFair: modifiedLocalJobFair });
  };

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
    }); // save  this to local datepicker component state.
    const dateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("startDate", dateFormatted);
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    }); // save  this to local datepicker component state.
    const dateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("endDate", dateFormatted);
  };

  onSubmit = () => {
    // validate forms.
    const { name, startDate, endDate, organizer_type } =
      this.state.localJobFair;

    if (!is_form_valid([name, startDate, endDate])) {
      alert("Errors in form - please correct before saving.");
      // don't save to db and don't toggle/erase data.
    } else if (endDate && endDate < startDate) {
      alert("End Date must be after your Start Date");
    } else {
      this.props.editJobFair({
        jobFairId: this.props.selectedJobFairId,
        name,
        startDate,
        endDate,
        organizer_id: this.props.organizer_id,
        organizer_type,
        // todo: in the future support managing company membership and acceptance here
        // todo: and notifaction that each job fair may need to address like company requesting to join
      });

      this.props.toggleEditJobFair();
      window.location.reload();
    }
  };

  createCompaniesAttendingTable(isAccepted = true) {
    let companyList = [];

    if (!isEmpty(this.props?.employersJobFair)) {
      companyList = isAccepted
        ? this.props.employersJobFair.companiesAccepted
        : this.props.employersJobFair.companiesAwaitingAcceptance;
    }

    if (!companyList?.length) {
      return null;
    } else {
      const tableHeader = (
        <div>
          <Row style={{ height: "40px", backgroundColor: "#e6ecf5" }}>
            <div
              className="col-sm-10 vertical-align"
              style={{ height: "100%" }}
            >
              <p>
                <strong>Company</strong>
              </p>
            </div>
            <div
              className="col-sm-2 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <strong>Action</strong>
            </div>
          </Row>
        </div>
      );

      const tableBody = companyList.map((eachCompany) => {
        return (
          <div key={eachCompany.pk}>
            <Row style={{ height: "40px" }}>
              <div
                className="col-sm-8 vertical-align"
                style={{ height: "100%" }}
              >
                <p>
                  <strong>{eachCompany.companyName}</strong>
                </p>
              </div>
              <div
                className="col-sm-4 vertical-align vhr"
                style={{ height: "100%" }}
              >
                {isAccepted ? (
                  <Button
                    bsStyle="danger"
                    bsSize="small"
                    onClick={() =>
                      this.props.deleteEmployerJobFairMap(
                        eachCompany.employer_company_map_id
                      )
                    }
                  >
                    {/* TODO: in the future add delete modal for comfirmation */}
                    Rescind Acceptance
                  </Button>
                ) : (
                  <Button
                    bsStyle="primary"
                    bsSize="small"
                    onClick={async () =>
                      {
                        await this.props.acceptEmployerJobFairMap(
                            eachCompany.employer_company_map_id,
                        );
                        window.location.reload();
                      }
                    }
                  >
                    {/* todo: above not toggle but say accept emplyoer job fair map..., sets fthe flag to one*/}
                    Accept
                  </Button>
                )}
              </div>
            </Row>
            <hr className="hrZeroMargin" />
          </div>
        );
      });

      return (
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%" }}>
            <Row>
              <strong>
                {isAccepted
                  ? "Companies Attending"
                  : "Companies Awaiting Acceptance"}
              </strong>
            </Row>
            {tableHeader}
            {tableBody}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal
        show={this.props.editJobFairOpen}
        bsSize="large"
        onHide={() => this.props.toggleEditJobFair()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Job Fair</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <RenderInput
                label="Job Fair Name"
                name="name"
                placeholder={this.state.localJobFair.name}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup controlId="startDate">
                <ControlLabel>Start Date*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.startDate} // date picker uses its own state, not the one we send to the API
                  onChange={this.handleStartDateChange}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup controlId="endDate">
                <ControlLabel>End Date*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.endDate} // date picker uses its own state, not the one we send to the API
                  onChange={this.handleEndDateChange}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>

          {this.createCompaniesAttendingTable(false)}
          {this.createCompaniesAttendingTable(true)}
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.onSubmit()}>
            Save Changes
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.props.toggleEditJobFair()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    employersJobFair: state.employerReducer.employersJobFair,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editJobFair,
      getEmployersAttendingJobFairs,
      deleteEmployerJobFairMap,
      acceptEmployerJobFairMap,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolJobFairTable);
