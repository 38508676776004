
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Libs
import moment from 'moment'

// App Resources
import HighlightsEditPanel from  './HighlightsEditPanel';
import { deleteHighlight, getCurrentUser, toggleDeleteResumeItemModal } from '../../actions';

// Bootstrap Resources
import { Glyphicon } from "react-bootstrap";

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class HighlightsPanel extends Component {
    state = {
        editing: false // same as show: false
    };

    async componentDidMount () {
        await this.props.getCurrentUser();
    }

    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    showDeleteModal = (highlight_id) => {
        this.props.toggleDeleteResumeItemModal(
            'highlight',
            () => this.props.deleteHighlight(highlight_id)
        );
    };

    renderBody = (highlight, companyName) => {
        return (this.state.editing
            ? <HighlightsEditPanel highlight={highlight} companyName={companyName} toggleEdit={this.toggleEdit} />
            : this.renderPreview(highlight, companyName));
    };

    renderPreview = (highlight, companyName) => (
        <div className="row">
            <h5 className="col-sm-2 text-right" style={{ marginTop: "0" }}><strong>{ highlight.quantity }</strong></h5>
            <p className="col-sm-7">{ highlight.fullDescription }</p>
            <div className="col-sm-2 text-left">
                <p>{ companyName }</p>
                <br/>
                <p>{ moment(highlight.highlightDate).format('YYYY') }</p>
            </div>
            <div className="col-sm-1 text-left">
                {
                    resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                        (
                            <div>
                                <Glyphicon glyph="pencil" onClick={this.toggleEdit} />
                                &nbsp;
                                <Glyphicon glyph="remove" onClick={() => this.showDeleteModal(highlight.pk)} />
                            </div>
                        ) :
                        null
                }
            </div>
        </div>
    );

    render () {
        const { highlight, companyName } = this.props;
        return this.renderBody(highlight, companyName);
    }
}


function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,  // assumes resume state is fetched into redux when this page loads
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            getCurrentUser,
            deleteHighlight,
            toggleDeleteResumeItemModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsPanel);
