import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const ResetPassword = () => {
  const [showNextStep, setShowNextStep] = useState(false);
  const [showApiError, setShowApiError] = useState(false);

  return (
    <div className="horizontal-align">
      {!showNextStep && (
        <div className="cardStyle" style={{ marginTop: "20px" }}>
          <h3>Update your new password</h3>
          <br />
          <Formik
            initialValues={{ email: "", password: "", confirm_password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              } else if (!values.password) {
                errors.password = "Password is required.";
              } else if (!values.confirm_password) {
                errors.password = "Password Confirmation is required.";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              const url = `/reset_password`;
              const current_page_url = window.location.href;
              values["token"] = current_page_url.split("/").pop();

              axios.post(url, values).then((response) => {
                if (response.status === 200) {
                  setShowNextStep(true);
                } else {
                  setShowApiError(true);
                }
              });
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <label>Email</label>
                <Field type="email" name="email" style={{ width: "100%" }} />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "#d9534f" }}
                />

                <label>Password</label>
                <Field
                  type="password"
                  name="password"
                  style={{ width: "100%" }}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  style={{ color: "#d9534f" }}
                />

                <label>Confirm Password</label>
                <Field
                  type="password"
                  name="confirm_password"
                  style={{ width: "100%" }}
                />
                <ErrorMessage
                  name="confirm_password"
                  component="div"
                  style={{ color: "#d9534f" }}
                />
                <Button
                  bsStyle="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {showNextStep && (
        <h5 style={{ marginTop: "20px" }}>
          Success! Please <Link to={`/home/`}>login</Link> with your new
          password.
        </h5>
      )}

      {/*{showApiError && (*/}
      {/*  <h5 style={{ marginTop: "20px" }}>*/}
      {/*    Error occured while updating your password. Please try again.*/}
      {/*  </h5>*/}
      {/*)}*/}
    </div>
  );
};

export default ResetPassword;
