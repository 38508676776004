
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

// App Resources
import RenderInput from '../../shared/RenderInput';
import {
    postLanguage,
    putLanguage,
    getLanguages,
    getRawLanguages
} from '../../actions';

// Bootstrap Resources
import { Button, ControlLabel, Form, FormGroup, FormControl, Panel, Row, Col } from 'react-bootstrap';

// Import Helpers
import { barOptions } from '../../shared/utils';


const RenderLanguageSelect = ({ label, name, initializedValue, type, onChange, options=null }) => {
    // TODO: Add error checking

    if (!Array.isArray(options)) return null;

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={initializedValue ? initializedValue : null}
              onChange={onChange}
            >
                {
                    options.map(item => (
                        <option value={ item.pk } key={ item.pk }>{ item.languageNameEnglish }</option>
                    ))
                }
            </FormControl>
        </FormGroup>
    );
};

const RenderBarsSelect = ({ label, name, initializedValue, type, onChange, options=null }) => {
    // TODO: Add error checking

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={initializedValue ? initializedValue : null}
              onChange={onChange}
            >
                {
                    options.map(item => (
                        <option value={ item.pk } key={ item.pk }>{ item.level }</option>
                    ))
                }
            </FormControl>
        </FormGroup>
    );
};

class LanguageEditPanel extends Component {
    // Constructor
    state = {
        // input placeholders are different from the default values used in dropdowns
        remarksPlaceholder: 'Remarks',
        localLanguage: cloneDeep(this.props.language)
    };

    componentDidMount () {
        this.props.getRawLanguages();
    }

    updateLanguageField = (field, pk, value) => {
        const modifiedLanguage = cloneDeep(this.state.localLanguage);
        modifiedLanguage[field] = value;
        this.setState({ localLanguage: modifiedLanguage })
    };

    onSubmit = () => {
        // if doesn't exist create one.
        this.state.localLanguage ? this.props.putLanguage(this.state.localLanguage, this.state.localLanguage.pk) : this.props.postLanguage(this.state.localLanguage);
        this.props.toggleEdit();
    };

    handleChange = () => {
        this.updateLanguageField(event.target.name, this.props.language.pk, event.target.value);
    };

    renderForm = () => {
        const {
            language,
            speaking,
            reading,
            writing,
            remarks
        } = this.state.localLanguage

        const rawLanguages = this.props.rawLanguages.data ? this.props.rawLanguages.data.results : null;

        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <RenderLanguageSelect
                          label='Language*'
                          name='language'
                          initializedValue={language}
                          type="select"
                          onChange={this.handleChange}
                          options={rawLanguages}
                        />
                    </Col>
                    <Col md={3}>
                        <RenderBarsSelect
                          label='Speaking*'
                          name='speaking'
                          initializedValue={speaking}
                          type="select"
                          onChange={this.handleChange}
                          options={barOptions}
                        />
                    </Col>
                    <Col md={3}>
                        <RenderBarsSelect
                          label='Reading*'
                          name='reading'
                          initializedValue={reading}
                          type="select"
                          onChange={this.handleChange}
                          options={barOptions}
                        />
                    </Col>
                    <Col md={3}>
                        <RenderBarsSelect
                          label='Writing*'
                          name='writing'
                          initializedValue={writing}
                          type="select"
                          onChange={this.handleChange}
                          options={barOptions}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <RenderInput
                          label='Remarks'
                          name='remarks'
                          type='textarea'
                          placeholder={this.state.remarksPlaceholder}
                          initializedValue={remarks}
                          onChange={this.handleChange}
                          rows={3}
                        />
                    </Col>
                </Row>
                <div className="modal-space-between-confirm-buttons">
                    <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                    <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.props.toggleEdit()}>Cancel</Button>
                </div>
            </Form>
        );
    };

    render () {
        return (
            <Panel>
                <Panel.Heading>
                    <p>{ this.props.language.languageNameEnglish }</p>
                </Panel.Heading>
                <Panel.Body>
                    { this.renderForm() }
                </Panel.Body>
            </Panel>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        language: ownProps.language,
        rawLanguages: state.resumeReducer.rawLanguages
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postLanguage,
            putLanguage,
            getLanguages,
            getRawLanguages // not loading data to store...
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(LanguageEditPanel);