
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// Component Specific Imports
import { getCurrentUser } from '../actions';

// Bootstrap Resources
import { Row, Col } from 'react-bootstrap';

// App Resources
import PrivateRoute from '../components/PrivateRoute';
import EmployerTermsConditions from './EmployerTermsConditions';
import EmployerAddProfile from './EmployerAddProfile';


const contentStyle = {
    minHeight: '100%'
};


class EmployerOnboarding extends Component {

    content () {
        const { match } = this.props;

        return (
            <Row style={contentStyle}>
                <Col md={12} style={{ backgroundColor: "#fcfafa", height: '800px' }}>
                    <Row>
                        <Switch>
                            <PrivateRoute path={`${match.path}/terms_conditions`} component={EmployerTermsConditions} />
                            <PrivateRoute path={`${match.path}/add_profile`} component={EmployerAddProfile} />
                        </Switch>
                    </Row>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            this.content()
        );
    }
}


function mapDispatchToProps(dispatch){
    return bindActionCreators({ getCurrentUser }, dispatch);
}

export default connect(null, mapDispatchToProps)(EmployerOnboarding);
