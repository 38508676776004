// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// App Resources
import { handle_login, handle_signup } from "../actions";

// Bootstrap Resources
import {
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

class LoginForm extends Component {
  state = {
    signup: true,
    username: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  toggleSignup = () => {
    this.setState({ signup: !this.state.signup });
  };

  updateField = (field, value) => {
    this.setState({ [field]: value });
  };

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  async onSubmit(workflow) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const redirect_to = params.get("redirect_to");

    if (workflow === "login") {
      await this.props.handle_login(this.state.username, this.state.password);
      if (redirect_to) {
        this.props.history.push(redirect_to);
      } else {
        this.props.history.push("/home/dashboard");
      }
    } else {
      await this.props.handle_signup(
        this.state.username,
        this.state.password,
        this.state.firstName,
        this.state.lastName
      );

      if (!this.props.sign_up_error) {
        // this.props.history.push('/home/onboarding/terms_conditions');
        this.props.history.push("/home/activation_email_confirm");
      }
    }
  }

  signup() {
    const errors =
      this.props.sign_up_error &&
      this.props.sign_up_error.response &&
      this.props.sign_up_error.response.data
        ? this.props.sign_up_error.response.data
        : {};

    return (
      <Form>
        <h2 style={{ marginBottom: "10px" }}>
          <strong>Sign Up</strong>
        </h2>
        <FormGroup style={{ marginBottom: "15px" }}>
          <Row>
            <Col md={6}>
              <FormControl
                placeholder="First name"
                name="firstName"
                onChange={this.handleChange}
              />
            </Col>
            <Col md={6}>
              <FormControl
                placeholder="Last name"
                name="lastName"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup controlId="formBasicEmail">
          <FormControl
            type="email"
            placeholder="Enter email"
            name="username"
            onChange={this.handleChange}
          />
          <p style={{ color: "red" }}>
            {errors.hasOwnProperty("username") ? errors.username : null}
          </p>
        </FormGroup>

        {/* In the meantime can assume username to be email for signing up new accounts */}

        <FormGroup controlId="formBasicPassword">
          <FormControl
            type="password"
            placeholder="Password"
            name="password"
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup className="flex-container-row flex-space-between flex-align-center">
          <Button
            bsStyle="success"
            style={{ marginRight: "8px" }}
            onClick={() => this.onSubmit("signup")}
          >
            <strong>Sign Up</strong>
          </Button>
          <p
            onClick={this.toggleSignup}
            style={{ color: "#6666FF", cursor: "pointer" }}
          >
            <strong>Already have an account? Login Here</strong>
          </p>
        </FormGroup>
      </Form>
    );
  }

  login() {
    return (
      <Form>
        <h2 style={{ marginBottom: "10px" }}>
          <strong>Login</strong>
        </h2>
        <FormGroup controlId="formBasicEmail">
          <FormControl
            type="text"
            placeholder="Enter email or username"
            name="username"
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="formBasicPassword" style={{ marginBottom: "5px" }}>
          <FormControl
            type="password"
            placeholder="Password"
            name="password"
            onChange={this.handleChange}
          />
        </FormGroup>

        <div style={{ marginBottom: "20px" }}>
            <Link to={`/home/reset_password_request`}>Forgot Password?</Link>
        </div>

        <FormGroup className="flex-container-row flex-space-between flex-align-center">
          <Button
            bsStyle="success"
            style={{ marginRight: "8px" }}
            onClick={() => this.onSubmit("login")}
          >
            <strong>Login</strong>
          </Button>
          <p
            onClick={this.toggleSignup}
            style={{ color: "#6666FF", cursor: "pointer" }}
          >
            <strong>New to Findfit? Sign Up Here</strong>
          </p>
        </FormGroup>
      </Form>
    );
  }

  render() {
    return (
      <Row>
        <Col
          sm={8}
          style={{
            backgroundColor: "#FFF",
            marginLeft: "20px",
            marginTop: "50px",
            paddingTop: "0px",
            paddingBottom: "0px",
            borderRadius: "10px",
          }}
          className="text-left"
        >
          {this.state.signup ? this.signup() : this.login()}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    sign_up_error: state.sharedReducer.sign_up_error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      handle_login,
      handle_signup,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
