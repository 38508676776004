import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// Component Specific Imports
import {
    getCurrentUser,
    getProfile,
    patchProfile,
    getCareerTracks,
    updateProfileField,
} from '../actions';
import RenderInput from '../shared/RenderInput';
import RenderSelect from '../shared/RenderSelect';

// Bootstrap Resources
import { Button, Col, Panel, Row, Form, ControlLabel } from 'react-bootstrap';


class AddProfile extends Component {

    componentDidMount () {
        this.props.getCurrentUser();
        this.props.getProfile();
        this.props.getCareerTracks();
    }

    async onSubmit () {
        // this.props.profile not exist, raise error to sentry
        const {
            city,
            state,
            country,
            idealPosition,
            ifCurrentlySeeking,
            ifSponsorship,
            phoneNumber,
            careerTrack,
        } = this.props.profile;

        await this.props.patchProfile({
            city,
            state,
            country,
            idealPosition,
            ifCurrentlySeeking,
            ifSponsorship,
            phoneNumber,
            careerTrack,
        }, this.props.profile.pk);
        this.props.history.push('/home/dashboard');
    };

    updateField = (field, value) => {
        // have this update redux store based on field in profile state via action directly
        this.props.updateProfileField(field, value);
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    profileForm = () => {
        if ( _.isEmpty(this.props.profile)) return null;

        const {
            city,
            state,
            country,
            idealPosition,
            ifCurrentlySeeking,
            ifSponsorship,
            phoneNumber,
            careerTrack,
        } = this.props.profile;

        return(
            <Form>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='City*'
                          name='city'
                          placeholder='City'
                          initializedValue={city}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderInput
                          label='State*'
                          name='state'
                          placeholder='State'
                          initializedValue={state}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderSelect
                          label='Country*'
                          name='country'
                          type='select'
                          initializedValue={country}
                          onChange={this.handleChange}
                          options={[
                              {id:1, name: 'USA'}
                          ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='Ideal Position*'
                          name='idealPosition'
                          placeholder='Ideal Position'
                          initializedValue={idealPosition}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <ControlLabel>Phone Number*</ControlLabel>
                        <PhoneInput
                          placeholder='Phone Number'
                          country={'us'}
                          value={phoneNumber}
                          onChange={this.handleChange}
                          inputProps={{
                              name: 'phoneNumber'
                          }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderSelect
                          label='Actively searching for jobs?*'
                          name='ifCurrentlySeeking'
                          type='select'
                          initializedValue={ifCurrentlySeeking}
                          onChange={this.handleChange}
                          options={[
                              {id: false, name: 'No'},
                              {id: true, name: 'Yes'}
                          ]}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderSelect
                          label='Career Track*'
                          name='careerTrack'
                          type='select'
                          initializedValue={careerTrack}
                          onChange={this.handleChange}
                          options={this.props.careerTracks}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderSelect
                          label='Do you require sponsorship to work in this country?'
                          name='ifSponsorship'
                          type='select'
                          initializedValue={ifSponsorship}
                          onChange={this.handleChange}
                          options={[
                              {id: false, name: 'No'},
                              {id: true, name: 'Yes'}
                          ]}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    content () {
        return (
            <div
              className='col-sm-8 col-sm-offset-2'
              style={{ marginTop: "35px" }}
            >
                <Panel style={{ marginBottom: '15px' }}>
                    <Panel.Heading>
                        <Row>
                            <Col md={11}>
                                <p><strong>Basic Information</strong></p>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body>
                        { this.profileForm() }
                    </Panel.Body>
                    <Panel.Footer>
                        <Button
                          bsStyle="primary"
                          onClick={() => this.onSubmit()}
                          style={{ marginLeft: '5px' }}
                        >
                            Save and Continue
                        </Button>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }

    render() {
        return (
            this.content()
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.sharedReducer.currentUser,
        profile: state.sharedReducer.profile,
        careerTracks: state.sharedReducer.careerTracks,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getCurrentUser,
        getProfile,
        patchProfile,
        getCareerTracks,
        updateProfileField,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProfile);
