// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

// Shared Resources
import { is_form_valid } from "../../shared/utils";

// App Resources
import RenderInput from "../../shared/RenderInput";
import { getCurrentUser, postSkillGroup } from "../../actions";

// Bootstrap Resources
import { Button, Col, Form, Glyphicon, Panel, Row } from "react-bootstrap";

class SkillGroupNewPanel extends Component {
  state = {
    editing: false, // same as show: false
    localSkillGroup: {
      user: null,
      resumeVersion: null,
      skillGroupName: "",
      order: 1, // TODO: Set new skill group order to be the last one (based on len skillgroups)
    },
  };

  async componentDidMount() {
    // set the user
    await this.props.getCurrentUser();
    this.setState({
      localSkillGroup: {
        ...this.state.localSkillGroup,
        resumeVersion: this.props.resume.pk,
        user: this.props.currentUser.id,
      },
    });
  }

  toggleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  // TODO: Repeated code between dialogs scream HOC
  updateField = (field, value) => {
    const modifiedHighlight = cloneDeep(this.state.localSkillGroup);
    modifiedHighlight[field] = value;
    this.setState({ localSkillGroup: modifiedHighlight });
  };

  onSubmit = () => {
    // validate forms.
    const { skillGroupName } = this.state.localSkillGroup;

    if (!is_form_valid([skillGroupName])) {
      return;
      // don't save to db and don't toggle/erase data.
    }
    // if doesn't exist create one.
    this.props.postSkillGroup(this.state.localSkillGroup);
    this.toggleEdit();
  };

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  renderForm = () => {
    const { skillGroupName } = this.state.localSkillGroup;

    return (
      <Panel.Body>
        <Form>
          <Row>
            <Col md={4}>
              <RenderInput
                label="Skill Group Name*"
                name="skillGroupName"
                placeholder="Skill Group Name"
                initializedValue={skillGroupName}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <div className="modal-space-between-confirm-buttons">
            <Button bsStyle="primary" onClick={() => this.onSubmit()}>
              Save
            </Button>
            <Button
              bsStyle="warning"
              style={{ marginLeft: "5px" }}
              onClick={() => this.toggleEdit()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Panel.Body>
    );
  };

  render() {
    return this.state.editing ? (
      <Panel style={{ marginTop: "15px" }}>
        <Panel.Heading style={{ backgroundColor: "#5cb85c", color: "#fff" }}>
          <Row>
            <Col md={11}>
              <Glyphicon glyph="plus" />
              <p>
                <strong>
                  {" "}
                  Add a New Skill <em>Group</em>
                </strong>
              </p>
            </Col>
          </Row>
        </Panel.Heading>
        {this.renderForm()}
      </Panel>
    ) : (
      <Button
        bsStyle="primary"
        bsSize="small"
        onClick={() => this.toggleEdit()}
      >
        <Glyphicon glyph="plus" /> Add a Skill Group
      </Button>
    );
  }
}

function mapStateToProps(state) {
  return {
    resume: state.resumeReducer.resume,
    currentUser: state.sharedReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postSkillGroup,
      getCurrentUser,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillGroupNewPanel);
