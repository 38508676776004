import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import LoadingIndicator from "../../shared/LoadingIndicator";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";


const FileUploadForm = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      alert('Please select a file');
      return;
    }

    try {
      setShowSpinner(true);
      const formData = new FormData();
      formData.append('pdfFile', selectedFile);

      const url = `/resume/parse-resume-ai/`;
      const token = localStorage.getItem('token');
      const response = await axios.post(url, formData, {
        headers: {"Authorization" : `Bearer ${token}`}
      });

      setShowSpinner(false);
      props.onHide();
      window.location.reload();
    } catch (error) {
      // Handle errors
      console.error('Error uploading file:', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Select a PDF file:
        <input type="file" accept=".pdf" onChange={handleFileChange} />
      </label>
      <br />
      <br />
      <p>We recommend uploading an existing resume or uploading your LinkedIn profile as a PDF.</p>
      <br />
      <br />
      {showSpinner && <LoadingIndicator />}
      <Button bsStyle="primary" type="submit">
        Upload
      </Button>
    </form>
  );
};


const ResumeUploadModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      bsSize="large"
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload an Existing Resume</Modal.Title>
      </Modal.Header>

      <Modal.Body>
          <FileUploadForm onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};


export default ResumeUploadModal
