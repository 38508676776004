import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

// Component Specific Imports
import {
  getCurrentUser,
  getProfile,
  getJobOpening,
  getJobOpeningSkillMap,
  getCompanies,
  postCompany,
  putJobOpening,
  applyToJob,
  getResumes,
  getJobOpeningApplicantMap,
  postSkill,
  getSkills,
} from "../actions";

// Component Specific Imports
import RenderInput from "../shared/RenderInput";

// Bootstrap Resources
import { Button, Row, Modal, Col, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import RenderSelect from "../shared/RenderSelect";

const pageStyle = {
  minHeight: "calc(100vh)",
  backgroundColor: "#e8e8e8",
};

const contentStyle = {
  marginTop: "20px",
  paddingBottom: "40px",
  backgroundColor: "#fff",
  minHeight: "400px",
};

const center = {
  display: "flex",
  justifyContent: "center",
  whiteSpace: "pre-line",
};

const rightBox = {
  minHeight: "400px",
  borderLeftColor: "#DEDCDC",
  borderLeftStyle: "solid",
  borderLeftWidth: "1px",
  borderLeftRadius: "3px",
};

class JobOpening extends Component {
  state = {
    form_company_id: null,
    editJobOpen: false,
    applyOpen: false,
    resume_id: null, // used for apply flow only.
    localJobOpening: cloneDeep(this.props.jobOpening), // for initializing empty object from Redux store null state on initial payload, used only for editing job opening.
    skill_ids: new Set(), // We need this to maintain current state of selected skill_ids locally outside the form under hood logic.
  };

  async componentDidMount() {
    const jobOpeningId = this.props.match.params.pk;
    await this.props.getProfile();
    await this.props.getCurrentUser();
    await this.props.getJobOpening(jobOpeningId);
    await this.props.getJobOpeningSkillMap(jobOpeningId);
    await this.props.getCompanies();
    await this.props.getResumes();
    await this.props.getSkills();
    await this.props.getJobOpeningApplicantMap(null); // for candidate to see if candidate already applied
    this.setState({
      localJobOpening: cloneDeep(this.props.jobOpening),
      form_company_id: this.props.jobOpening.company.pk,
    }); // after mount, redux store has loaded data into jobOpening data loaded here from backend.
  }

  toggleEditJob = () => {
    this.setState({
      editJobOpen: !this.state.editJobOpen,
      skill_ids: new Set(), // rest on modal close.
    });
  };

  toggleApply = () => {
    this.setState({
      applyOpen: !this.state.applyOpen,
    });
  };

  updateApplyField = (field, value) => {
    // Update the resume selected for the job application.
    this.setState({ resume_id: value });
  };

  updateEditJobOpeningField = (field, value) => {
    const modified = cloneDeep(this.state.localJobOpening);

    if (field === "form_company_id") {
      if (Array.isArray(value) && value[0].customOption) {
        const companyName = value[0].label;
        if (companyName.length) {
          this.props
            .postCompany({
              companyName: companyName,
            })
            .then((res) => {
              value = res.payload.data.pk;
              this.setState({ form_company_id: value });
              return;
            });
        }
      } else {
        this.setState({ form_company_id: value });
      }
    }

    if (field === "skill_id") {
      if (Array.isArray(value) && value[value.length - 1].customOption) {
        const skillName = value[value.length - 1].label;
        if (skillName.length) {
          this.props
            .postSkill({
              skillName,
            })
            .then((res) => {
              value = res.payload.data.pk;
              // update multi select state, can't update state directly, so use clonedeep
              const modified_skill_ids = cloneDeep(this.state.skill_ids);
              modified_skill_ids.add(value);
              this.setState({ skill_ids: modified_skill_ids });
              return;
            });
        }
      } else {
        // handle array of skill_ids here.
        // update multi select state, can't update state directly, so use clonedeep
        const modified_skill_ids = cloneDeep(this.state.skill_ids);
        modified_skill_ids.add(value);
        this.setState({ skill_ids: modified_skill_ids });
        return;
      }
    }

    modified[field] = value;
    this.setState({ localJobOpening: modified });
  };

  handleChange = (apply) => {
    if (apply) {
      this.updateApplyField(event.target.name, event.target.value);
    } else {
      this.updateEditJobOpeningField(event.target.name, event.target.value);
    }
  };

  // Save edit to database if recruiter
  async editJobOpening() {
    const pk = this.props.jobOpening.pk;
    const { role, description } = this.state.localJobOpening;

    await this.props.putJobOpening({
      job_opening_pk: pk,
      role,
      description,
      form_company_id: this.state.form_company_id,
      skill_ids: [...this.state.skill_ids], // Serialize set to array for passing over RESTful API
      employer_internal_job_id: null, // not used yet
    });
    await this.props.getJobOpeningSkillMap(pk);
    this.toggleEditJob();
  }

  // Save application to database if it is a candidate applying.
  async apply() {
    const { resume_id } = this.state;

    await this.props.applyToJob({
      job_opening: this.props.jobOpening.pk,
      user: this.props.currentUser.id,
      resume: resume_id,
    });
    this.toggleApply();
    this.props.history.push("/home/employer/jobopeninglist/");
  }

  alreadyApplied = () => {
    // only used for jobseeker
    let hasAlreadyApplied = false;

    this.props.jobOpeningApplicantMap.forEach((each) => {
      if (each.job_opening === this.props.jobOpening.pk) {
        hasAlreadyApplied = true;
        return; // return early from the loop when a match found
      }
    });

    // above return doesn't return from the function, only from the loop, thus 2nd return here
    return hasAlreadyApplied;
  };

  editModal() {
    const { role, description } = this.state.localJobOpening; // extract data for loading from state to the forms

    // const companiesOptionsList = this.props.companies ? this.props.companies : [];
    // const defaultSelectedCompany = companiesOptionsList.filter(obj =>
    //     obj.pk === this.state.form_company_id
    // );

    const skillsOptionsList = this.props.skills ? this.props.skills : [];

    return (
      <Modal
        show={this.state.editJobOpen}
        bsSize="large"
        onHide={() => this.toggleEditJob()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Job Opening</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={10}>
              <RenderInput
                label="Role"
                name="role"
                initializedValue={role}
                type="input"
                onChange={() => this.handleChange(false)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <RenderInput
                label="Description"
                name="description"
                type="textarea"
                initializedValue={description}
                onChange={() => this.handleChange(false)}
                rows={3}
              />
            </Col>
          </Row>
          {/*<Row>*/}
          {/*    <Col md={3}>*/}
          {/*        <div style={{ paddingBottom: '5px' }}><strong>Company*</strong></div>*/}
          {/*        <Typeahead*/}
          {/*          allowNew*/}
          {/*          labelKey={option => `${option.companyName}`}*/}
          {/*          options={companiesOptionsList}*/}
          {/*          placeholder='Start typing a company name'*/}
          {/*          style={{ marginBottom: '10px' }}*/}
          {/*          defaultSelected={defaultSelectedCompany}*/}
          {/*          onChange={selected => {*/}
          {/*            if (selected.length > 0) {*/}
          {/*                if (selected[0].pk) {*/}
          {/*                    this.updateEditJobOpeningField('form_company_id', selected[0].pk);*/}
          {/*                } else {*/}
          {/*                    this.updateEditJobOpeningField('form_company_id', selected);*/}
          {/*                }*/}
          {/*              }*/}
          {/*            }*/}
          {/*          }*/}
          {/*        />*/}
          {/*    </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col md={8}>
              <div style={{ paddingBottom: "5px" }}>
                <strong>Skills</strong>
              </div>
              <Typeahead
                multiple
                allowNew
                labelKey={(option) => `${option.skillName}`}
                options={skillsOptionsList}
                placeholder="Start typing some skill names"
                style={{ marginBottom: "10px" }}
                onChange={(selected) => {
                  if (selected.length > 0) {
                    const new_added_item_index = selected.length - 1;
                    if (selected[new_added_item_index].pk) {
                      this.updateEditJobOpeningField(
                        "skill_id",
                        selected[new_added_item_index].pk
                      );
                    } else {
                      // Handle creating new item (ie it doesn't have a pk yet)
                      this.updateEditJobOpeningField("skill_id", selected);
                    }
                  }
                }}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.editJobOpening()}>
            Update
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleEditJob()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  applyModal() {
    const { resumes } = this.props;
    const resumeList = resumes && resumes.length ? cloneDeep(resumes) : [];
    resumeList.forEach((each) => {
      each.id = each.pk;
      each.name = each.resumeName;
    });

    resumeList.unshift({
      id: 0,
      name: "Pick a resume to apply with.",
    });

    return (
      <Modal
        show={this.state.applyOpen}
        bsSize="large"
        onHide={() => this.toggleApply()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col md={3}>
                <RenderSelect
                  label="Choose a resume to apply with"
                  name="resume_id"
                  type="select"
                  onChange={() => this.handleChange(true)}
                  options={resumeList}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.apply()}>
            Apply
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleApply()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  content() {
    const { jobOpening, jobOpeningSkillMap, profile } = this.props;

    const applyButton = (
      <Button
        bsStyle="primary"
        onClick={() => this.toggleApply()}
        disabled={
          this.alreadyApplied() || profile?.isRecruiter || profile?.isSchoolUser
        }
      >
        {this.alreadyApplied() ? "Applied" : "Apply"}
      </Button>
    );

    const editJobOpening = (
      <Button bsStyle="primary" onClick={() => this.toggleEditJob()}>
        Edit
      </Button>
    );

    if (jobOpening) {
      const { company } = jobOpening;
      const profile =
        this.props.profile &&
        this.props.profile.data &&
        this.props.profile.data.results
          ? this.props.profile.data.results[0]
          : null;

      let companyWebsiteBlock = null;
      if (company.companyWebsite) {
        companyWebsiteBlock = (
          <div>
            <p>
              <strong>Website</strong>
            </p>
            <br />
            <a href={company.companyWebsite} target="_blank">
              <p>{company.companyWebsite}</p>
            </a>
          </div>
        );
      }

      let locationBlock = null;
      if (company.city) {
        locationBlock = (
          <div style={{ marginTop: "10px" }}>
            <p>
              <strong>Location</strong>
            </p>
            <br />
            <p>{company.city}</p>
          </div>
        );
      }

      let companySizeBlock = null;
      if (company.companySize) {
        const companySizeDict = {
          0: "No Employees",
          1: "1 - 10",
          2: "11-50",
          3: "50-200",
          4: "300-500",
          5: "500-1000",
          6: "1000+",
        };

        companySizeBlock = (
          <div style={{ marginTop: "10px" }}>
            <p>
              <strong>Company Size</strong>
            </p>
            <br />
            <p>{companySizeDict[company.companySize]}</p>
          </div>
        );
      }

      let skillBlock = null;
      if (jobOpeningSkillMap) {
        const jobOpeningSkillDivs = jobOpeningSkillMap.map((each) => {
          return (
            <div>
              <p>{each.skill.skillName}</p>
            </div>
          );
        });

        // TODO: need many to many table query
        // TODO: this component can be reusable too...
        // use map or forEach over each jobOpeningSkillMap to render the correct jobOpeningSkillMap.skill.skillName (in red circles like Angellist)
        skillBlock = (
          <div style={{ marginTop: "10px" }}>
            <p>
              <strong>Skills</strong>
            </p>
            {jobOpeningSkillDivs}
          </div>
        );
      }

      return (
        <div>
          <h3 style={center}>{company.companyName}</h3>
          <h5 style={center}>{this.props.jobOpening.role}</h5>
          <div style={center}>
            {profile && profile.isRecruiter ? editJobOpening : applyButton}
          </div>
          <hr className="hrstyle" />
          <div className="row">
            {/* Left Side */}
            <div className="col-sm-8">
              <p>
                <strong>About {company.companyName}</strong>
              </p>
              <br />
              <p>{company.about}</p>

              <br />
              <br />
              <p>
                <strong>Description</strong>
              </p>
              <br />
              <p style={center}>{this.props.jobOpening.description}</p>
            </div>

            {/* Right Side */}
            <div className="col-sm-4" style={rightBox}>
              {companyWebsiteBlock}
              {locationBlock}
              {companySizeBlock}
              {skillBlock}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const profile =
      this.props.profile &&
      this.props.profile.data &&
      this.props.profile.data.results
        ? this.props.profile.data.results[0]
        : null;

    return (
      <Row style={pageStyle}>
        <div
          className="col-sm-8 col-sm-offset-2 lightBoxBorderNoRadius"
          style={contentStyle}
        >
          {this.content()}
          {profile && profile.isRecruiter
            ? this.editModal()
            : this.applyModal()}
        </div>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    jobOpening: state.employerReducer.jobOpening,
    jobOpeningSkillMap: state.employerReducer.jobOpeningSkillMap,
    companies: state.resumeReducer.companies,
    skills: state.employerReducer.skills,
    resumes: state.resumeReducer.resumes,
    jobOpeningApplicantMap: state.employerReducer.jobOpeningApplicantMap,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProfile,
      getCurrentUser,
      getJobOpening,
      getJobOpeningSkillMap,
      getCompanies,
      postCompany,
      putJobOpening,
      applyToJob,
      getResumes,
      getJobOpeningApplicantMap,
      postSkill,
      getSkills,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOpening);
