import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Bootstrap
import {
  Glyphicon,
  Nav,
  Navbar,
  NavItem,
  NavDropdown,
  Image,
  MenuItem,
  Row,
} from "react-bootstrap";

// Img Import
import profPic from "../../../assets/img/profilepic.png";
import { bindActionCreators } from "redux";
import { getCurrentUser, getProfile, getUsers } from "../actions";
import { connect } from "react-redux";
import DownloadResumeModal from './Dialogs/DownloadResumeModal';

const imgStyle = {
  width: "30px",
  height: "auto",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

const navDropdownTitle = (
  <Image src={profPic} alt="profPic" style={imgStyle} circle />
);

const renderDropdown = (isLoggedIn) => {
  return (
    <NavDropdown eventKey={3} title={navDropdownTitle} id="basic-nav-dropdown">
      {isLoggedIn ? (
        <>
          <MenuItem eventKey="1">
            <Link to={`/home/settings`}>Settings</Link>
          </MenuItem>

          <MenuItem divider />

          <MenuItem
            eventKey="2"
            onClick={() => {
              localStorage.removeItem("token");
              window.location.reload();
            }}
          >
            Logout
          </MenuItem>
        </>
      ) : (
        <MenuItem eventKey="3">
          <Link to={`/home/`}>Login</Link>
        </MenuItem>
      )}
    </NavDropdown>
  );
};

// DO I need container fluid to move this all the way left w/o margins?
class Navigation extends Component {

  state = {
    downloadResumeModalOpen: false
  }

  setDownloadResumeModalOpen = () => {
    this.setState({
      downloadResumeModalOpen: !this.state.downloadResumeModalOpen,
    });
  };

  render() {
    const isLoggedIn = !!(
      this.props.currentUser &&
      this.props.currentUser &&
      this.props.currentUser.id
    );

    const dropdown = renderDropdown(isLoggedIn);

    let path = this.props.history.location.pathname;
    path = path.split("/");
    // path looks like this -> ['', 'home', 'resume', '1']
    const isResumePage = path.length > 3 && path[1] === "home" && path[2] === "resume";
    const resumeId = isResumePage ? parseInt(path[3], 10) : null

    return (
      <Row>
        <Navbar className="navbar navbar-default nav-Custom-blue">
          <Navbar.Header>
            <Navbar.Brand>
              <Link to={`/home/dashboard`}>
                <strong style={{ color: "#FFF", fontSize: "24px" }}>
                  FindFit.AI
                </strong>
              </Link>
            </Navbar.Brand>
          </Navbar.Header>
          <Nav className="navbar-right">
            {isResumePage && (
              <>
                <NavItem
                  eventKey={1}
                  href="/home/dashboard"
                  style={{ paddingTop: "7px" }}
                >
                  <strong style={{ color: "#FFF", fontSize: "18px" }}>
                    Home
                  </strong>
                </NavItem>
                <NavItem eventKey={2} style={{ paddingTop: "7px" }} onClick={() => this.setDownloadResumeModalOpen()}>
                  <strong style={{ color: "#FFF", fontSize: "18px" }}>
                    Export Resume as PDF <Glyphicon glyph="download" />
                  </strong>
                </NavItem>
              </>
            )}
            {dropdown}
          </Nav>
        </Navbar>
        {isResumePage &&
            <DownloadResumeModal
                isOpen={this.state.downloadResumeModalOpen}
                toggleOpen={this.setDownloadResumeModalOpen}
                resumeId={resumeId}
                currentUserId={this.props.currentUser.id}
            />
        }
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.sharedReducer.users,
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUsers,
      getProfile,
      getCurrentUser,
    },
    dispatch,
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation),
);
