
// libs
import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// event imports
import * as events from './events';

export const getUsers = () => {
    const url = `/users`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_USERS,
        payload: request
    }
};

export const getCurrentUser = () => {
    const url = `/current_user`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_CURRENT_USER,
        payload: request
    }
};

export const getProfile = () => {
    const url = `/profile_by_user`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_PROFILE,
        payload: request
    }
};

export const patchUser = (values, id) => {
    const url = `/users/${id}/`;
    const token = localStorage.getItem('token');
    const request = axios.patch(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.PATCH_USER,
        payload: request
    }
};

export const postProfile = (values) => {
    const url = `/profile_by_user/`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.PATCH_PROFILE,
        payload: request
    }
};

export const patchProfile = (values, id) => {
    const url = `/profiles/${id}/`;
    const token = localStorage.getItem('token');
    const request = axios.patch(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    // may be able to drop the return because we don't have this action's event update the store
    return {
        type: events.PATCH_PROFILE,
        payload: request
    }
};

export const updateUserField = (field, value) => {
    return {
        type: events.UPDATE_USER_FIELD,
        payload: { field, value }
    }
};

export const updateProfileField = (field, value) => {
    return {
        type: events.UPDATE_PROFILE_FIELD,
        payload: { field, value }
    }
};

export const getHasAccessToResume = (resume_id) => {
    const url = `/resume/has_access_to_resume/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_ACCESS_TO_RESUME,
        payload: request
    }
};

export const getResume = (id) => {
    const url = `/resume/${id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_RESUME,
        payload: request
    }
};

export const getResumes = () => {
    const url = `/resume`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_RESUMES,
        payload: request
    }
};

export const createResume = (values) => {
    const url = `/resume/`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.CREATE_RESUME,
        payload: request
    }
};

export const editResume = (values) => {
    const url = `/resume/${values.resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.patch(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EDIT_RESUME,
        payload: request
    }
};

export const deleteResume = (id) => {
    const url = `/resume/${id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_RESUME,
        payload: id
    }
};

/* Summary */
export const getSummary = (resume_id) => {
    const url = `/resume/resume_summary/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_SUMMARY,
        payload: request
    }
};

export const postSummary = (values) => {
    const url = `/resume/summary`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.SUMMARY_UPDATE_SUCCESS,
        payload: request
    }
};

export const putSummary = (values, summary_id) => {
    const url = `/resume/summary/${summary_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.SUMMARY_UPDATE_SUCCESS,
        payload: request
    }
};

/* Cover Letter */
export const getCoverLetter = (resume_id) => {
    const url = `/resume/resume_coverletter/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_COVER_LETTER,
        payload: request
    }
};

export const postCoverLetter = (values) => {
    const url = `/resume/coverletter`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.COVER_LETTER_UPDATE_SUCCESS,
        payload: request
    }
};

export const putCoverLetter = (values, cover_letter_id) => {
    const url = `/resume/coverletter/${cover_letter_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.COVER_LETTER_UPDATE_SUCCESS,
        payload: request
    }
};

/* Highlights */
export const getHighlights = (resume_id) => {
    const url = `/resume/resume_highlights/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_HIGHLIGHTS,
        payload: request
    }
};

export const postHighlight = (values) => {
    const url = `/resume/highlights`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.HIGHLIGHT_CREATE_SUCCESS,
        payload: request
    }
};

export const putHighlight = (values, highlight_id) => {
    const url = `/resume/highlights/${highlight_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.HIGHLIGHT_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteHighlight = (highlight_id) => {
    const url = `/resume/highlights/${highlight_id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_HIGHLIGHT,
        payload: highlight_id
    }
};


/* Education */
export const getEducations = (resume_id) => {
    const url = `/resume/resume_education/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_EDUCATIONS,
        payload: request
    }
};

export const getSchools = (filterClause = null, primaryKey = null) => {
    let url = '';

    if (filterClause !== null) {
        url = `/resume/school/?filter_clause=${filterClause}`;
    } else if (primaryKey !== null) {
        url = `/resume/school/?primary_key=${primaryKey}`;
    } else {
        url = `/resume/school/`;
    }

    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_SCHOOLS,
        payload: request
    }
};


export const postSchool = (values) => {
    const url = `/resume/school/`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.SCHOOL_CREATE_SUCCESS,
        payload: request
    }
};

export const postEducation = (values) => {
    const url = `/resume/education`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EDUCATION_CREATE_SUCCESS,
        payload: request
    }
};

export const putEducation = (values, education_id) => {
    const url = `/resume/education/${education_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EDUCATION_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteEducation = (education_id) => {
    const url = `/resume/education/${education_id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_EDUCATION,
        payload: education_id
    }
};


/* Languages */
export const getRawLanguages = () => {
    const url = '/resume/rawlanguages';
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_RAW_LANGUAGES,
        payload: request
    }
};

export const getLanguages = (resume_id) => {
    const url = `/resume/resume_languages/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_LANGUAGES,
        payload: request
    }
};

export const postLanguage = (values) => {
    const url = `/resume/languages`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.LANGUAGE_CREATE_SUCCESS,
        payload: request
    }
};

export const putLanguage = (values, language_id) => {
    const url = `/resume/languages/${language_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.LANGUAGE_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteLanguage = (pk) => {
    const url = `/resume/languages/${pk}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_LANGUAGE,
        payload: pk
    }
};

/* References */
export const getReferences = (resume_id) => {
    const url = `/resume/resume_reference/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_REFERENCES,
        payload: request
    }
};

export const postReference = (values) => {
    const url = `/resume/reference`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.REFERENCE_CREATE_SUCCESS,
        payload: request
    }
};

export const putReference = (values, reference_id) => {
    const url = `/resume/reference/${reference_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.REFERENCE_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteReference = (reference_id) => {
    const url = `/resume/reference/${reference_id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_REFERENCE,
        payload: reference_id
    }
};

/* Experiences */
export const getExperiences = (resume_id) => {
    const url = `/resume/resume_experience/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_EXPERIENCES,
        payload: request
    }
};

export const postExperience = (values) => {
    const url = `/resume/experience`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EXPERIENCE_CREATE_SUCCESS,
        payload: request
    }
};

export const putExperience = (values, experience_id) => {
    const url = `/resume/experience/${experience_id}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EXPERIENCE_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteExperience = (id) => {
    const url = `/resume/experience/${id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_EXPERIENCE,
        payload: id
    }
};

/* SkillGroups */
export const getSkillGroups = (resume_id) => {
    const url = `/resume/resume_skillgroup/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_SKILL_GROUPS,
        payload: request
    }
};

export const postSkillGroup = (values) => {
    const url = `/resume/skillgroup`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.SKILL_GROUP_CREATE_SUCCESS,
        payload: request
    }
};

export const putSkillGroup = (values) => {
    const url = `/resume/skillgroup/${values.skillGroup}`;  // skillGroup is an id
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.SKILL_GROUP_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteSkillGroup = (id) => {
    const url = `/resume/skillgroup/${id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_SKILL_GROUP,
        payload: id
    }
};


/* Skills */
export const getUserSkillMap = (resume_id) => {
    const url = `/resume/resume_userskillmap/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;

    return {
        type: events.GET_USER_SKILL_MAP,
        payload: request
    }
};

export const postUserSkillMap = (values) => {
    const url = `/resume/userskillmap`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.USER_SKILL_MAP_CREATE_SUCCESS,
        payload: request
    }
};

export const putUserSkillMap = values => {
    const url = `/resume/userskillmap/${values.pk}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.USER_SKILL_MAP_UPDATE_SUCCESS,
        payload: request
    }
};

export const deleteUserSkillMap = (user_skill_map_id) => {
    // Note skill items are global to all users, thus we only delete the user_skill_map representing the relationship
    // between the user and the skill they have.

    const url = `/resume/userskillmap/${user_skill_map_id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_USER_SKILL_MAP,
        payload: user_skill_map_id
    }
};

/* Skill Hours */
export const getSkillHours = (resume_id) => {
    const url = `/resume/resume_skillshourscompany/${resume_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });;
    return {
        type: events.GET_SKILL_HOURS,
        payload: request
    }
};

export const putSkillHours = (skillHoursExperience, userId) => { // TODO: Params just guessed, organize correctly.
    const {
        experience,
        hours,
        resumeVersion,
        userSkillMap,
        pk
    } = skillHoursExperience;

    const url = `/resume/skillshourscompany/${pk}`;
    const token = localStorage.getItem('token');

    const values = {
        resumeVersion,
        userSkillMap,
        experience,
        hours: parseInt(hours),
        user_id: userId
    };

    const request = axios.put(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.PUT_SKILL_HOURS,
        payload: request
    }
};

export const postSkillHours = (hours, experience, userSkillMap, resume_id, userId) => {
    const url = `/resume/skillshourscompany`;
    const token = localStorage.getItem('token');
    // do we need to pass values or can we just pass above inputs directly?
    const values = {
        resumeVersion: resume_id,
        userSkillMap: userSkillMap.pk,
        experience: experience.pk,
        hours: parseInt(hours),
        user_id: userId
    };
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} }); // these values must match up with what backend expects?

    return {
        type: events.POST_SKILL_HOURS, // TODO: Update this in events and reducer
        payload: request
    }
};

export const getCompanies = (filterClause = null, primaryKey = null) => {
    // For now, we can use either or of the param clauses

    let url = '';
    if (filterClause !== null) {
        url = `/resume/company/?filter_clause=${filterClause}`;
    } else if (primaryKey !== null) {
        url = `/resume/company/?primary_key=${primaryKey}`;
    } else {
        url = `/resume/company/`;
    }
    const token = localStorage.getItem('token')
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_COMPANIES,
        payload: request
    }
};

export const postCompany = (values) => {
    const url = `/resume/company/`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.COMPANY_CREATE_SUCCESS,
        payload: request
    }
};

export const patchCompany = (pk, values) => {
    // Support patch single field update
    const url = `/resume/company/${pk}`;
    const token = localStorage.getItem('token');
    const request = axios.patch(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.PATCH_COMPANY,
        payload: request
    }
};

export const patchSchool = (pk, values) => {
    // Support patch single field update
    const url = `/resume/school/${pk}`;
    const token = localStorage.getItem('token');
    const request = axios.patch(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.PATCH_SCHOOL,
        payload: request
    }
};

/* Modals */

/* Skill Modal */
export const toggleSkillModal = () => {
    return {
        type: events.TOGGLE_SKILL_MODAL
    };
};

/* Summary Modal */
export const toggleSummaryModal = () => {
    return {
        type: events.TOGGLE_SUMMARY_MODAL
    };
};

/* Highlights Modal */
export const toggleHighlightsModal = () => {
    return {
        type: events.TOGGLE_HIGHLIGHTS_MODAL
    };
};

/* Language Modal */
export const toggleLanguageModal = () => {
    return {
        type: events.TOGGLE_LANGUAGE_MODAL
    };
};

/* Education Modal */
export const toggleEducationModal = () => {
    return {
        type: events.TOGGLE_EDUCATION_MODAL
    };
};

/* References Modal */
export const toggleReferencesModal = () => {
    return {
        type: events.TOGGLE_REFERENCES_MODAL
    };
};

/* Experience Modal */
export const toggleExperienceModal = () => {
    return {
        type: events.TOGGLE_EXPERIENCE_MODAL
    };
};

/* DeleteResumeItemModal Modal */
export const toggleDeleteResumeItemModal = (
    itemName=null,
    deleteItemFn=null,
    relatedItems=null,
    cascadeDeleteItem=null
) => {
    return {
        type: events.TOGGLE_DELETE_RESUME_ITEM_MODAL,
        payload: {
            itemName,
            deleteItemFn,
            relatedItems,
            cascadeDeleteItem
        }
    };
};

/* Authentication */

export const handle_login = (username, password) => {
    const url = '/api/token/';
    const auth_data = { username, password };
    const request = axios.post(url, auth_data);

    return {
        type: events.HANDLE_LOGIN,
        payload: request
    }
};


export const handle_signup = (username, password, first_name, last_name) => {
    const url = '/users/';
    const auth_data = { username, password, first_name, last_name };

    return async dispatch => {
        await axios
            .post(url, auth_data)
            .then(response => {
                dispatch({
                    type: events.HANDLE_SIGNUP,
                    payload: response
                });
                dispatch({
                    type: events.SIGN_UP_ERROR,
                    payload: null
                });
            })
            .catch(error => {
                dispatch({
                    type: events.SIGN_UP_ERROR,
                    payload: error
                });
            });
    }
};

// TODO: Below unused.
// export const handle_logout = () => {
//     localStorage.removeItem('token');
//     this.setState({ logged_in: false, username: '' });
// };

/* Employer Reducer */


export const getJobOpenings = (company_id=null) => {
    const url = company_id !== null ? `/employer/job_opening?company_id=${company_id}` : `/employer/job_opening`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_JOB_OPENINGS,
        payload: request
    }
};

export const getJobOpening = (id) => {
    const url = `/employer/job_opening/${id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_JOB_OPENING,
        payload: request
    }
};

export const createJobOpening = (values) => {
    const url = `/employer/create_job_opening`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.CREATE_JOB_OPENING,
        payload: request
    }
};

export const putJobOpening = (values) => {
    const url = `/employer/update_job_opening`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.JOB_OPENING_UPDATE_SUCCESS,
        payload: request
    }
};

export const applyToJob = (values) => {
    const url = `/employer/job_opening_applicant_map`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.APPLY_TO_JOB_SUCCESS,
        payload: request
    }
};

export const unApplyToJob = (id) => {
    const url = `/employer/job_opening_applicant_map/${id}`;
    const token = localStorage.getItem('token');
    const request = axios.delete(url,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.UN_APPLY_TO_JOB_SUCCESS,
        payload: id
    }
};

export const deleteJobOpening = (id) => {
    const url = `/employer/job_opening/${id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.DELETE_JOB_OPENING,
        payload: id
    }
};

// Getting raw skill object itself without any many to many mapping
export const getSkills = () => {
    const url = `/employer/skill`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_SKILLS,
        payload: request
    }
};

export const postSkill = (values) => {
    const url = `/employer/skill`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.SKILL_CREATE_SUCCESS,
        payload: request
    }
};

export const getJobOpeningSkillMap = job_opening_id => {
    const url = `/employer/job_opening_skill_map?job_opening_id=${job_opening_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_JOB_OPENING_SKILL_MAP,
        payload: request
    }
};

export const getJobOpeningApplicantMap = (job_opening_id=null) => {
    const url = `/employer/job_opening_applicant_map?job_opening_id=${job_opening_id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_JOB_OPENING_APPLICANT_MAP,
        payload: request
    }
};

export const getSchoolByInstitutionAdminUser = () => {
    /*
    Function can have clearer name - this one gets a school for a user that is an admin at that school " +
    For the new job fair flow

    There also exists a getSchool() that uses events.GET_SCHOOL as well.
     */
    const url = `/employer/get_school_by_admin_user`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_SCHOOL,
        payload: request
    }
};

export const getSchoolById = (id) => {
    /*
    Function can have clearer name - this one gets a school for a user that is an admin at that school " +
    For the new job fair flow
     */
    const url = `/resume/school/${id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_SCHOOL,
        payload: request
    }
};

export const getJobFairs = (only_fetch_enrolled_fairs=false) => {
    /*
    TODO: expand this by using param to see what type we are getting job fairs for
    there are 3 cases, school, for a company and for a student.
     */

    const url = only_fetch_enrolled_fairs
        ? `/employer/get_job_fairs?only_fetch_enrolled_fairs=${only_fetch_enrolled_fairs}`
        : `/employer/get_job_fairs`;

    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    if (only_fetch_enrolled_fairs) {
        return {
            type: events.GET_ENROLLED_JOB_FAIRS,
            payload: request
        }
    }

    return {
        type: events.GET_JOB_FAIRS,
        payload: request
    }
};


export const getJobFair = id => {

    const url = `/employer/job_fair/${id}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_JOB_FAIR,
        payload: request
    }
};


export const createJobFair = (values) => {
    const url = `/employer/job_fairs`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.CREATE_JOB_FAIR,
        payload: request
    }
};

export const editJobFair = (values) => {
    const url = `/employer/job_fair/${values.jobFairId}`;
    const token = localStorage.getItem('token');
    const request = axios.put(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EDIT_JOB_FAIR,
        payload: request
    }
};

export const getEmployersAttendingJobFairs = (jobFairId) => {
    /*
    TODO: expand this by using param to see what type we are getting job fairs for
    there are 3 cases, school, for a company and for a student.
     */

    const url = `/employer/get_companies_attending_job_fair?job_fair_id=${jobFairId}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_EMPLOYERS_ATTENDING_JOB_FAIRS,
        payload: request
    }
};

export const deleteEmployerJobFairMap = (id) => {
    const url = `/employer/employer_job_fair_map/${id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });
    window.location.reload();  // TODO: TEMP REMOVE WHEN REACT auto reloads modal state using redux
    return {
        type: events.DELETE_EMPLOYER_JOB_FAIR_MAP,
        payload: id
    }
};

export const deleteEmployerJobFairMapWithJobFairId = (job_fair_id, company_id) => {
    const url = `/employer/delete_employer_job_fair_map?job_fair_id=${job_fair_id}&company_id=${company_id}`;

    const token = localStorage.getItem('token');
    axios.delete(url, {headers: {"Authorization": `Bearer ${token}`}});

    window.location.reload();  // TODO: TEMP REMOVE WHEN REACT auto reloads modal state using redux
    return {
        type: events.DELETE_EMPLOYER_JOB_FAIR_MAP_WITH_JOB_FAIR_ID,
        payload: id
    }
};

export const acceptEmployerJobFairMap = (id) => {
    const url = `/employer/accept_employer_job_fair_map`;
    const token = localStorage.getItem('token');
    axios.put(url, { 'id': id },{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EMPLOYER_JOB_FAIR_MAP_ACCEPT_SUCCESS,
        payload: id
    }
};

export const getCompany = (pk) => {
    const url = `/resume/company/${pk}`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_COMPANY,
        payload: request
    }
};

export const applyToCompany = (values) => {
    const url = `/employer/company_applicant_map`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values,{ headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.APPLY_TO_COMPANY,
        payload: request
    }
};

export const getCompanyApplicantMap = (company_id=null) => {
    const url = company_id !== null
        ? `/employer/company_applicant_map?company_id=${company_id}`
        : `/employer/company_applicant_map`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_COMPANY_APPLICANT_MAP,
        payload: request
    }
};

export const deleteCompanyApplicantMap = (id) => {
    const url = `/employer/company_applicant_map/${id}`;
    const token = localStorage.getItem('token');
    axios.delete(url, { headers: {"Authorization" : `Bearer ${token}`} });
    window.location.reload();  // TODO: TEMP REMOVE WHEN REACT auto reloads modal state using redux
    return {
        type: events.DELETE_COMPANY_APPLICANT_MAP,
        payload: id
    }
};

export const getEmployerForEmployee = () => {
    /*
    user is passed via request so no ID needed
     */
    const url = `/employer/get_employer_for_employee`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.GET_EMPLOYER_FOR_EMPLOYEE,
        payload: request
    }
};

export const employerApplyToJobFair = (values) => {
    const url = `/employer/employer_job_fair_map`;
    const token = localStorage.getItem('token');
    const request = axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} });

    return {
        type: events.EMPLOYER_APPLY_TO_JOB_FAIR,
        payload: request
    }
};

/* Doing below call to api in CandidateJobFairBrowseList until we get a reason to keep it in global state. */
// export const getCandidateSchoolMap = () => {
//     const url = `/candidate_school_map`;
//     const token = localStorage.getItem('token');
//     const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
//
//     // TODO do we also want to set the school in state? or call get school
//     // after this school id returned.?
//
//     return {
//         type: events.GET_CANDIDATE_SCHOOL_MAP,
//         payload: request
//     }
// };

export const getCareerTracks = () => {
    const url = `/career_tracks`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_CAREER_TRACKS,
        payload: request
    }
};

export const getSkillRecommendations = () => {
    const url = `/resume/get_skill_recommendations_by_career_track`;
    const token = localStorage.getItem('token');
    const request = axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
    return {
        type: events.GET_SKILL_RECOMMENDATIONS,
        payload: request
    }
}
