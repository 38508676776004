
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// App Resources
import jwt_decode from 'jwt-decode';


const auth = {
    isAuthenticated () {
        let valid = false;
        const token = localStorage.getItem('token');
        if (token) {
            try {
                let decodedToken = jwt_decode(token);
                let dateNow = new Date();
                if (decodedToken.exp > dateNow.getTime() / 1000) {
                    valid = true;
                }
            } catch {
                console.log('Invalid Token')
            }
        }
        return valid;
    }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        auth.isAuthenticated()
        ? <Component {...props} />
        : <Redirect to={{
              pathname: '/home',
              state: { from: props.location }
            }}
        />
        )}
    />
);


const mapStateToProps = state => {
  return {
    title: state.title
  };
};

export default connect(mapStateToProps)(PrivateRoute);
