import React, { Component } from "react";

// Component Specific Imports
import LoginForm from "./LoginForm";

// Bootstrap Resources
import { Row, Col } from "react-bootstrap";

const contentStyle = {
  minHeight: "100%",
  backgroundColor: "#325ea8",
};

class Welcome extends Component {
  content() {
    return (
      <Row style={contentStyle}>
        <Col md={6}>
          <h2 style={{ marginTop: "50px", color: "#FFF" }}>
            <strong>Removing Bias in Job Search</strong>
          </h2>
          <p style={{ color: "#FFF" }}>
            <em>
              <strong>We get it, finding a job after college is hard. </strong>
              <br/>
              Making a resume to highlight your value from coursework, projects & internships is what we can help with.
            </em>
          </p>
        </Col>
        <Col md={6} style={{ height: "800px" }}>
          {/* Defaults to sign up, but a login option exist in signup's dialog to toggle state to show login */}
          <LoginForm history={this.props.history} />
        </Col>
      </Row>
    );
  }

  render() {
    return this.content();
  }
}

export default Welcome;
