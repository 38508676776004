
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Component Specific Imports
import EmployerLoginForm from './EmployerLoginForm';

// Bootstrap Resources
import { Row, Col } from 'react-bootstrap';


const contentStyle = {
    minHeight: '100%'
};


class Employer extends Component {

    content () {
        return (
            <Row style={contentStyle}>
                {/* Need to add column spans here */}
                <Col md={6} style={{ backgroundColor: "#325ea8", height: '800px' }}>
                  <h2 style={{ marginTop: '50px', color: '#FFF' }}><strong>Recruiter</strong></h2>
                </Col>
                <Col md={6} style={{ backgroundColor: "#325ea8", height: '800px' }}>
                {/* Defaults to sign up, but a login option exist in signup's dialog to toggle state to show login */}
                  <EmployerLoginForm history={this.props.history} />
                </Col>
            </Row>
        );
    }

    render() {
        return (
            this.content()
        );
    }
}

export default Employer;
