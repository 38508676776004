
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import ReferencesPreviewPanel from './ReferencesPreviewPanel';
import ReferencesEditPanel from  './ReferencesEditPanel';
import { deleteReference, getCurrentUser, toggleDeleteResumeItemModal } from '../../actions';

// Shared Resources
import { referenceRelationshipOptions } from "../../shared/utils";

// Bootstrap Resources
import { Panel, Row, Col, Glyphicon } from 'react-bootstrap';

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class ReferencesPanel extends Component {
    state = {
        editing: false // same as show: false
    };

    async componentDidMount() {
        await this.props.getCurrentUser();
    }

    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    showDeleteModal = (reference_id) => {
        this.props.toggleDeleteResumeItemModal(
            'reference',
            () => this.props.deleteReference(reference_id)
        );
    };

    renderBody = () => {
        const { reference, companyName } = this.props;
        return (this.state.editing
            ? <ReferencesEditPanel reference={reference} toggleEdit={this.toggleEdit} />
            : <ReferencesPreviewPanel reference={reference} companyName={companyName} />);
    };

    render () {
        const { reference, companyName } = this.props;
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>{ reference.firstNameOfRef } { reference.lastNameOfRef }</strong></p>
                            <br/>
                            <p>{ referenceRelationshipOptions[reference.relationship].name } at { companyName }</p>
                        </Col>
                        <Col md={1}>
                            {
                                resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                                    (
                                        <div>
                                            <Glyphicon glyph="pencil" onClick={this.toggleEdit} />
                                            &nbsp;
                                            <Glyphicon glyph="remove" onClick={() => this.showDeleteModal(reference.pk)} />
                                        </div>
                                    ) :
                                    null
                            }
                        </Col>
                    </Row>
                </Panel.Heading>
                { this.renderBody() }
            </Panel>
        );
    }
}


function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,  // assumes resume state is fetched into redux when this page loads
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            deleteReference,
            getCurrentUser,
            toggleDeleteResumeItemModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesPanel);
