import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "react-bootstrap";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";


const ResetPasswordRequest = () => {
  const [showNextStep, setShowNextStep] = useState(false);

  return (
    <div className="horizontal-align">
      {!showNextStep && (
        <div className="cardStyle" style={{ marginTop: "20px" }}>
          <h3>Forgot password?</h3>
          <p><em>Reset password in two quick steps</em></p>
          <br />
          <br />
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              const url = `/reset_password_request`;
              axios.post(url, values);
              setSubmitting(false);
              setShowNextStep(true);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <label>Email</label>
                <Field type="email" name="email" style={{ width: "100%" }} />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "#d9534f" }}
                />
                <Button
                  bsStyle="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {showNextStep && (
        <h5>
          Please check your email. If we found a matching user, an email will be
          sent to you for next steps.
        </h5>
      )}
    </div>
  );
};

export default ResetPasswordRequest;
