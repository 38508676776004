
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import DatePicker from 'react-datepicker';

// App Resources
import {
    putUserSkillMap,
    postSkillHours,
    putSkillHours,
    getSkills,
    postSkill,
    getExperiences,
    getCurrentUser,
    getSkillHours,
    getSkillGroups,
    getUserSkillMap,
} from '../../actions';

// Bootstrap Resources
import { Button, ControlLabel, Form, FormGroup, FormControl, Panel, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import RenderInput from '../../shared/RenderInput';
import RenderSelect from "../../shared/RenderSelect";


class SkillEditPanel extends Component {

    // Constructor
    state = {
        user_skill_map: {
            ...this.props.user_skill_map
        },
        localSkillHours: cloneDeep(
            this.props.skillHours.data && this.props.skillHours.data.results.length > 0 ?
                this.props.skillHours.data.results :
                []
        )
    };

    // Fields: SkillName, change a skill
    // Experience last used calculated if that experience skillhr value is non-zero
    // Dynamic amount of forms based on experiences for each skillhr
    // above wrap in a map of skillhrexperience objects
    // This panel should show ONE skill. and all the SkillHrExperiences for that skill
    // Also ability to add a skillGroup

    async componentDidMount() {
        await this.props.getCurrentUser();
        await this.props.getSkills();
        await this.props.getSkillHours(this.props.resume.pk);
        await this.props.getSkillGroups(this.props.resume.pk);
        await this.props.getUserSkillMap(this.props.resume.pk);
    }

    async updateSkillHrExField (field, pk, experience, value)  {
        const modifiedSkillHours = cloneDeep(this.state.localSkillHours);
        let existing = false;

        // Handles new and existing items and sets the existing flag.
        modifiedSkillHours.forEach(each => {
            if (each.pk === pk) {
                each[field] = value === "" ? 0 : value;
                existing = true;
            }
            // if not pk, then this is new, so call POST actionSkillHours() -> api to the db, append the response,
            // which looks like the pre-existing data. to modifiedSkillHours array
        });

        if (!existing) {
            // New items (first time entering this skillHrEx field in the db
            const { currentUser } = this.props;
            const userId = currentUser ? currentUser.id : null;
            const newSkillHourExperience = await this.props.postSkillHours(value, experience, this.props.user_skill_map, this.props.user_skill_map.resumeVersion, userId);  // Save to db
            const parsed = newSkillHourExperience.payload ? newSkillHourExperience.payload.data : null;
            modifiedSkillHours.push(parsed)  // update frontend state
        }
        this.setState({ localSkillHours: modifiedSkillHours })
        await this.props.getSkillHours(this.props.resume.pk);  // Update state via API call
    };

    // state looks like local state is just for this skill's version of Hr's does not concern other skills
    // GOOD!
    // (2) [{…}, {…}, hours: "5"]
    // 0: {pk: 1, user: "pilotj", resumeVersion: 1, userSkillMap: 1, experience: 1, …}
    // 1: {pk: 2, user: "pilotj", resumeVersion: 1, userSkillMap: 1, experience: 2, …}
    // hours: "5"
    // length: 2
    // __proto__: Array(0)

    // should be accessing the correct pk and then updating its hours attribute.
    // perhaps es6 way or a simple map. if no map, issue a create, fetch the pk from the db
    // and append that nested json with the new pk in the state.

    // let's first try with just updating existing elements.

    // es6 way.
    /*
    maybe not possible since it is an array and there are no key value pairs we can play with.
    modifiedSkillHours.forEach(each => {
        if (each.pk == incomingPKIfWeHaveIt) {
            each[field] = value; // where field is 'hours' only for now in our case.
        }
    });
    // if not found in above or we upstream detected that this is a new element, then we call post
    and get the new pk.

    we can do if incomingPKIfWeHaveIt then call below map to save performance, else then do a the post
    append to modifiedSkillHours the new element with all the fields populated.
     */

    async onSubmit () {
        // if doesn't exist create one.
        // need to loop for each form value under each skill, call below.
        // where localSkill.skillPK is equal to whoever calls onSubmit (ie a filter)

        // 1. Save the skill name
        await this.props.putUserSkillMap(this.state.user_skill_map);
        // 2. Then save the all the skill hour experiences for this skill.
        const { currentUser } = this.props;
        const userId = currentUser ? currentUser.id : null;
        await this.state.localSkillHours.forEach(each => {
            if (each.userSkillMap === this.state.user_skill_map.pk) {
                // update all skillhrcompanies that are related to this skill.
                // even if one is changed, all skillhrcompanies for this skill updated
                // but we write it over with original data since the state is unchanged
                // like chewse "ping pong" meal_edit_tool data copied FE->BE->BE
                this.props.putSkillHours(each, userId);
                // notice that on typing into a "blank" form, for a skillhrcompany field
                // we autoallocate (create a new instance) on typing in this file, search for where "postSkillHours" is used.
            }
        });
        this.props.getUserSkillMap(this.props.resume.pk);  // key to prevent data out of sync above and for UI skill bars refresh
        this.props.toggleEdit();
    };

    updateField = (field, value) => {
        // This is the skill edit form.
        const modified = cloneDeep(this.state.user_skill_map);

        if (field === 'skill' && Array.isArray(value) && value[0].customOption) {
            const newSkillName = value[0].label;
            if (newSkillName.length) {
                this.props.postSkill({
                    skillName: newSkillName
                }).then(res => {
                    modified['skill'] = res.payload.data;
                    this.setState({ user_skill_map: modified });
                    return;
                });
            }
        }

        if (field == "skillGroup") {
            // cast to int for this select field.
            value = parseInt(value, 10);
        }
        
        modified[field] = value;
        this.setState({ user_skill_map: modified });
    };
    
    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    handleSkillHrExChange = (event, pk, experience) => { // good practice to explicitly say event?
        this.updateSkillHrExField(event.target.name, pk, experience, event.target.value);
    };

    renderSkillHrExperienceForm = () => {
        // should map over all experience in each skill. If it is a skillHrExperience, pass
        // that in as an intialized value

        // TODO: Initialize local copy of skillHrExperience here?
        // And then for each iteration have a this.updateSkillHrExField()
        
        let experiencesList = [];
        if (this.props.experiences.data && this.props.experiences.data.results.length > 0) {
            experiencesList = this.props.experiences.data.results
        }

        return experiencesList.map(ex => { // Also sort this if needed
            // for each experience search if skillHrExperienceForThisSkill.experience equals ex.pk
            const companyName = ex.companyName;
            let hours = 0;
            let pk = null;

            this.state.localSkillHours.forEach(skillHrEx => { // sort these if needed
                if (skillHrEx.experience === ex.pk && skillHrEx.userSkillMap === this.state.user_skill_map.pk) {
                    // there will be only one match per iteration of the outer experience map. so we can set below here
                    hours = skillHrEx.hours; // called each render...
                    pk = skillHrEx.pk;
                }
            });

            return (
                <Col md={3}>
                    <RenderInput
                      label={`Hours used/week @ ${companyName}`}
                      name='hours'
                      placeholder={0}
                      initializedValue={hours} // this causing issues?
                      type="input"
                      onChange={(e) => this.handleSkillHrExChange(e, pk, ex)} // cannot have handleSkillHrExChange() with braces...
                    />
                </Col>
            );
        });
    };

    renderForm = () => {
        const skillOptionsList = this.props.skills;
        const defaultSelectedSkill = this.props.skills.filter(obj =>
            obj.pk === this.state.user_skill_map.skill.pk
        );

        const skillGroupOptionList = this.props.skillGroups && this.props.skillGroups.data
            ? this.props.skillGroups.data.results
            : [];
        skillGroupOptionList.forEach((each => {
            // Note: RenderSelect only understands "id" and "name" fields
            each.id = each.pk;
            each.name = each.skillGroupName;
        }));
        let defaultSelectedSkillGroup = skillGroupOptionList.filter(obj =>
            obj.pk === this.state.user_skill_map.skillGroup
        );
        defaultSelectedSkillGroup = defaultSelectedSkillGroup.length > 0 ? defaultSelectedSkillGroup[0].id : null;

        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <label>Skill Name</label>
                        <Typeahead
                          allowNew
                          labelKey={option => `${option.skillName}`}
                          options={skillOptionsList}
                          placeholder='Enter a Skill Name'
                          defaultSelected={defaultSelectedSkill}
                          style={{ marginBottom: '10px' }}
                          onChange={selected => {
                            if (selected.length > 0) {
                                if (selected[0].pk) {
                                    this.updateField('skill', selected[0]);
                                } else {
                                    this.updateField('skill', selected);
                                }
                              }
                            }
                          }
                        />
                    </Col>
                    <Col md={3}>
                        <RenderSelect
                          label='Change Skill Group'
                          name='skillGroup'
                          type='select'
                          initializedValue={defaultSelectedSkillGroup}
                          onChange={this.handleChange}
                          options={skillGroupOptionList}
                        />
                    </Col>
                </Row>
                <Row>
                    {this.renderSkillHrExperienceForm()}
                </Row>
                <div className="modal-space-between-confirm-buttons">
                    <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                    <Button bsStyle="warning" style={{marginLeft: '5px'}}
                            onClick={() => this.props.toggleEdit()}>Cancel
                    </Button>
                </div>
            </Form>
        )
    };

    render() {
        const { user_skill_map } = this.props;

        return (
            <Panel style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>{ user_skill_map.skill.skillName }</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    { this.renderForm(user_skill_map) }
                </Panel.Body>
            </Panel>
        );
    }
}


function mapStateToProps(state) {
    return {
        skills: state.employerReducer.skills,
        skillHours: state.resumeReducer.skillHours,
        skillGroups: state.resumeReducer.skillGroups,
        experiences: state.resumeReducer.experiences,
        currentUser: state.sharedReducer.currentUser, // why is current user not pilotj? How can I login as PilotJ? // Or try with another resume.
        resume: state.resumeReducer.resume
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            putUserSkillMap,
            getSkillHours,
            getSkillGroups,
            postSkillHours,
            putSkillHours,
            getSkills,
            postSkill,
            getExperiences,
            getCurrentUser,
            getUserSkillMap,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(SkillEditPanel);
