
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import { toggleEducationModal } from '../../actions';
import EducationPanel from './EducationPanel';

// Bootstrap Resources
import { Button, Modal, Row } from 'react-bootstrap';
import EducationNewPanel from './EducationNewPanel';

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class EducationModal extends Component {

    render() {
        const { isOpen } = this.props.educationModal;
        let body = [];
        const { educations } = this.props;
        const hasExistingData = !!(educations && educations.data && educations.data.results && educations.data.results.length);  // https://stackoverflow.com/questions/5417969/why-dont-logical-operators-and-always-return-a-boolean-result

        if (hasExistingData) {
            body = educations.data.results.map((education) => {
                return ( // TODO: update me
                    <div style={{ paddingLeft: '15px',  paddingRight: '15px' }}>
                        <Row>
                            <EducationPanel education={education} schools={this.props.schools} />
                        </Row>
                    </div>
                );
            });
        }

        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            body.unshift(
                <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                    <Row>
                        <EducationNewPanel schools={this.props.schools} hasExistingData={hasExistingData} />
                    </Row>
                </div>
            );
        }

        const title = (
            <strong>Education</strong>
        );

        return (
            <div>
                <Modal
                  show={ isOpen }
                  bsSize="large"
                  onHide={() => this.props.toggleEducationModal()}
                  backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {body}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleEducationModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        educationModal: state.resumeReducer.educationModal,
        educations: state.resumeReducer.educations,
        schools: ownProps.schools // better if this uses contexts
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleEducationModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EducationModal);
