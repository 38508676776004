import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Component Specific Imports
import {
  getUsers,
  getCurrentUser,
  getProfile,
  getSchoolByInstitutionAdminUser,
  createJobFair,
  getEmployerForEmployee,
} from "../actions";

// Bootstrap Resources
import { Tabs, Tab, Button, Modal, Row, Col } from "react-bootstrap";
import EmployerBrowseJobFairTable from "./EmployerBrowseJobFairTable";
import EmployerUpcomingJobFairTable from "./EmployerUpcomingJobFairTable";
import EmployerSettings from "./EmployerSettings";

import RenderInput from "../shared/RenderInput";
import { cloneDeep } from "lodash";
import { is_form_valid } from "../shared/utils";
import EmployerActivityTable from "./EmployerActivityTable";
import EmployerJobOpeningTable from "./EmployerJobOpeningTable";
import axios from "axios";

const contentWrapper = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
};

const logoStyle = {
  width: "10%",
};

const defaultLogo = require(`${"../../../assets/img/company_logo/default.png"}`);
// TODO actually create a default picture here

class EmployerDashboard extends Component {
  state = {
    images: [],
    createAdminUserModalOpen: false,
    startDate: Date.now(), // local state independent of localNewAdminUser's nested date field only used for the calendar
    endDate: Date.now(), // local state independent of localNewAdminUser's nested date field only used for the calendar
    localNewAdminUser: {
      name: "",
    },
  };

  componentDidMount() {
    this.props.getUsers(); // loaded by map state to props
    this.props.getProfile();
    this.props.getCurrentUser();
    this.props.getSchoolByInstitutionAdminUser();
    this.props.getEmployerForEmployee();
  }

  resetForms = () => {
    /* Resets state back to initial */
    this.setState({
      localNewAdminUser: {
        name: "",
      },
      startDate: Date.now(), // local state independent of setState's nested date field only used for the calendar
      endDate: Date.now(), // see above comment
    });
  };

  addDefaultImage(e) {
    e.target.src = defaultCompanyLogo;
  }

  updateField = (field, value) => {
    const modifiedLocalJobFair = cloneDeep(this.state.localNewAdminUser);
    modifiedLocalJobFair[field] = value;
    this.setState({ localNewAdminUser: modifiedLocalJobFair });
  };

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  onSubmit = () => {
    // validate forms.
    const { name, startDate, endDate } = this.state.localNewAdminUser;

    if (!is_form_valid([name, startDate, endDate])) {
      alert("Errors in form - please correct before saving.");
      // don't save to db and don't toggle/erase data.
    } else if (endDate && endDate < startDate) {
      alert("End Date must be after your Start Date");
    } else {
      this.props.createJobFair(this.state.localNewAdminUser);
      this.toggleCreateNew();
      window.location.reload();
    }
  };


  createAdminUserModal() {
    // todo update below states
    return (
      <Modal
        show={this.state.createAdminUserModalOpen}
        onHide={() => this.toggleCreateNew()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a new Job Fair</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <RenderInput
                label="First Name"
                name="name"
                placeholder={this.state.localNewAdminUser.name}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.onSubmit()}>
            Create
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleCreateNew()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  content() {
    let logoPath = defaultLogo; // Handles case when image is not in database and not in media folder.
    if (this.props.employer_of_recruiter?.companyLogo) {
      logoPath = `../../../media/companyLogo${
        this.props.employer_of_recruiter.pk
      }.${this.props.employer_of_recruiter.companyLogo.split(".").pop()}`;
    }

    return (
      <div style={contentWrapper}>
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src={logoPath}
              alt="logo"
              onError={this.addDefaultImage}
              style={logoStyle}
              onClick={() => {
                // this.toggleProfileImage();
              }}
            />
            <h3>{this.props.employer_of_recruiter?.companyName || ""}</h3>
          </div>

          <Tabs
            defaultActiveKey="activity"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="activity" title="Activity">
              <EmployerActivityTable />
            </Tab>
            <Tab eventKey="admin" title="Admin">
              <br />
              <p>Contact Findfit.io to add new admin users.</p>
              {/*<Button*/}
              {/*  bsStyle="primary"*/}
              {/*  style={{ textAlign: "left", marginTop: "10px" }}*/}
              {/*  onClick={() => this.toggleCreateNew()}*/}
              {/*>*/}
              {/*  Add Authorized User*/}
              {/*</Button>*/}
            </Tab>
            <Tab eventKey="jobOpening" title="Job Openings">
              <EmployerJobOpeningTable />
            </Tab>
            <Tab eventKey="jobFair" title="Job Fairs">
              <EmployerUpcomingJobFairTable />
              <EmployerBrowseJobFairTable />
            </Tab>
            <Tab eventKey="companySettings" title="Company Settings">
              <EmployerSettings logoPath={logoPath} />
            </Tab>
          </Tabs>
        </div>
        {this.createAdminUserModal()}
      </div>
    );
  }

  render() {
    const { profile } = this.props;

    if (profile?.isRecruiter) {
      return this.content();
    }
    return <p>This feature is for employers only.</p>;
  }
}

function mapStateToProps(state) {
  return {
    users: state.sharedReducer.users,
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    employer_of_recruiter: state.employerReducer.employer_of_recruiter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUsers,
      getProfile,
      getCurrentUser,
      getSchoolByInstitutionAdminUser,
      createJobFair,
      getEmployerForEmployee,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerDashboard);
