// https://react-pdf.org/components

import React, { Component } from "react";
import {
  PDFDownloadLink,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getProfile,
  getCurrentUser,
  getResume,
  getSummary,
  getExperiences,
  getEducations,
  getSkillGroups,
  getUserSkillMap,
  getSkillHours,
  getReferences,
  getLanguages,
  getHighlights,
  getHasAccessToResume,
} from "../actions";
import { getCompanyNameExperienceArray } from "../shared/utils";
import moment from "moment";

// Create styles

const BLUE_HEX_CODE = "#2a5dbd";
const LINK_HEX_CODE = "#0645AD";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingLeft: 25,
    paddingRight: 25,
    paddingHorizontal: 35,
  },
  page: {
    paddingLeft: 10,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 40,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 13,
    fontFamily: "Roboto",
  },
  blueText: {
    color: BLUE_HEX_CODE,
  },
  linkText: {
    color: LINK_HEX_CODE,
  },
  name: {
    fontSize: 17,
    fontFamily: "RobotoBold",
  },
  center: {
    textAlign: "center",
  },
  header: {
    fontSize: 10,
  },
  boldHeader: {
    fontSize: 11,
    fontFamily: "RobotoBold",
  },
  boldItalicHeader: {
    fontSize: 11,
    fontFamily: "RobotoBoldItalics",
  },
  headerSpacing: {
    marginTop: 10,
  },
  defaultText: {
    fontSize: 9,
  },
  block: {
    marginTop: 10,
  },
  skillIconImg: {
    width: 8,
    height: 8,
  },
});

Font.register({
  family: "RobotoBold",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
    },
  ],
});

Font.register({
  family: "RobotoBoldItalics",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf",
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
    },
  ],
});

// Import Imgs
import glyph1 from "../../../assets/img/glyphimg1bars.png";
import glyph2 from "../../../assets/img/glyphimg2bars.png";
import glyph3 from "../../../assets/img/glyphimg3bars.png";
import glyph4 from "../../../assets/img/glyphimg4bars.png";
import glyph5 from "../../../assets/img/glyphimg5bars.png";
const glyphImgArr = [glyph1, glyph2, glyph3, glyph4, glyph5];

const Br = () => "\n";

// Create Document Component
const MyDocument = ({
  user,
  profile,
  summary,
  experiences,
  educations,
  references,
  skillGroups,
  userSkillMap,
  languages,
  highlights,
  hasColor,
  shouldExportSkillBars,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View
        style={
          (styles.section,
          {
            marginBottom: "0px",
            textAlign: "center",
            paddingBottom: 5,
          })
        }
      >
        <Text style={[styles.name, styles.center]}>
          {user && user.first_name} {user && user.last_name}
        </Text>
        {/* Need to handle nullness on page load ^ or have a default component that doesn't render until all data loaded. */}
        <Text style={[styles.defaultText, hasColor ? styles.linkText : null]}>
          {user && user.email} {user && user.email && profile && profile.phoneNumber && '•'} {profile && profile.phoneNumber}
        </Text>
      </View>

      <div
        style={{
          flexDirection: "row",
          borderTopColor: "#000",
          borderTopWidth: "1",
        }}
      >
        <View
          style={
            (styles.section,
            {
              width: "25%",
              borderRightColor: "#000",
              borderRightWidth: "1",
              paddingLeft: 5,
              paddingRight: 5,
            })
          }
        >
          {/* We need this so the left column is never blank: Can assume user always has skills. */}
          <Text style={[styles.title, hasColor && styles.blueText]}>
            SKILLS
          </Text>

          {skillGroups &&
          skillGroups.length &&
          userSkillMap &&
          userSkillMap.length
            ? skillGroups.map((skillGroup, index) => {
                const skillInSkillGroup = userSkillMap.filter((obj) => {
                  return obj.skillGroup === skillGroup.pk;
                });

                /* This fetches all skills from skillInSkillGroup under the same parent Skill Group. */
                const formatedSkillsInSkillGroup = skillInSkillGroup.map(
                  (userSkillMap) => {
                    // Flex with side by side style with icon
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={styles.defaultText}
                          key={userSkillMap.skill.skillName}
                        >
                          • {userSkillMap.skill.skillName}
                        </Text>
                        {shouldExportSkillBars ? (
                          <Image
                            style={styles.skillIconImg}
                            src={glyphImgArr[userSkillMap.skillLevel - 1]}
                          />
                        ) : null}
                      </div>
                    );
                  }
                );

                if (formatedSkillsInSkillGroup.length) {
                  // Hide skill groups containing no skills.
                  return (
                    <>
                      <Text
                        style={[
                          styles.boldHeader,
                          index > 0 ? styles.block : null,
                          hasColor ? styles.blueText : null,
                        ]}
                      >
                        {skillGroup.skillGroupName}
                      </Text>
                      {formatedSkillsInSkillGroup}
                    </>
                  );
                }
                return null;
              })
            : null}

          {languages && languages.length ? (
            <Text
              style={[
                styles.title,
                styles.headerSpacing,
                hasColor ? styles.blueText : null,
              ]}
            >
              LANGUAGES
            </Text>
          ) : null}

          {languages && languages.length
            ? languages.map((language) => {
                return (
                  <div key={language.pk}>
                    <Text style={[styles.header, styles.boldHeader]}>
                        {language.languageNameEnglish}
                    </Text>
                  </div>
                );
              })
            : null}

          {references && references.length ? (
            <Text
              style={[
                styles.title,
                styles.headerSpacing,
                hasColor ? styles.blueText : null,
              ]}
            >
              REFERENCES
            </Text>
          ) : null}

          {references && references.length
            ? references.map((reference, index) => {
                let companyName = getCompanyNameExperienceArray(
                  reference,
                  experiences
                );

                return (
                  <div key={reference.pk}>
                    <Text
                      style={[
                        styles.header,
                        styles.boldHeader,
                        index > 0 ? styles.block : null,
                      ]}
                    >
                      {reference.firstNameOfRef} {reference.lastNameOfRef}
                    </Text>
                    <Text style={[styles.defaultText]}>{companyName}</Text>
                    <Text style={[styles.defaultText]}>
                      {reference.refEmail}
                    </Text>
                    <Text style={[styles.defaultText]}>
                      {reference.refPhone}
                    </Text>
                  </div>
                );
              })
            : null}
        </View>

        <View
          style={
            (styles.section, { width: "75%", paddingLeft: 5, paddingRight: 5 })
          }
        >
          {/* Null section placeholder. */}
          {!summary &&
            !summary?.summaryText &&
            !educations &&
            !educations.length &&
            !experiences &&
            !experiences.length && <Text>&nbsp;</Text>}

          {summary && summary?.summaryText && (
            <>
              <Text style={[styles.title, hasColor ? styles.blueText : null]}>
                SUMMARY
              </Text>
              <Text style={[styles.defaultText]}>{summary?.summaryText}</Text>
            </>
          )}

          {educations && educations.length ? (
            <Text
              style={[
                styles.title,
                styles.headerSpacing,
                hasColor ? styles.blueText : null,
              ]}
            >
              EDUCATION
            </Text>
          ) : null}

          {educations && educations.length
            ? educations.map((education) => {
                return (
                  <div key={education.pk}>
                    <Text style={[styles.header]}>
                      <Text style={styles.boldHeader}>{education.schoolName}</Text>
                      {" "}
                      | {education.major} |{" "}
                      {education.ifCurrentSchool
                        ? "Present"
                        : moment(education.endDate).format("YYYY")}
                    </Text>
                  </div>
                );
              })
            : null}

          {highlights && highlights.length ? (
            <Text
              style={[
                styles.title,
                styles.headerSpacing,
                hasColor && styles.blueText,
              ]}
            >
              CAREER HIGHLIGHTS
            </Text>
          ) : null}

          {highlights && highlights.length
            ? highlights.map((highlight, index) => {
                let companyName = getCompanyNameExperienceArray(
                  highlight,
                  experiences
                );

                return (
                  <div key={highlight.pk}>
                    <Text
                      style={[
                        styles.header,
                        index > 0 ? styles.block : null,
                        hasColor ? styles.blueText : null,
                      ]}
                    >
                      <Text style={styles.boldHeader}>{highlight.quantity}</Text>
                      {" "}
                      | {companyName} |{" "}
                      {moment(highlight.highlightDate).format("YYYY")}
                    </Text>
                    <Text style={[styles.defaultText]}>
                      {highlight.shortDescription}
                    </Text>
                  </div>
                );
              })
            : null}

          {experiences && experiences.length ? (
            <Text
              style={[
                styles.title,
                styles.headerSpacing,
                hasColor ? styles.blueText : null,
              ]}
            >
              EXPERIENCE
            </Text>
          ) : null}

          {experiences && experiences.length
            ? experiences.map((experience, index) => {
                return (
                  <div key={experience.pk}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Text
                          style={[
                            styles.header,
                            styles.boldHeader,
                            index > 0 ? styles.block : null,
                            hasColor ? styles.blueText : null,
                          ]}
                        >
                          {experience.companyName}
                        </Text>
                      </div>
                      <div>
                        <Text
                          style={[
                            styles.header,
                            styles.boldItalicHeader,
                            index > 0 ? styles.block : null,
                            hasColor ? styles.blueText : null,
                          ]}
                        >
                          {experience.position}
                        </Text>
                      </div>
                      <div>
                        <Text
                          style={[
                            styles.header,
                            styles.boldHeader,
                            index > 0 ? styles.block : null,
                            hasColor ? styles.blueText : null,
                          ]}
                        >
                          {moment(experience.startDate).format("MM/YYYY")} -{" "}
                          {experience.ifCurrentExperience
                            ? "Present"
                            : moment(experience.endDate).format("MM/YYYY")}
                        </Text>
                      </div>
                    </div>

                    <Text style={[styles.defaultText]}>
                      {experience.summary}
                    </Text>
                  </div>
                );
              })
            : null}
        </View>
      </div>
    </Page>
  </Document>
);

class Viewer extends Component {
  state = {
    resumeVersion: null,
    profile: null,
    dataLoadingComplete: false,
  };

  async componentDidMount() {
    // set the user
    const resumeIdFromPath = this?.props?.match?.params?.pk; // id from url
    // Above*
    // Can be called like so: <PrivateRoute path='/home/export_resume/:pk' component={ExportResume} />
    const resumeId = this.props?.resumeId
      ? this.props.resumeId
      : resumeIdFromPath;

    await this.props.getHasAccessToResume(resumeId);
    await this.props.getResume(resumeId);
    await this.props.getCurrentUser();
    await this.props.getProfile();

    // Resume content
    await this.props.getSummary(resumeId);
    await this.props.getExperiences(resumeId);
    await this.props.getEducations(resumeId);
    await this.props.getSkillGroups(resumeId);
    await this.props.getUserSkillMap(resumeId);
    await this.props.getSkillHours(resumeId);
    await this.props.getReferences(resumeId);
    await this.props.getLanguages(resumeId);
    await this.props.getHighlights(resumeId);

    this.setState({
      // Verify why we need this and instead use props instead.
      resumeVersion: this.props.resume.pk,
      dataLoadingComplete: true,
      profile: this.props.profile,
    });
  }

  render() {
    // Handle nulls, recall react renders a blank page on-load when waiting for data

    const experiences = this.props?.experiences?.data?.results
      ? this.props.experiences.data.results
      : [];

    const educations = this.props?.educations?.data?.results
      ? this.props.educations.data.results
      : [];

    const references = this.props?.references?.data?.results
      ? this.props.references.data.results
      : [];

    const userSkillMap = this.props?.userSkillMap?.data?.results
      ? this.props.userSkillMap.data.results
      : [];

    const skillGroups = this.props?.skillGroups?.data?.results
      ? this.props.skillGroups.data.results
      : [];

    const languages = this.props?.languages?.data?.results
      ? this.props.languages.data.results
      : [];

    const highlights = this.props?.highlights?.data?.results
      ? this.props.highlights.data.results
      : [];

    if (this.state.dataLoadingComplete && this.props.hasAccessToResume) {
      return (
        <PDFDownloadLink
          document={
            <MyDocument
              user={this.props.currentUser}
              profile={this.state.profile}
              summary={this.props.summary}
              experiences={experiences}
              educations={educations}
              references={references}
              skillGroups={skillGroups}
              userSkillMap={userSkillMap}
              languages={languages}
              highlights={highlights}
              hasColor={this.props.hasColor}
              shouldExportSkillBars={this.props.shouldExportSkillBars}
            />
          }
          fileName="resume.pdf"
          style={{ color: "#FFF" }}
        >
          {({ loading }) => (loading ? "Loading..." : this.props.buttonLabel)}
        </PDFDownloadLink>
      );
    }
    return <>Loading...</>;
  }
}

function mapStateToProps(state) {
  return {
    resume: state.resumeReducer.resume,
    currentUser: state.sharedReducer.currentUser,

    // Resume content
    hasAccessToResume: state.resumeReducer.hasAccessToResume,
    profile: state.sharedReducer.profile,
    summary: state.resumeReducer.summary,
    experiences: state.resumeReducer.experiences,
    educations: state.resumeReducer.educations,
    skillGroups: state.resumeReducer.skillGroups,
    userSkillMap: state.resumeReducer.userSkillMap,
    skillHours: state.resumeReducer.skillHours,
    references: state.resumeReducer.references,
    languages: state.resumeReducer.languages,
    highlights: state.resumeReducer.highlights,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      getProfile,
      getResume,
      getSummary,
      getExperiences,
      getEducations,
      getSkillGroups,
      getUserSkillMap,
      getSkillHours,
      getReferences,
      getLanguages,
      getHighlights,
      getHasAccessToResume,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
