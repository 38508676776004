// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep, debounce } from "lodash";
import DatePicker from "react-datepicker";

// Libs
import moment from "moment";

// Shared Resources
import RenderInput from "../../shared/RenderInput";
import {
  degreeOptions,
  majorOptions,
  is_form_valid,
  RenderMajorSelect,
  RenderDegreeSelect,
} from "../../shared/utils";

// App Resources
import {
  postEducation,
  putEducation,
  postSchool,
  getEducations,
  getSchools,
} from "../../actions";

// Bootstrap Resources
import {
  Button,
  ControlLabel,
  Form,
  FormGroup,
  Panel,
  Row,
  Col,
  Checkbox,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";

class EducationEditPanel extends Component {
  // Constructor
  state = {
    // input placeholders are different from the default values used in dropdowns
    classesTakenPlaceholder: "Classes Taken",
    clubsAndActivitiesPlaceholder: "Clubs & Activities",
    remarksPlaceholder:
      "Joined a sports team and graduated after an extra year.",
    gpaPlaceholder: "GPA",
    localEducation: cloneDeep(this.props.education),
    startDate: new Date(this.props.education.startDate), // local state independent of localEducation's nested date field only used for the calendar
    endDate: new Date(this.props.education.endDate), // local state independent of localEducation's nested date field only used for the calendar
  };

  updateSchoolName = (schoolId) => {
    let schoolName = null;
    schoolName = this.props.schools.filter((school) => {
      if (school.pk === parseInt(schoolId)) {
        return school.schoolName; // local return for "filter" statement.
      }
    });
    return schoolName[0];
  };

  updateEducationField = (field, pk, value) => {
    const modifiedEducation = cloneDeep(this.state.localEducation);
    modifiedEducation[field] = value;

    if (field === "school") {
      const schoolObj = this.updateSchoolName(value);
      if (schoolObj) {
        modifiedEducation["schoolName"] = schoolObj.schoolName;
      }
    }

    this.setState({ localEducation: modifiedEducation });
  };

  updateField = (field, value) => {
    const modified = cloneDeep(this.state.localEducation);

    if (field === "school" && Array.isArray(value) && value[0].customOption) {
      const schoolName = value[0].label;
      if (schoolName.length) {
        this.props
          .postSchool({
            schoolName: schoolName,
          })
          .then((res) => {
            value = res.payload.data.pk;
            modified[field] = value;
            this.setState({ localEducation: modified });
            return;
          });
      }
    }

    modified[field] = value;
    this.setState({ localEducation: modified });
  };

  onSubmit = () => {
    // validate forms.
    const { school, major, degree, startDate, endDate, ifCurrentSchool } =
      this.state.localEducation;

    if (
      !is_form_valid([
        school,
        major,
        degree,
        startDate,
        endDate || ifCurrentSchool,
      ])
    ) {
      alert("Errors in form - please correct before saving.");
      // don't save to db and don't toggle/erase data.
    } else if (endDate && endDate < startDate) {
      alert("Graduation Date must be after your Start Date");
    } else {
      // if doesn't exist create one.
      this.state.localEducation
        ? this.props.putEducation(
            this.state.localEducation,
            this.state.localEducation.pk,
          )
        : this.props.postEducation(this.state.localEducation);
      this.props.toggleEdit();
    }
  };

  handleChange = () => {
    this.updateEducationField(
      event.target.name,
      this.props.education.pk,
      event.target.value,
    );
  };

  handleToggleCheckbox = () => {
    // similar to handleChange
    this.updateField(
      event.target.name,
      !this.state.localEducation.ifCurrentSchool,
    );
  };

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
    }); // save  this to local datepicker component state.
    const startDateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateEducationField(
      "startDate",
      this.props.education.pk,
      startDateFormatted,
    );
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    }); // save  this to local datepicker component state.
    const endDateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateEducationField(
      "endDate",
      this.props.education.pk,
      endDateFormatted,
    );
  };

  async schoolFilter(input) {
    const debouncedFilter = debounce(() => {
      console.log("====>", input);
    }, 500);
    await this.props.getSchools(input, null); // take an option to filter by name
    debouncedFilter();
  }

  renderForm = () => {
    const {
      classesTaken,
      clubsAndActivities,
      remarks,
      gpa,
      major,
      degree,
      ifCurrentSchool,
    } = this.state.localEducation;

    return (
      <Form>
        <Row>
          <Col md={4}>
            <div style={{ paddingBottom: "5px" }}>
              <strong>School*</strong>
            </div>
            <Typeahead
              allowNew
              labelKey={(option) => `${option.schoolName}`}
              options={this.props.schools}
              placeholder="Start typing a school name"
              style={{ marginBottom: "10px" }}
              onInputChange={(input) => this.schoolFilter(input)}
              defaultSelected={this.props.defaultSelected}
              onChange={(selected) => {
                if (selected.length > 0) {
                  if (selected[0].pk) {
                    this.updateField("school", selected[0].pk);
                  } else {
                    this.updateField("school", selected);
                  }
                }
              }}
            />
          </Col>
          <Col md={2}>
            <FormGroup controlId="startDate">
              <ControlLabel>Start Date*</ControlLabel>
              <br />
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                className="form-control"
                maxDate={moment().toDate()}
              />
            </FormGroup>
          </Col>
          {ifCurrentSchool ? null : (
            <Col md={2}>
              <FormGroup controlId="endDate">
                <ControlLabel>Graduation*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          )}
          <Col md={3}>
            <Checkbox
              name="ifCurrentSchool"
              checked={ifCurrentSchool}
              // ensure checked state reflects current state of ifCurrentSchool correctly
              onChange={this.handleToggleCheckbox}
            >
              I currently study here
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <RenderMajorSelect
              label="Major*"
              name="major"
              initializedValue={major}
              type="select"
              onChange={this.handleChange}
              options={majorOptions}
            />
          </Col>
          <Col md={4}>
            <RenderDegreeSelect
              label="Degree*"
              name="degree"
              initializedValue={degree}
              type="select"
              onChange={this.handleChange}
              options={degreeOptions}
            />
          </Col>
          <Col md={4}>
            <RenderInput
              label="GPA"
              name="gpa"
              placeholder={this.state.gpaPlaceholder}
              initializedValue={gpa}
              type="input"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <RenderInput
              label="Classes Taken"
              name="classesTaken"
              type="textarea"
              placeholder={this.state.classesTakenPlaceholder}
              initializedValue={classesTaken}
              onChange={this.handleChange}
              rows={5}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <RenderInput
              label="Clubs and Activities"
              name="clubsAndActivities"
              type="textarea"
              placeholder={this.state.clubsAndActivitiesPlaceholder}
              initializedValue={clubsAndActivities}
              onChange={this.handleChange}
              rows={3}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <RenderInput
              label="Miscellaneous Remarks"
              name="remarks"
              type="textarea"
              placeholder={this.state.remarksPlaceholder}
              initializedValue={remarks}
              onChange={this.handleChange}
              rows={3}
            />
          </Col>
        </Row>
        <div className="modal-space-between-confirm-buttons">
          <Button bsStyle="primary" onClick={() => this.onSubmit()}>
            Save
          </Button>
          <Button
            bsStyle="warning"
            style={{ marginLeft: "5px" }}
            onClick={() => this.props.toggleEdit()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    return <Panel.Body>{this.renderForm()}</Panel.Body>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    education: ownProps.education,
    schools: state.resumeReducer.schools,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postEducation,
      putEducation,
      postSchool,
      getEducations,
      getSchools,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EducationEditPanel);
