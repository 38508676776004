
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import JobOpeningList from "./JobOpeningList";

// Component Specific Imports
import {
    getCurrentUser,
    getProfile,
    getJobOpenings
} from '../actions';

// Bootstrap Resources
import { Row } from 'react-bootstrap';

const pageStyle = {
    minHeight: 'calc(100vh)',
    backgroundColor: '#e8e8e8'
};

const contentStyle = {
    marginTop: '20px',
    paddingBottom: '40px',
    backgroundColor: '#fff',
    minHeight: '400px'
};

const center = {
    display: 'flex',
    justifyContent: 'center'
};


class JobOpeningListWrapper extends Component {

    componentDidMount () {
        this.props.getProfile();
        this.props.getCurrentUser();
        this.props.getJobOpenings();
    }

    content () {
        return (
            <div>
                <h3 style={center}>
                    Jobs
                </h3>
                <JobOpeningList jobOpenings={this.props.jobOpenings} />
            </div>
        )
    }

    render() {
        return (
            <Row style={pageStyle}>
                <div className="col-sm-8 col-sm-offset-2 lightBoxBorderNoRadius" style={contentStyle}>
                    { this.content() }
                </div>
            </Row>
        );
    }
}


function mapStateToProps(state) {
    return {
        currentUser: state.sharedReducer.currentUser,
        profile: state.sharedReducer.profile,
        jobOpenings: state.employerReducer.jobOpenings
    };
}


function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getProfile,
        getCurrentUser,
        getJobOpenings
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOpeningListWrapper);
