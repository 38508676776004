
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import { toggleExperienceModal } from '../../actions';
import ExperiencePanel from './ExperiencePanel';
import { CompaniesListProvider } from '../../contexts/CompaniesListContext';

// Bootstrap Resources
import { Button, Modal, Row } from 'react-bootstrap';
import ExperienceNewPanel from './ExperienceNewPanel';

// Import Shared
import { resume_owner_is_current_user } from '../../shared/utils';


class ExperienceModal extends Component {

    render() {
        const { isOpen } = this.props.experienceModal;
        let body = [];
        const { experiences } = this.props;
        const hasExistingData = !!(experiences && experiences.data && experiences.data.results && experiences.data.results.length);

        if (hasExistingData) {
            body = experiences.data.results.map((experience) => { // Todo: we need a request endpoint that joins the experience and company table.
                return (
                    <div>
                        <CompaniesListProvider value={this.props.companies}>
                            <ExperiencePanel experience={experience}/>
                        </CompaniesListProvider>
                        <hr className="hrstyle"/>
                    </div>
                );
            });
        }

        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            body.unshift(
                <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                    <Row>
                        <CompaniesListProvider value={this.props.companies}>
                            <ExperienceNewPanel hasExistingData={hasExistingData} />
                        </CompaniesListProvider>
                    </Row>
                    <hr className="hrstyle"/>
                </div>
            );
        }

        const title = (
            <strong>Experience</strong>
        );

        return (
            <div>
                <Modal
                  show={ isOpen }
                  bsSize="large"
                  onHide={() => this.props.toggleExperienceModal()}
                  backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {body}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleExperienceModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        experienceModal: state.resumeReducer.experienceModal,
        experiences: state.resumeReducer.experiences,
        companies: state.resumeReducer.companies
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleExperienceModal,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceModal);
