// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// App Resources
import {
  toggleSkillModal,
  getExperiences,
  getSkillGroups,
  getUserSkillMap,
  getSkillHours,
  getSkillRecommendations,
} from "../../actions";
import SkillGroupPanel from "./SkillGroupPanel";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

// Bootstrap Resources
import { Button, Glyphicon, Modal, Row } from "react-bootstrap";
import SkillGroupNewPanel from "./SkillGroupNewPanel";

// Import Shared
import { resume_owner_is_current_user } from "../../shared/utils";

class SkillModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      expandSkillExamples: false,
    };
  }

  componentDidMount() {
    // Load the most current info into store on dialog open.
    // Ensure we don't work with stale data in Skill Modal.
    const resumeId = this.props.resume.pk;

    this.props.getExperiences(resumeId);
    this.props.getSkillGroups(resumeId);
    this.props.getUserSkillMap(resumeId);
    this.props.getSkillHours(resumeId);
    this.props.getSkillRecommendations();
  }

  help = () => {
    const { skillRecommendations } = this.props;
    const skillRecommendationGroups = Object.keys(skillRecommendations);
    const example = skillRecommendationGroups.map((skillGroup) => {
      let skillsForGroup = skillRecommendations[skillGroup];
      let skills = skillsForGroup.map((skill) => {
        return (
          <>
            <p>{skill}</p>
            <br />
          </>
        );
      });

      return (
        <>
          <h5>{skillGroup}</h5>
          {skills}
        </>
      );
    });

    return (
      <>
        <h4>Example skill ideas for your career track</h4>
        {!this.state.expandSkillExamples ? (
          <>
            <Glyphicon
              glyph="chevron-down"
              style={{ color: "#2b71b1" }}
              onClick={() => this.setState({ expandSkillExamples: true })}
            />
            &nbsp;
            <p
              style={{ color: "#2b71b1" }}
              onClick={() => this.setState({ expandSkillExamples: true })}
            >
              View Examples
            </p>
          </>
        ) : (
          <>
            <Glyphicon
              glyph="chevron-up"
              style={{ color: "#2b71b1" }}
              onClick={() => this.setState({ expandSkillExamples: false })}
            />
            &nbsp;
            <p
              style={{ color: "#2b71b1" }}
              onClick={() => this.setState({ expandSkillExamples: false })}
            >
              Minimize
            </p>
            {example}
          </>
        )}
      </>
    );
  };

  render() {
    const isOpen = this.props.skillModal.isOpen;
    let { userSkillMap, skillGroups, experiences } = this.props;

    // pull the correct part of state we want since axios nests it under the data.results field
    userSkillMap =
      userSkillMap && userSkillMap.data && userSkillMap.data.results
        ? userSkillMap.data.results
        : [];

    skillGroups =
      skillGroups && skillGroups.data && skillGroups.data.results
        ? skillGroups.data.results
        : [];

    experiences =
      experiences && experiences.data && experiences.data.results
        ? experiences.data.results
        : [];

    let body = [];

    if (skillGroups !== null && skillGroups.length > 0) {
      body = skillGroups.map((skillGroup) => {
        const bundledSkills = userSkillMap.filter((obj) => {
          return obj.skillGroup === skillGroup.pk;
        });
        // Conditional to render edit mode here.
        return (
          <SkillGroupPanel
            skillGroup={skillGroup}
            bundledSkills={bundledSkills}
            experiences={experiences}
          />
        );
      });
    }

    // append an add skill group item to body.
    if (
      resume_owner_is_current_user(this.props.resume, this.props.currentUser)
    ) {
      body.push(
        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <hr className="hrstyle" />
          <Row>
            <SkillGroupNewPanel />
          </Row>
        </div>
      );
    }

    // map over each experience, and display the amount of experience for each skill here.
    const title = <h3>Skills</h3>;

    const header = experiences
      ? experiences.map((experience) => {
          // turn outer div below into a scrollable component.
          return (
            <div
              key={experience.pk}
              style={{
                minWidth: "70px",
                textAlign: "center",
                minHeight: "75px",
                verticalAlign: "middle",
                overflow: "scroll",
              }}
              className="rotate-fortyfive"
            >
              {experience.companyName}
            </div>
          );
        })
      : null;

    return (
      <div>
        <Modal
          show={isOpen}
          dialogClassName="modal-90w"
          onHide={() => this.props.toggleSkillModal()}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ScrollSync>
              <div>
                <Row>
                  <div
                    className="col-sm-1 col-sm-offset-2"
                    style={{ textAlign: "center" }}
                  >
                    <strong>Last Used</strong>
                  </div>
                  <div className="col-sm-8">
                    {/* 8 because the right one col used in the following section to show edit/delete for each skill that lines up with these headers. */}
                    <ScrollSyncPane group="one">
                      <div
                        className="flex-container-row"
                        style={{ paddingTop: "30px", paddingBottom: "0px" }}
                      >
                        {header}
                      </div>
                    </ScrollSyncPane>
                  </div>
                </Row>

                <hr />
                {this.help()}
                {body}
              </div>
            </ScrollSync>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={() => this.props.toggleSkillModal()}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resume: state.resumeReducer.resume,
    currentUser: state.sharedReducer.currentUser,
    skillModal: state.resumeReducer.skillModal,
    userSkillMap: state.resumeReducer.userSkillMap,
    skillGroups: state.resumeReducer.skillGroups,
    experiences: state.resumeReducer.experiences,
    skillRecommendations: state.sharedReducer.skillRecommendations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleSkillModal,
      getExperiences,
      getSkillGroups,
      getUserSkillMap,
      getSkillHours,
      getSkillRecommendations,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillModal);
