
// React Imports
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

// App Resources
import SkillPanel from './SkillPanel';

import {
    getCurrentUser,
    postUserSkillMap,
    getSkills,
    postSkill,
    putSkillGroup,
    deleteSkillGroup,
    toggleDeleteResumeItemModal
} from '../../actions';

// Bootstrap Resources
import { Button, Col, Form, Glyphicon, Panel, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

// Import Shared
import { resume_owner_is_current_user, is_form_valid } from '../../shared/utils';
import RenderInput from '../../shared/RenderInput';


class SkillGroupPanel extends Component {

    state = {
        addingNewSkill: false,
        editingSkillGroup: false,
        // State for userSkillMap itself and for skill hour
        // may need to add other userSkillMap fields like self defined ratings here too.
        localSkill: {
            user: null,
            resumeVersion: null,
            skillGroup: null,
            skill: null,
            skillLevel: 1,
            skillGroupName: null
        }
    };

    async componentDidMount() {
        // set the user
        await this.props.getCurrentUser();
        await this.props.getSkills();
        this.setState({
            localSkill: {
                ...this.state.localSkill,
                resumeVersion: this.props.resume.pk,
                user: this.props.currentUser.id,
                skillGroup: this.props.skillGroup.pk,
                skillGroupName: this.props.skillGroup.skillGroupName
            }
        });
    }

    showDeleteModal = (skillGroupId) => {
        this.props.toggleDeleteResumeItemModal(
            'Skill Group',
            () => this.props.deleteSkillGroup(skillGroupId),
            'Deleting this skill group will delete all skills in this group. You can cancel this action and ' +
            'move all skills in this group to another group.',
            true
        );
    };

    toggleAddSkill = () => {
        this.setState({ addingNewSkill: !this.state.addingNewSkill });
    };

    toggleEditSkillGroup = () => {
        this.setState({ editingSkillGroup: !this.state.editingSkillGroup });
    };

    updateField = (field, value) => {
        // This is the new skill form.
        const modified = cloneDeep(this.state.localSkill);

        if (field === 'skill' && Array.isArray(value) && value[0].customOption) {
            const newSkillName = value[0].label;
            if (newSkillName.length) {
                this.props.postSkill({
                    skillName: newSkillName
                }).then(res => {
                    value = res.payload.data.pk;
                    modified[field] = value;
                    this.setState({ localSkill: modified });
                    return;
                });
            }
        }

        modified[field] = value;
        this.setState({ localSkill: modified })
    };

    onSubmit = () => {
        this.props.postUserSkillMap(this.state.localSkill);
        this.toggleAddSkill();
    };

    onSaveSkillGroup = () => {
        // validate forms.
        const {
            skillGroupName
        } = this.state.localSkill;

        if (
            !is_form_valid([
                skillGroupName
            ])
        ) {
            return;
            // don't save to db and don't toggle/erase data.
        }

        this.props.putSkillGroup(this.state.localSkill);
        this.toggleEditSkillGroup();
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };


    // TODO: Handle the editing/preview state logic here inside renderSkillsSubsection ??
    // Can move to its own component as well. Look at Highlights Modal to see for each item.
    renderSkillsSubsection = () => {
        const {
            bundledSkills,
            experiences,
        } = this.props;

        const skillPreviewContent = bundledSkills.map((user_skill_map) => { // For each skill (make each skill section a SkillPanel Component)

            // BELOW for preview only
            // BELOW should be a SkillPanel object which then handles
            // if addingNewSkill state.
            // TODO: Handle the editing/preview logic here, on a per row basis
            // Can move below into its own component given the above input of skill
            // and inside this component define toggleAddSkill.
            // We need to move skillHrExDOM in here as well so that section of code too
            // This can be a dumb component with or one connected item to the global edit state

            return (
                <SkillPanel user_skill_map={user_skill_map} experiences={experiences} />
            )
        }); // end map // TODO: WHERE DOES THIS LOOPING occur in language

        return skillPreviewContent;
    };

    renderNewSkillForm = (skills) => {
        return (
            <Panel style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>Add a New Skill</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    {/*<p>Field for user_skill_map.skill.name -> add this to edit skill form as well</p>*/}
                    {/*<p>Field for each experience to add the hour</p>*/}
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Typeahead
                                  allowNew
                                  labelKey={option => `${option.skillName}`}
                                  options={skills}
                                  placeholder='Skill Name'
                                  style={{ marginBottom: '10px' }}
                                  onChange={selected => {
                                    if (selected.length > 0) {
                                        if (selected[0].pk) {
                                            this.updateField('skill', selected[0].pk);
                                        } else {
                                            this.updateField('skill', selected);
                                        }
                                      }
                                    }
                                  }
                                />
                            </Col>
                        </Row>
                        <div className="modal-space-between-confirm-buttons">
                            <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                            <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.toggleAddSkill()}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Panel.Body>
            </Panel>
        )
    };

    renderUpdateSkillGroupForm = () => {
        return (
            <Panel>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>Update Skill Group</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <RenderInput
                                  label='Skill Group Name*'
                                  name='skillGroupName'
                                  placeholder='Skill Group Name'
                                  initializedValue={this.state.localSkill.skillGroupName}
                                  type="input"
                                  onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <div className="modal-space-between-confirm-buttons">
                            <Button bsStyle="primary" onClick={() => this.onSaveSkillGroup()}>Save</Button>
                            <Button
                              bsStyle="warning"
                              style={{ marginLeft: '5px' }}
                              onClick={() => this.toggleEditSkillGroup()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Panel.Body>
            </Panel>
        )
    };

    render() {
        const {
            skillGroup,
            skills
        } = this.props;

        return (
            <div style={{ marginBottom: '25px' }}>
                <hr className="hrstyle"/>
                <Row>
                    <Col md={12} className="flex-container-row flex-align-center">
                        <p style={{ fontSize: '20px' }}><strong>{ skillGroup.skillGroupName }</strong></p>
                        {
                            resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                                (
                                    <div style={{ marginLeft: '10px' }}>
                                        <Glyphicon
                                          glyph="pencil"
                                          onClick={() => this.toggleEditSkillGroup()}
                                          style={{ color: '#2b71b1' }}
                                        />
                                        &nbsp;
                                        <Glyphicon
                                            glyph="remove"
                                            onClick={() => this.showDeleteModal(skillGroup.pk)}
                                            style={{ color: '#2b71b1' }}
                                        />
                                    </div>
                                ) :
                                null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={2} style={{ color: '#1F85DE', marginTop: '5px', marginBottom: '10px' }} onClick={() => this.toggleAddSkill()}>
                        {/* On click, this shows a form in this SkillGroupPanel component to add a skill */}
                        {
                            resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                                <>
                                    <Button
                                      bsStyle="success"
                                      bsSize="small"
                                      onClick={() => this.toggleAddSkill()}
                                    >
                                      Add Your { skillGroup.skillGroupName } Skills
                                    </Button>
                                </>:
                                null
                        }
                    </Col>
                </Row>
                <div>
                    { this.state.addingNewSkill ? this.renderNewSkillForm(skills) : null }
                    { this.state.editingSkillGroup ? this.renderUpdateSkillGroupForm() : null }
                    { this.renderSkillsSubsection() }
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        skills: state.employerReducer.skills,
        skillGroup: ownProps.skillGroup,
        experiences: ownProps.experiences
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postUserSkillMap,
            getCurrentUser,
            getSkills,
            postSkill,
            putSkillGroup,
            deleteSkillGroup,
            toggleDeleteResumeItemModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillGroupPanel);
