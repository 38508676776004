
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import SkillEditPanel from './SkillEditPanel';
import { ScrollSyncPane } from 'react-scroll-sync';
import {
    getCurrentUser,
    deleteUserSkillMap,
    toggleDeleteResumeItemModal
} from '../../actions';

// Bootstrap Resources
import { Row, Glyphicon } from 'react-bootstrap';

// Import Imgs
import glyph1 from '../../../../assets/img/glyphimg1bars.png';
import glyph2 from '../../../../assets/img/glyphimg2bars.png';
import glyph3 from '../../../../assets/img/glyphimg3bars.png';
import glyph4 from '../../../../assets/img/glyphimg4bars.png';
import glyph5 from '../../../../assets/img/glyphimg5bars.png';

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class SkillPanel extends Component {
    state = {
        editing: false
    };

    async componentDidMount () {
        await this.props.getCurrentUser();
    }

    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    showDeleteModal = user_skill_map_id => {
        this.props.toggleDeleteResumeItemModal(
            'skill',
            () => this.props.deleteUserSkillMap(user_skill_map_id)
        );
    };

    renderPreview = (user_skill_map, experiences) => {
        let skillHrExForThisSkill = [];

        if (this.props.skillHours.data && this.props.skillHours.data.results.length > 0) {
            skillHrExForThisSkill = this.props.skillHours.data.results.filter(obj => obj.userSkillMap === user_skill_map.pk);
        }

        const glyphImgArr = [glyph1, glyph2, glyph3, glyph4, glyph5];
        let endDates = [];
        let skillHrExDOM = experiences.map((experience) => {
            // For each experience for each skill, render the skillHrExperience value in preview mode.
            let skillHrValue = skillHrExForThisSkill.find(obj => obj.experience === experience.pk);

            if (skillHrValue) {
                // it enters this for each experience, then we find the most recent one below
                // Use flexbox instead of columns for unlimited growth. and this outer div as overflow scroll.
                // and width of px
                if (skillHrValue.hours > 0) {
                    // Make sure we only add end dates if a skill was used in a certain experience
                    // Also if a skills was not used at any experiences, endDates will be an empty array and downstream rendered as mostRecentEndDate -> '--'
                    endDates.push(new Date(experience.endDate)); // walk through all experiences and add them to the array if the skill was actually used at that company
                }

                return (
                    <div key={skillHrValue.pk} style={{minWidth: '70px', textAlign: 'center'}}>
                        <p>{skillHrValue.hours === 0 ? '--' : skillHrValue.hours}</p>
                    </div>
                )
            }
            return (
                <div key={experience.pk} style={{minWidth: '70px', textAlign: 'center'}}>
                    <p>--</p>
                </div>
            )
        });

        // Calculate mostRecentEndDate
        let mostRecentEndDate = '--';
        if (endDates.length >= 1) {  // find the most recent endDate for this skill
            mostRecentEndDate = endDates.reduce(function (a, b) {
                return a > b ? a : b;
            });
            mostRecentEndDate = mostRecentEndDate.getFullYear();
        }

        return (
            <Row key={user_skill_map.skill.pk} style={{ height: "30px" }}>
                <div className="col-sm-2">
                    <img src={glyphImgArr[user_skill_map.skillLevel - 1]} width="22" height="22" alt="glyphImg"/>&nbsp;
                    <p style={{ marginLeft: '5px' }}>{user_skill_map.skill.skillName}</p>
                </div>
                <div className="col-sm-1" style={{ textAlign: 'center' }}>
                    {mostRecentEndDate}
                </div>
                <div className="col-sm-8">
                    <ScrollSyncPane group="one">
                        <div className="flex-container-row">
                            {skillHrExDOM}
                        </div>
                    </ScrollSyncPane>
                </div>
                <div className="col-sm-1" style={{ textAlign: 'right' }}>
                    {
                        resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                            (
                                <>
                                    <Glyphicon
                                        glyph="pencil"
                                        onClick={this.toggleEdit}
                                        style={{ color: '#2b71b1' }}
                                    />
                                    &nbsp;
                                    <Glyphicon
                                        glyph="remove"
                                        onClick={
                                            () => this.showDeleteModal(user_skill_map.pk)
                                        }
                                        style={{ color: '#2b71b1' }}
                                    />
                                </>
                            ) :
                            null
                    }
                </div>
            </Row>
        );
    };

    renderBody = () => {
        const { user_skill_map, experiences } = this.props;
        return (this.state.editing
            ? <SkillEditPanel user_skill_map={user_skill_map} toggleEdit={this.toggleEdit}/>
            : this.renderPreview(user_skill_map, experiences));
    };


    render () {
        return this.renderBody();
    }
}

function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        skillHours: state.resumeReducer.skillHours,
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            getCurrentUser,
            deleteUserSkillMap,
            toggleDeleteResumeItemModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillPanel);
