import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// Component Specific Imports
import { getCurrentUser, getJobFairs } from "../actions";

// Bootstrap
import { Button, Glyphicon, Row } from "react-bootstrap";
import moment from "moment";
import EditJobFairModal from "./EditJobFairModal";

class SchoolJobFairTable extends Component {
  state = {
    selectedJobFairId: null,
    editJobFairOpen: false,
  };

  async componentDidMount() {
    await this.props.getCurrentUser();
    await this.props.getJobFairs();
  }

  formatDate = (rawDate) => {
    return moment(rawDate).isValid()
      ? moment(rawDate).format("MMMM D, YYYY")
      : "";
  };

  toggleEditJobFair = (selectedJobFairId = null) => {
    this.setState({
      editJobFairOpen: !this.state.editJobFairOpen,
      selectedJobFairId,
    });
  };

  createTable() {
    if (!this.props.jobFairs?.data || !this.props.jobFairs?.data.length) {
      return null;
    } else {
      const tableBody = this.props.jobFairs.data.map((each_job_fair) => {
        return (
          <div key={each_job_fair.id}>
            <Row style={{ height: "40px" }}>
              <div
                className="col-sm-3 vertical-align"
                style={{ height: "100%" }}
              >
                <p>
                  <strong>{each_job_fair.name}</strong>
                </p>
              </div>
              <div className="col-sm-3 row-fluid vertical-align vhr">
                <p>
                  {this.formatDate(each_job_fair.startDate)} -{" "}
                  {this.formatDate(each_job_fair.endDate)}
                </p>
              </div>
              {/*<div className="col-sm-1 vertical-align vhr" style={{ height: "100%" }}>*/}
              {/*    <p>800 attending</p>*/}
              {/*</div>*/}
              <div
                className="col-sm-1 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <p>{each_job_fair.companies_attending_count}</p>
              </div>
              <div
                className="col-sm-2 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <p>{each_job_fair.companies_not_yet_accepted_count}</p>
              </div>
              <div
                className="col-sm-2 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <Button
                  bsStyle="success"
                  bsSize="small"
                  onClick={() =>
                    this.props.history.push(`/home/jobfair/${each_job_fair.id}`)
                  }
                >
                  Preview Job Fair <Glyphicon glyph="search" />
                </Button>
              </div>
              <div
                className="col-sm-1 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  onClick={() => this.toggleEditJobFair(each_job_fair.id)}
                >
                  Manage
                </Button>
              </div>
            </Row>
            <hr className="hrZeroMargin" />
          </div>
        );
      });

      const tableHeader = (
        <div>
          <Row style={{ height: "40px", backgroundColor: "#e6ecf5" }}>
            <div className="col-sm-3 vertical-align" style={{ height: "100%" }}>
              <p>
                <strong>Job Fair Name</strong>
              </p>
            </div>
            <div className="col-sm-3 row-fluid vertical-align vhr">
              <p>
                <strong>Dates</strong>
              </p>
            </div>
            {/*<div className="col-sm-1 vertical-align vhr" style={{ height: "100%" }}>*/}
            {/*    <p>Student Attendees</p>*/}
            {/*</div>*/}
            <div
              className="col-sm-1 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <p>
                <strong>Companies Attending</strong>
              </p>
            </div>
            <div
              className="col-sm-2 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <p>
                <strong>Companies Awaiting Acceptance</strong>
              </p>
            </div>
            <div
              className="col-sm-2 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <strong>Preview Job Fair</strong>
            </div>
            <div
              className="col-sm-1 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <strong>Settings</strong>
            </div>
          </Row>
        </div>
      );

      const table = (
        <div style={{ marginTop: "30px" }}>
          {tableHeader}
          {tableBody}
        </div>
      );

      return (
        <div>
          {table}
          <EditJobFairModal
            editJobFairOpen={this.state.editJobFairOpen}
            selectedJobFairId={this.state.selectedJobFairId}
            jobFairList={this.props.jobFairs.data}
            toggleEditJobFair={() => this.toggleEditJobFair()}
            organizer_id={this.props.school ? this.props.school.pk : null}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="col-sm-12">
        <div className="resumeformatContentArea">{this.createTable()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    jobFairs: state.employerReducer.jobFairs,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      getJobFairs,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchoolJobFairTable)
);
