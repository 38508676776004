import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Component Specific Imports
import {
  getResumes,
  getUsers,
  getCurrentUser,
  getProfile,
  getEmployerForEmployee,
} from "../actions";
import ResumeTable from "./ResumeTable";
import EmployerJobOpeningTable from "./EmployerJobOpeningTable";
import ActivityTable from "./activityTable";
import EmployerActivityTable from "./EmployerActivityTable";

// Bootstrap Resources
import { Row, Modal } from "react-bootstrap";
import axios from "axios";

const lhStyle = {
  backgroundColor: "#2d2d2d",
  color: "white",
  minHeight: "calc(100vh)",
  alignContent: "center",
  textAlign: "center",
};

const rhStyle = {
  backgroundColor: "#fff",
  minHeight: "calc(100vh)",
  paddingTop: "20px",
};

const contentStyle = {
  minHeight: "100%",
};

const resumeTable = {
  backgroundColor: "#fff",
};

const activityTable = {
  // margins except (first child, using pseudo classes)
  backgroundColor: "#fff",
  marginTop: "30px",
};

const zeroPadding = {
  padding: "0 0 0 0",
};

const defaultProfPic = require(`${"../../../assets/img/profilepic.png"}`);

class Home extends Component {
  state = {
    showUpload: false,
    images: [],
  };

  componentDidMount() {
    this.props.getUsers(); // loaded by map state to props
    this.props.getProfile();
    this.props.getResumes();
    this.props.getCurrentUser();
  }

  addDefaultImage(e) {
    e.target.src = defaultProfPic;
  }

  toggleProfileImage = () => {
    this.setState({ showUpload: !this.state.showUpload });
  };

  showUpload() {
    return (
      <Modal
        show={this.state.showUpload}
        bsSize="large"
        onHide={() => this.toggleProfileImage()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose a Headshot</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <input
            type="file"
            id="single"
            onChange={(e) => {
              const file = e.target.files[0];
              const formData = new FormData();
              formData.append("image", file);

              const url = `/image_upload?user_type=CANDIDATE`;
              const token = localStorage.getItem("token");

              axios
                .put(url, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/x-www-form-urlencoded",
                  },
                })
                .then(() => {
                  caches
                    .keys()
                    .then((keyList) =>
                      Promise.all(keyList.map((key) => caches.delete(key)))
                    );
                  window.location.reload();
                  caches
                    .keys()
                    .then((keyList) =>
                      Promise.all(keyList.map((key) => caches.delete(key)))
                    );
                });
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }

  left() {
    let firstName = null;
    if (this.props.currentUser?.first_name) {
      firstName = this.props.currentUser.first_name;
    }
    let lastName = null;
    if (this.props.currentUser?.last_name) {
      lastName = this.props.currentUser.last_name;
    }

    const { profile } = this.props;
    const idealPosition = profile ? profile.idealPosition : null;
    const city = profile ? profile.city : null;
    const state = profile ? profile.state : null;
    const phoneNumber = profile ? profile.phoneNumber : null;

    // TODO: Below when deploying to non-localhost env, In the future handle default name path check
    const avatar = profile && profile.avatar !== "" ? profile.avatar : null;

    let profPic = defaultProfPic; // Handles case when image is not in database and not in media folder.
    if (this.props.currentUser && avatar) {
      profPic = `../../../media/profilepic${this.props.currentUser.id}.${avatar
        .split(".")
        .pop()}`;
    }

    return (
      <Row style={lhStyle}>
        <div className="col-sm-12" style={zeroPadding}>
          <img
            src={profPic}
            alt="profPic"
            onError={this.addDefaultImage}
            className="imgStyle"
            onClick={() => {
              this.toggleProfileImage();
            }}
          />
          <h3>
            {firstName} {lastName}
          </h3>
          <p>
            <strong>
              {profile?.isRecruiter
                ? this.props.employer_of_recruiter.companyName
                : idealPosition}
            </strong>
          </p>
          <br />
          <p>
            {city}, {state}
          </p>
          <br />
          <br />
          <p>{phoneNumber}</p>
          {this.showUpload()}
        </div>
      </Row>
    );
  }

  right() {
    let content = null;
    if (this.props?.profile?.isRecruiter) {
      content = <EmployerJobOpeningTable />;
    } else {
      content = <ResumeTable />;
    }

    const activityTable = (
      <Row>
        <div className="col-sm-12" style={activityTable}>
          {this.props?.profile?.isRecruiter ? (
            <EmployerActivityTable />
          ) : (
            <ActivityTable history={this.props.history} />
          )}
        </div>
      </Row>
    );

    return (
      <div style={rhStyle}>
        <Row>
          <div className="col-sm-12" style={resumeTable}>
            {content}
          </div>
        </Row>

        {/* TODO only show if developing job fair tools integration */}
        {/*{activityTable}*/}
      </div>
    );
  }

  content() {
    return (
      <Row style={contentStyle}>
        <div className="col-sm-2">{this.left()}</div>
        <div className="col-sm-10">{this.right()}</div>
      </Row>
    );
  }

  render() {
    const { profile } = this.props;
    if (profile?.isRecruiter) {
      this.props.history.push("/home/employer/dashboard");
    }
    if (profile?.isSchoolUser) {
      this.props.history.push("/home/school/dashboard");
    }
    return this.content();
  }
}

function mapStateToProps(state) {
  return {
    users: state.sharedReducer.users,
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    employer_of_recruiter: state.employerReducer.employer_of_recruiter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUsers,
      getResumes,
      getProfile,
      getCurrentUser,
      getEmployerForEmployee,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
