
// React Resources
import React  from 'react';
// Bootstrap Resources
import { Panel } from 'react-bootstrap';


const renderClassesTaken = (education) => {
    if (education.classesTaken) {
        return (
            <div>
                <h5><strong>Classes Taken</strong></h5>
                <pre style={{ marginLeft: '20px' }}>
                    { education.classesTaken }
                </pre>
            </div>
        );
    }
};

const renderClubsAndActivities = (education) => {
    if (education.clubsAndActivities) {
        return (
            <div>
                <h5><strong>Clubs and Activities</strong></h5>
                <p style={{ marginLeft: '20px' }}>{ education.clubsAndActivities }</p>
            </div>
        );
    }
};

const renderRemarks = (education) => {
    if (education.clubsAndActivities) {
        return (
            <div>
                <h5><strong>Remarks</strong></h5>
                <p style={{ marginLeft: '20px' }}>{ education.remarks }</p>
            </div>
        );
    }
};

const EducationPreviewPanel = ({ education }) => {

    return (
        <Panel.Body>
            {renderClassesTaken(education)}
            {renderClubsAndActivities(education)}
            {renderRemarks(education)}
        </Panel.Body>
    );
};

export default EducationPreviewPanel;