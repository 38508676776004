
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import {
    handle_login,
    handle_signup,
    getCompanies
} from '../actions';

// Bootstrap Resources
import { Col, Row, Form, FormGroup, FormControl, Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';


class EmployerLoginForm extends Component {

    state = {
        signup: true,
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        company_id: null
    };

    componentDidMount () {
        this.props.getCompanies();
    }

    toggleSignup = () => {
        this.setState({signup: !this.state.signup})
    };

    updateField = (field, value) => {
        this.setState({ [field]: value })
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    async onSubmit (type) {
        if (type == 'login') {
            await this.props.handle_login(
                this.state.username,
                this.state.password
            );
            this.props.history.push('/home/dashboard');  // todo: implement this page
        } else {
            await this.props.handle_signup(
                this.state.username,
                this.state.password,
                this.state.firstName,
                this.state.lastName,
                this.state.company_id
            );
            this.props.history.push('/home/employer/onboarding/terms_conditions');
        }
    };

    signup () {
        return (
          <Form>
            <h2><strong>Sign Up</strong></h2>
            <FormGroup style={{ marginBottom: '15px' }}>
                <Row>
                  <Col md={6}>
                    <FormControl placeholder="First name" name="firstName" onChange={this.handleChange} />
                  </Col>
                  <Col md={6}>
                    <FormControl placeholder="Last name" name="lastName" onChange={this.handleChange} />
                  </Col>
                </Row>
            </FormGroup>

            <FormGroup controlId="formBasicEmail">
                <Row>
                    <Col md={12}>
                        <Typeahead
                          allowNew
                          labelKey={option => `${option.companyName}`}
                          options={this.props.companies ? this.props.companies : []}
                          placeholder='Start typing a company name'
                          style={{ marginBottom: '10px' }}
                          onChange={selected => {
                            if (selected.length > 0) {
                                if (selected[0].pk) {
                                    this.updateField('company_id', selected[0].pk);
                                } else {
                                    this.updateField('company_id', selected);
                                }
                              }
                            }
                          }
                        />
                    </Col>
                </Row>
            </FormGroup>
            {/* Typeahead, Allow employer to claim company if a company in company table exists, like Linkedin */}

            <FormGroup controlId="formBasicEmail">
              <FormControl type="email" placeholder="Enter email" name="username" onChange={this.handleChange} />
            </FormGroup>
            {/* In the meantime can assume username to be email for signing up new accounts */}

            <FormGroup controlId="formBasicPassword">
              <FormControl type="password" placeholder="Password" name="password" onChange={this.handleChange} />
            </FormGroup>

            <FormGroup>
              <Button bsStyle="primary" style={{ marginRight: '8px' }} onClick={() => this.onSubmit('signup')}>
                Sign Up
              </Button>
              <p style={{ color: '#6666FF' }}>
                Already have an account? Login Here
              </p>
            </FormGroup>
          </Form>
        );
    }

    login () {
        return (
          <Form>
            <h2><strong>Login</strong></h2>
            <FormGroup controlId="formBasicEmail">
              <FormControl type="text" placeholder="Enter email or username" name="username" onChange={this.handleChange} />
            </FormGroup>

            <FormGroup controlId="formBasicPassword">
              <FormControl type="password" placeholder="Password" name="password" onChange={this.handleChange} />
            </FormGroup>

            <FormGroup>
              <Button bsStyle="primary" style={{ marginRight: '8px' }} onClick={() => this.onSubmit('login')}>
                Login
              </Button>
              <p onClick={this.toggleSignup} style={{ color: '#6666FF' }}>
                New to Findfit? Sign Up Here
              </p>
            </FormGroup>
          </Form>
        );
    }

    render () {
        return (
          <Row>
            <Col sm={8} style={{ backgroundColor: "#FFF", marginLeft: '20px', marginTop: '50px', paddingTop: '20px', paddingBottom: '20px', borderRadius: '10px' }} className="text-left">
              {this.state.signup ? this.signup() : this.login()}
            </Col>
          </Row>
        );
    }
}


function mapStateToProps(state) {
    return {
        companies: state.resumeReducer.companies
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            handle_login,
            handle_signup,
            getCompanies
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerLoginForm);
