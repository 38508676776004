import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// Component Specific Imports
import {
  employerApplyToJobFair,
  getCurrentUser,
  getJobFairs,
} from "../actions";

// Bootstrap
import { Button, Glyphicon, Modal, Row } from "react-bootstrap";
import moment from "moment";

const ApplyToJobFairModal = ({
  isOpen,
  jobFair,
  toggleOpen,
  employerOfRecruiter,
  applyFn,
}) => {
  const applyToJobFair = () => {
    // pass in the company_id + job_fair id and search for the employer job fair map instance that way
    applyFn({ job_fair: jobFair.id, company: employerOfRecruiter.pk });
  };

  return (
    <Modal
      show={isOpen}
      style={{ marginTop: "40px" }}
      onHide={() => toggleOpen()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply to {jobFair?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-space-between-confirm-buttons">
        <Button
          bsStyle="success"
          onClick={() => {
            applyToJobFair();
            toggleOpen();
          }}
        >
          Confirm
        </Button>
        <Button
          bsStyle="default"
          style={{ marginLeft: "5px" }}
          onClick={() => toggleOpen()}
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

class EmployerBrowseJobFairTable extends Component {
  state = {
    selectedJobFair: {},
    applyToJobFairModalOpen: false,
  };

  async componentDidMount() {
    await this.props.getCurrentUser();
    await this.props.getJobFairs();
  }

  formatDate = (rawDate) => {
    return moment(rawDate).isValid()
      ? moment(rawDate).format("MMMM D, YYYY")
      : "";
  };

  toggleModal = (selectedJobFair = {}) => {
    this.setState({
      applyToJobFairModalOpen: !this.state.applyToJobFairModalOpen,
      selectedJobFair,
    });
  };

  createTable() {
    if (!this.props.jobFairs?.data || !this.props.jobFairs?.data.length) {
      return null;
    } else {
      const tableBody = this.props.jobFairs.data.map((each_job_fair) => {
        return (
          <div key={each_job_fair.id}>
            <Row style={{ height: "40px" }}>
              <div
                className="col-sm-3 vertical-align"
                style={{ height: "100%" }}
              >
                <p>
                  <strong>{each_job_fair.name}</strong>
                </p>
              </div>
              <div className="col-sm-3 row-fluid vertical-align vhr">
                <p>
                  {this.formatDate(each_job_fair.startDate)} -{" "}
                  {this.formatDate(each_job_fair.endDate)}
                </p>
              </div>
              {/* Future enhancement to track students attending each fair */}
              <div
                className="col-sm-1 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <p>{each_job_fair.companies_attending_count}</p>
              </div>
              <div
                className="col-sm-2 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  onClick={() =>
                    this.props.history.push(`/home/jobfair/${each_job_fair.id}`)
                  }
                >
                  Preview Job Fair <Glyphicon glyph="search" />
                </Button>
              </div>
              <div
                className="col-sm-3 vertical-align vhr"
                style={{ height: "100%" }}
              >
                <Button
                  bsStyle="success"
                  bsSize="small"
                  onClick={() => this.toggleModal(each_job_fair)}
                >
                  Apply to Participate <Glyphicon glyph="arrow-right" />
                </Button>
              </div>
            </Row>
            <hr className="hrZeroMargin" />
          </div>
        );
      });

      const tableHeader = (
        <div>
          <Row style={{ height: "40px", backgroundColor: "#e6ecf5" }}>
            <div className="col-sm-3 vertical-align" style={{ height: "100%" }}>
              <p>
                <strong>Job Fair Name</strong>
              </p>
            </div>
            <div className="col-sm-3 row-fluid vertical-align vhr">
              <p>
                <strong>Dates</strong>
              </p>
            </div>
            {/*<div className="col-sm-1 vertical-align vhr" style={{ height: "100%" }}>*/}
            {/*    <p>Student Attendees</p>*/}
            {/*</div>*/}
            <div
              className="col-sm-1 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <p>
                <strong>Companies Attending</strong>
              </p>
            </div>
            <div
              className="col-sm-2 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <strong>Preview Job Fair</strong>
            </div>
            <div
              className="col-sm-3 vertical-align vhr"
              style={{ height: "100%" }}
            >
              <strong>Apply</strong>
            </div>
          </Row>
        </div>
      );

      const table = (
        <div style={{ marginTop: "30px" }}>
          {tableHeader}
          {tableBody}
        </div>
      );

      return (
        <div>
          {table}
          <ApplyToJobFairModal
            isOpen={this.state.applyToJobFairModalOpen}
            jobFair={this.state.selectedJobFair}
            toggleOpen={() => this.toggleModal()}
            employerOfRecruiter={this.props.employer_of_recruiter}
            applyFn={employerApplyToJobFair}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="col-sm-12">
        {this.props.jobFairs?.data && this.props.jobFairs.data.length && (
          <h3>Explore Other Job Fairs</h3>
        )}
        <div className="resumeformatContentArea">{this.createTable()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    jobFairs: state.employerReducer.jobFairs,
    employer_of_recruiter: state.employerReducer.employer_of_recruiter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      getJobFairs,
      employerApplyToJobFair,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerBrowseJobFairTable)
);
