
import React from 'react';

// Bootstrap Resources
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';


const RenderSelect = ({ label, name, type, value, onChange,  options=[], initializedValue=null, onBlur=null }) => {
    // Usage: Call this with options = [{id: ##, name: XX}, {}...]

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              defaultValue={(initializedValue || initializedValue === 0) ? initializedValue : null}
              value={(value || value === 0) ? value : null}
              onChange={onChange}
              onBlur={onBlur || (() => {})}
            >
                {
                    options.map(item => (
                        <option value={ item.id } key={ item.id }>{ item.name }</option>
                    ))
                }
            </FormControl>
        </FormGroup>
    );
};

export default RenderSelect;
