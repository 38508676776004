
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import { toggleLanguageModal } from '../../actions';
import LanguagePanel from './LanguagePanel';
import LanguageNewPanel from './LanguageNewPanel';

// Bootstrap Resources
import { Button, Modal, Row, Col } from 'react-bootstrap';

import { resume_owner_is_current_user } from '../../shared/utils';


class LanguageModal extends Component {

    render() {
        const { isOpen } = this.props.languageModal;
        let body = [];
        const { languages } = this.props;
        const hasExistingData = !!(languages && languages.data && languages.data.results && languages.data.results.length);

        if (hasExistingData) {
            body = languages.data.results.map((language) => {
                return (
                    <div style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px' }}>
                        <Row>
                            <LanguagePanel language={language} />
                        </Row>
                        <hr className="hrstyle"/>
                    </div>
                );
            });
        }

        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            body.unshift(
                <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                    <Row>
                        <LanguageNewPanel language={null} hasExistingData={hasExistingData} />
                    </Row>
                    <hr className="hrstyle"/>
                </div>
            );
        }

        const title = (
            <div>
                <Row>
                    <Col md={12}>
                        <strong>Languages</strong>
                    </Col>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                    <Col md={4} /> {/* TODO: Use proper offset */}
                    <Col md={2}>
                        <span>Speaking</span>
                    </Col>
                    <Col md={2}>
                        <span>Reading</span>
                    </Col>
                    <Col md={2}>
                        <span>Writing</span>
                    </Col>
                    <Col md={3} />  {/* Filler */}
                </Row>
            </div>
        );

        return (
            <div>
                <Modal
                  show={ isOpen }
                  bsSize="large"
                  onHide={() => this.props.toggleLanguageModal()}
                  backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {body}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleLanguageModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        languageModal: state.resumeReducer.languageModal,
        languages: state.resumeReducer.languages
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleLanguageModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageModal);
