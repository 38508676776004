
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import { toggleDeleteResumeItemModal } from '../../actions';

// Bootstrap Resources
import { Button, Modal } from 'react-bootstrap';

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class DeleteResumeItemModal extends Component {

    render() {
        const {
            isOpen,
            itemName,
            deleteItemFn,
            relatedItems,
            cascadeDeleteItem
        } = this.props.deleteResumeItemModal;

        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            return (
                <Modal show={isOpen} style={{marginTop: '40px'}}
                       onHide={() => this.props.toggleDeleteResumeItemModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you want to delete this {itemName}?</Modal.Title>
                        <br/>
                        {
                            cascadeDeleteItem
                                ? (<p>{relatedItems}</p>)
                                : null
                        }
                    </Modal.Header>

                    <Modal.Body className="modal-space-between-confirm-buttons">
                        <Button
                          bsStyle="danger"
                          onClick={() => {
                              deleteItemFn();
                              this.props.toggleDeleteResumeItemModal();
                          }}>
                              Yes
                          </Button>
                        <Button
                          bsStyle="default"
                          style={{marginLeft: '5px'}}
                          onClick={() => this.props.toggleDeleteResumeItemModal()}
                        >
                            Cancel
                        </Button>
                    </Modal.Body>
                </Modal>
            )
        }
        return null;
    }
}


function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        deleteResumeItemModal: state.resumeReducer.deleteResumeItemModal
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleDeleteResumeItemModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteResumeItemModal);
