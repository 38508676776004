
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import DatePicker from 'react-datepicker';


// Libs
import moment from 'moment';

// App Resources
import RenderInput from '../../shared/RenderInput';
import {
    postHighlight,
    putHighlight,
    getExperiences
} from '../../actions';

// Bootstrap Resources
import { Button, ControlLabel, Form, FormGroup, FormControl, Panel, Row, Col } from 'react-bootstrap';


const RenderExperienceSelect = ({ label, type, experience, experiences, name, onChange }) => {
    // TODO: Add error checking

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={experience ? experience : -1}
              onChange={onChange}
            >
                <option value={-1} key={-1}>Select where you and they worked</option>
                {
                    experiences.data.results.map(experienceObj => (
                        <option value={experienceObj.pk} key={experienceObj.pk}>{ experienceObj.companyName }</option>
                ))}
            </FormControl>
        </FormGroup>
    );
};

class HighlightsEditPanel extends Component {

    // Constructor
    state = {
        // input placeholders are different from the default values
        // used in dropdowns
        highlightDate: new Date(this.props.highlight.highlightDate), // local state independent of localHighlight's highlightDate nested field only used for the calendar
        // todo: think we can in future refactor to use localHighlight's nested field
        localHighlight: cloneDeep(this.props.highlight)
    };

    updateHighlightField = (field, pk, value) => {
        const modifiedHighlight = cloneDeep(this.state.localHighlight);
        modifiedHighlight[field] = value;
        this.setState({ localHighlight: modifiedHighlight })
    };

    onSubmit = () => {
        // if doesn't exist create one.
        this.state.localHighlight ? this.props.putHighlight(this.state.localHighlight, this.state.localHighlight.pk) : this.props.postHighlight(this.state.localHighlight);
        this.props.toggleEdit();
    };

    handleChange = () => {
        this.updateHighlightField(event.target.name, this.props.highlight.pk, event.target.value);
    };

    handleHighlightDateChange = (date) => {
        this.setState({
            highlightDate: date
        }); // save  this to local datepicker component state.
        const highlightDateFormatted = moment(date).format('YYYY-MM-DD');
        this.updateHighlightField('highlightDate', this.props.highlight.pk, highlightDateFormatted);
    };

    renderForm = () => {
        const {
            quantity,
            fullDescription,
            experience,
        } = this.state.localHighlight;

        return (
            <Form>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='Quantitative Impact of your Highlight*'
                          name='quantity'
                          placeholder='30% Sales Increase'
                          initializedValue={quantity}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={3}>
                        <FormGroup controlId='highlightDate'>
                            <ControlLabel>Date*</ControlLabel>
                            <br/>
                            <DatePicker
                              selected={this.state.highlightDate}
                              onChange={this.handleHighlightDateChange}
                              className="form-control"
                              maxDate={moment().toDate()}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <RenderExperienceSelect
                          label='Company*'
                          name='experience'
                          type='select'
                          experience={experience}
                          experiences={this.props.experiences}
                          onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <RenderInput
                          label='Full Description (Seen in the detailed view)'
                          name='fullDescription'
                          placeholder='Captured leads from high valued customers from website visits.'
                          initializedValue={fullDescription}
                          type="textarea"
                          onChange={this.handleChange}
                          rows={10}
                        />
                    </Col>
                </Row>
                <div className="modal-space-between-confirm-buttons">
                    <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                    <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.props.toggleEdit()}>Cancel</Button>
                </div>
            </Form>
        );
    };

    render() {
        const { highlight, companyName } = this.props;

        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>{ highlight.quantity }</strong> at { companyName }</p>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    { this.renderForm() }
                </Panel.Body>
            </Panel>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        highlight: ownProps.highlight,
        experiences: state.resumeReducer.experiences
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postHighlight,
            putHighlight,
            getExperiences
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(HighlightsEditPanel);
