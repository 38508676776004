import React, { Component } from 'react';

// Libs
import moment from 'moment';

// Import Helpers
import { truncate } from '../shared/utils';

// Bootstrap Resources
import { Row } from 'react-bootstrap';

// Import Imgs
import glyph1 from '../../../assets/img/glyphimg1bars.png';
import glyph2 from '../../../assets/img/glyphimg2bars.png';
import glyph3 from '../../../assets/img/glyphimg3bars.png';
import glyph4 from '../../../assets/img/glyphimg4bars.png';
import glyph5 from '../../../assets/img/glyphimg5bars.png';


const RenderSkillGroup = ({
        skillGroup,
        bundledSkills,
        skillHours,
        experiences
    }) => {

    let body = (<span></span>);
    body = bundledSkills.map(userSkillMap => { // for each userSkillMap

        // Initialize variables for each userSkillMap
        let startDate = null;
        let endDate = null;
        let totalYearsAcrossExperiences = 0;  // total years used (number of years spanned based on sum of years across tenure at companies this skill was used)
        let totalHoursOfExperience = 0;  // total hours across experiences for this skill
        let endDates = [];
        const ANNUAL_WORK_WEEKS = 50;  // assuming a user takes 2 weeks off/year.
        const glyphImgArr = [glyph1, glyph2, glyph3, glyph4, glyph5];

        // Gather all the data we need from each experience for this skill
        const skillHrExForThisSkill = skillHours ? skillHours.filter(obj => obj.userSkillMap === userSkillMap.pk) : null;
        if (experiences) {
            experiences.forEach((experience) => {
                let skillHrValue = skillHrExForThisSkill ? skillHrExForThisSkill.find(obj => obj.experience === experience.pk) : null;
                if (skillHrValue && skillHrValue.hours && skillHrValue.hours > 0) {
                    endDates.push(new Date(experience.endDate));  // to show year skill was last used.

                    // Calculate skill metrics:
                    let totalYearsAtThisExperience = 0;
                    let totalDaysAtThisExperience = 0;

                    // Calculate Total Years, if it is less than 1 year, use decimals
                    // start date - end date, find the years and find the months. -> note currently using decimal of a year if < 1 year.
                    startDate = moment(experience.startDate);
                    endDate = moment(experience.endDate);

                    if (startDate && endDate) {
                        totalDaysAtThisExperience += Math.abs(startDate.diff(endDate, 'days'));
                        totalYearsAtThisExperience = totalDaysAtThisExperience/365;
                    } else if (startDate && experience.ifCurrent) {
                        totalDaysAtThisExperience += Math.abs(startDate.diff(moment(), 'days'));
                        totalYearsAtThisExperience = totalDaysAtThisExperience/365;
                    }

                    totalYearsAcrossExperiences += totalYearsAtThisExperience;

                    // calculate totalHoursUsedAtThisExperience then sum it up with the global total
                    const hoursPerWeekAtThisExperience = skillHrValue.hours;
                    const totalHoursUsedAtThisExperience = hoursPerWeekAtThisExperience * ANNUAL_WORK_WEEKS * totalYearsAtThisExperience;
                    totalHoursOfExperience += totalHoursUsedAtThisExperience;
                }
            });
        }

        if (totalHoursOfExperience >= 10000) {
            totalHoursOfExperience = truncate((totalHoursOfExperience/1000), 0).toString() + 'k';
        } else {
            if (totalHoursOfExperience > 0){
                totalHoursOfExperience = truncate(totalHoursOfExperience, 0).toString()
            } else {
                totalHoursOfExperience = '--';
            }
        }

        // Calculate mostRecentEndDate: find the most recent endDate for this skill
        let mostRecentEndDate = '--';
        if (endDates.length >= 1) {
            mostRecentEndDate = endDates.reduce(function (a, b) { return a > b ? a : b; });
            mostRecentEndDate = mostRecentEndDate.getFullYear();
        }

        if (totalYearsAcrossExperiences === 0) {
            totalYearsAcrossExperiences = '--';
        } else {
            if (totalYearsAcrossExperiences >= 1) {
                // Use full-number years if more than 1 years used for this skill
                totalYearsAcrossExperiences = truncate(totalYearsAcrossExperiences, 0)
            } else {
                // Use decimal years if less than one year
                totalYearsAcrossExperiences = truncate(totalYearsAcrossExperiences, 2)
            }
        }

        return (
            <Row key={ userSkillMap.pk } >
                <p className="col-sm-4">{ userSkillMap.skill.skillName }</p>
                <p className="col-sm-2">{ totalYearsAcrossExperiences }</p>
                <p className="col-sm-2">{ totalHoursOfExperience }</p>
                <p className="col-sm-2">{ mostRecentEndDate }</p>
                <div className="col-sm-2"><img src={ glyphImgArr[userSkillMap.skillLevel - 1] } width="12" height="12" alt="glyphImg"/></div>
            </Row>
        )
    });

    return (
        <div>
            <p><strong>{ skillGroup.skillGroupName }</strong></p>
            <div className="marginLeft15">
                { body }
            </div>
            <hr className="hrstyle" />
        </div>
    );
};

export default RenderSkillGroup
