import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { COMPANY_EMPLOYEE_SIZE_ENUM_MAP } from "../shared/utils";

// Component Specific Imports
import {
  getUsers,
  getCurrentUser,
  getProfile,
  getSchoolById,
  getJobFair,
  getEmployersAttendingJobFairs,
} from "../actions";
// way to get job fair and check if user belongs to the school that is attending job fair using backend token.

// Bootstrap Resources
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
const contentWrapper = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
};

const logoStyle = {
  width: "10%",
};

const eachRowFlex = {
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
};

const eachCompanyBox = {
  width: "25%",
  padding: "10px",
};

const defaultSchoolLogo = require(`${"../../../assets/img/school_mascot/default.png"}`);
const defaultCompanyLogo = require(`${"../../../assets/img/company_logo/default.png"}`);

const ITEMS_PER_ROW = 4;

class JobFair extends Component {
  componentDidMount() {
    const jobFairId = this.props.match.params.pk; // id from url - react router parses this using the :pk variable
    this.props.getUsers(); // loaded by map state to props
    this.props.getProfile();
    this.props.getCurrentUser();
    this.props.getJobFair(jobFairId).then(response => this.props.getSchoolById(response.payload.data.organizer_id));
    this.props.getEmployersAttendingJobFairs(jobFairId);
  }

  addDefaultImage(e) {
    e.target.src = defaultCompanyLogo;
  }

  createCompaniesAttendingTable() {
    let companyListGrouped = [];
    if (!isEmpty(this.props?.employersJobFair)) {
      const flatCompanyList = this.props.employersJobFair.companiesAccepted;

      // group companies by ITEMS_PER_ROW - for each row for iterating below
      let i = 1;
      let eachRowGroup = [];

      for (let eachCompany of flatCompanyList) {
        eachRowGroup.push(eachCompany);
        if (i % ITEMS_PER_ROW === 0) {
          companyListGrouped.push(eachRowGroup);
          eachRowGroup = []; // reset for next row, this is a temp list
        }
        i++;
      }

      if (!(i % ITEMS_PER_ROW === 0)) {
        // modulus if statement not entered if less than max length for each row, add it here instead
        // need nested loop for below renderer to create row component  (ie <ITEMS_PER_ROW or 4 items, 4th item needs own row)
        // not enough for one row or more than one row and not enough for 2 rows
        companyListGrouped.push(eachRowGroup);
      }
    }

    if (!companyListGrouped?.length) {
      // Todo capture the case where this is not entered but each company group is not null.
      return <p>No companies in this job fair.</p>;
    } else {
      const tableBody = companyListGrouped.map((eachRow) => {
        const jobFairsInARow = eachRow.map((eachCompany) => {
          let companyLogo = defaultCompanyLogo; // Handles case when image is not in database and not in media folder.
          if (eachCompany.companyLogo) {
            companyLogo = `../../../media/companyLogo${
              eachCompany.pk
            }.${eachCompany.companyLogo.split(".").pop()}`;
          }

          return (
            <div key={eachCompany.pk} style={eachCompanyBox}>
              <div
                style={{
                  border: "1px solid #c3c6c3",
                  paddingBottom: "10px",
                  backgroundColor: "#ededed",
                }}
              >
                <h3>{eachCompany.companyName}</h3>
                <img
                  src={companyLogo}
                  alt="companyLogo"
                  onError={this.addDefaultImage}
                  className="companyImgStyle"
                />
                <div>
                  <p>
                    <strong>
                      {COMPANY_EMPLOYEE_SIZE_ENUM_MAP[eachCompany.companySize]}{" "}
                      Employees
                    </strong>
                  </p>
                </div>
                <Button
                  bsStyle="primary"
                  href={`/home/jobfaircompany/${eachCompany.pk}`}
                >
                  Meet Us!
                </Button>
              </div>
            </div>
          );
        });

        return <div style={eachRowFlex}>{jobFairsInARow}</div>;
      });

      return tableBody;
    }
  }

  main() {
    let logo = defaultSchoolLogo; // Handles case when image is not in database and not in media folder.

    // TODO: Fix bug where company and student view should see the school's logo (does not happen to employer side since students and schools can't see employer portal)
    // TODO: get school from the job fair API?
    if (this.props.school?.mascot) {
      logo = `../../../media/mascot${
        this.props.school.pk
      }.${this.props.school.mascot.split(".").pop()}`;
    }

    return (
      <div style={contentWrapper}>
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src={logo}
              alt="logo"
              onError={this.addDefaultImage}
              style={logoStyle}
              onClick={() => {
                this.toggleProfileImage();
              }}
            />
            <h3>{this.props.jobFair ? this.props.jobFair.name : ""}</h3>

            <hr />
            {this.createCompaniesAttendingTable()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.main();
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    jobFair: state.employerReducer.jobFair,
    employersJobFair: state.employerReducer.employersJobFair,
    school: state.employerReducer.school,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUsers,
      getProfile,
      getCurrentUser,
      getSchoolById,
      getJobFair,
      getEmployersAttendingJobFairs,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobFair);
