import React, { useState } from "react";
import { Button, Checkbox, Col, Modal, Row } from "react-bootstrap";
import { downloadResume } from "../../shared/utils";
import ExportResume from "../ExportResume";
import glyph5 from "../../../../assets/img/glyphimg5bars.png";


const DownloadResumeModal = ({ isOpen, toggleOpen, resumeId, currentUserId }) => {
  const [shouldExportSkillBars, setShouldExportSkillBars] = useState(true);

  return (
    <Modal show={isOpen} onHide={() => toggleOpen()}>
      <Modal.Header closeButton>
        <Modal.Title>Choose a Resume Format</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={3}>
            <Button
              bsStyle="success"
              bsSize="small"
              onClick={() => downloadResume(resumeId, currentUserId)}
            >
              Classic Resume
            </Button>
          </Col>

          <Col md={5}>
            <Button bsStyle="success" bsSize="small">
              <ExportResume
                resumeId={resumeId}
                buttonLabel={"FindFit Resume (Black & White)"}
                hasColor={false}
                shouldExportSkillBars={shouldExportSkillBars}
              />
            </Button>
          </Col>

          <Col md={4}>
            <Button bsStyle="success" bsSize="small">
              <ExportResume
                resumeId={resumeId}
                buttonLabel={"FindFit Resume (Color)"}
                hasColor={true}
                shouldExportSkillBars={shouldExportSkillBars}
              />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            md={1}
            style={{
              paddingRight: "0px",
              paddingTop: "10px",
              marginRight: "5px",
            }}
          >
            <img src={glyph5} width="12" height="12" alt="glyphImg" />
          </Col>
          <Col md={10} style={{ marginLeft: "0px", paddingLeft: "0px" }}>
            <Checkbox
              name="shouldExportSkillBars"
              checked={shouldExportSkillBars}
              onChange={() => setShouldExportSkillBars(!shouldExportSkillBars)}
            >
              Show Skill Bars in Exported Findfit Style Resume
            </Checkbox>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          bsStyle="default"
          style={{ marginLeft: "5px" }}
          onClick={() => toggleOpen()}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DownloadResumeModal;
