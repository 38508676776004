// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep, debounce } from "lodash";
import DatePicker from "react-datepicker";

// Libs
import moment from "moment";

// Shared Resources
import { is_form_valid } from "../../shared/utils";

// App Resources
import RenderInput from "../../shared/RenderInput";
import { Checkbox } from "react-bootstrap";
import { postExperience, getCurrentUser, postCompany } from "../../actions";
import "react-datepicker/dist/react-datepicker.css";

// Bootstrap Resources
import {
  Button,
  ControlLabel,
  Form,
  FormGroup,
  Panel,
  Row,
  Col,
  Glyphicon,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { getCompanies } from "../../actions";

class ExperienceNewPanel extends Component {
  // Constructor
  state = {
    editing: !this.props.hasExistingData, // initialized to open if there is no data, so user can go straight to editing.,
    // input placeholders are different from the default values used in dropdowns
    localExperience: {
      company: -1,
      companyName: null,
      user: null,
      resumeVersion: null,
      startDate: moment(Date.now()).format("YYYY-MM-DD"),
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
      position: "",
      summary: "",
      ifCurrentExperience: false,
    },
    startDate: Date.now(), // for datepicker use
    endDate: Date.now(), // for datepicker use
    summaryPlaceholder: "Summary",
  };

  async componentDidMount() {
    // set the user
    await this.props.getCurrentUser(); // todo; remove line: this has no effect, user already loaded
    this.setState({
      localExperience: {
        ...this.state.localExperience,
        resumeVersion: this.props.resume.pk,
        user: this.props.currentUser.id,
      },
    });
  }

  resetForms = () => {
    /* Resets state back to initial */
    this.setState({
      localExperience: {
        company: -1,
        companyName: null,
        user: this.state.localExperience.user,
        resumeVersion: this.state.localExperience.resumeVersion,
        startDate: moment(Date.now()).format("YYYY-MM-DD"),
        endDate: moment(Date.now()).format("YYYY-MM-DD"),
        position: "",
        summary: "",
        ifCurrentExperience: false,
      },
      startDate: Date.now(), // for datepicker use
      endDate: Date.now(), // for datepicker use
    });
  };

  toggleEdit = () => {
    this.setState({ editing: !this.state.editing });
    this.resetForms();
  };

  updateField = (field, value) => {
    const modifiedExperience = cloneDeep(this.state.localExperience);

    if (field === "company" && Array.isArray(value) && value[0].customOption) {
      const companyName = value[0].label;
      if (companyName.length) {
        this.props
          .postCompany({
            companyName: companyName,
          })
          .then((res) => {
            value = res.payload.data.pk;
            modifiedExperience[field] = value;
            this.setState({ localExperience: modifiedExperience });
            return;
          });
      }
    }

    modifiedExperience[field] = value;
    this.setState({ localExperience: modifiedExperience });
  };

  onSubmit = () => {
    // validate forms.
    const { company, startDate, endDate, ifCurrentExperience, position } =
      this.state.localExperience;

    if (
      !is_form_valid([
        company,
        startDate,
        endDate || ifCurrentExperience,
        position,
      ])
    ) {
      alert("Errors in form - please correct before saving.");
      // don't save to db and don't toggle/erase data.
    } else if (company === -1) {
      // -1 is the default null placeholder.
      alert(
        "You must select a company. If this a new company, 1. Please type in your company name and 2. Click 'Add new:'",
      );
    } else if (endDate && endDate < startDate) {
      alert("End Date must be after your Start Date");
    } else {
      this.props.postExperience(this.state.localExperience);
      this.toggleEdit();
    }
  };

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  handleToggleCheckbox = () => {
    // similar to handleChange
    this.updateField(
      event.target.name,
      !this.state.localExperience.ifCurrentExperience,
    );
  };

  handleStartDateChange = (date) => {
    // Can move to a shared component.
    this.setState({
      startDate: date,
    }); // save  this to local datepicker component state.
    const startDateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("startDate", startDateFormatted);
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    }); // save  this to local datepicker component state.
    const endDateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("endDate", endDateFormatted);
  };

  async companyFilter(input) {
    const debouncedFilter = debounce(() => {
      console.log("====>", input);
    }, 500);
    await this.props.getCompanies(input, null);  // take an option to filter by name
    debouncedFilter();
  }

  renderForm = () => {
    const { summary, position, ifCurrentExperience } =
      this.state.localExperience;

    // const options = [{ pk: 1, name: 'A' }, { pk: 3, name: 'B' }];
    // https://github.com/ericgio/react-bootstrap-typeahead/issues/353
    // https://github.com/ericgio/react-bootstrap-typeahead/blob/master/docs/Usage.md
    // note onchange is triggered only when clicking the menu item, not by just typing and triggering
    // a rendered dropdown.
    // not case sensitive

    return (
      <Panel.Body>
        <Form>
          <Row>
            <Col md={3}>
              <div style={{ paddingBottom: "5px" }}>
                <strong>Company*</strong>
              </div>
              <Typeahead
                allowNew
                labelKey={(option) => `${option.companyName}`}
                options={this.props.companies}
                placeholder="Start typing a company name"
                newSelectionPrefix="Add new: "
                style={{ marginBottom: "10px" }}
                onInputChange={(input) => this.companyFilter(input)}
                onChange={(selected) => {
                  if (selected.length > 0) {
                    if (selected[0].pk) {
                      this.updateField("company", selected[0].pk);
                    } else {
                      this.updateField("company", selected);
                    }
                  }
                }}
              />
            </Col>
            <Col md={3}>
              <RenderInput
                label="Position*"
                name="position"
                type="input"
                placeholder="Position"
                initializedValue={position}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row>
            {/* Form fields for city role and company
                        Company will come from a company list from the
                        company table.

                        titles and roles, does LI/Angelist give a dropdown
                        s/t it will be easier to query

                        Location, that will be a dropdown so we can map that
                        into the google maps API.

                        // need to populate those  values into state
                        // populate those dbs and then load it into the form in
                        // this component.
                        */}
            <Col md={3}>
              <FormGroup controlId="startDate">
                <ControlLabel>Start Date*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  className="form-control"
                  maxDate={moment().toDate()}
                />
              </FormGroup>
            </Col>
            {ifCurrentExperience ? null : (
              <Col md={3}>
                <FormGroup controlId="endDate">
                  <ControlLabel>End Date*</ControlLabel>
                  <br />
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    className="form-control"
                    maxDate={moment().toDate()}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={3}>
              <Checkbox
                name="ifCurrentExperience"
                checked={ifCurrentExperience}
                // ensure checked state reflects current state of ifCurrentExperience correctly
                onChange={this.handleToggleCheckbox}
              >
                I currently work here
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <RenderInput
                label="Summary"
                name="summary"
                type="textarea"
                placeholder={this.state.summaryPlaceholder}
                initializedValue={summary}
                onChange={this.handleChange}
                rows={3}
              />
            </Col>
          </Row>
          <div className="modal-space-between-confirm-buttons">
            <Button bsStyle="primary" onClick={() => this.onSubmit()}>
              Save
            </Button>
            <Button
              bsStyle="warning"
              style={{ marginLeft: "5px" }}
              onClick={() => this.toggleEdit()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Panel.Body>
    );
  };

  render() {
    return (
      <Panel style={{ marginBottom: "15px" }}>
        <Panel.Heading onClick={() => this.toggleEdit()}>
          <Row>
            <Col md={11}>
              <Glyphicon glyph="plus" />
              <p>
                <strong> Add a new Experience</strong>
              </p>
            </Col>
          </Row>
        </Panel.Heading>
        {this.state.editing ? this.renderForm() : null}
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: state.resumeReducer.companies,
    resume: state.resumeReducer.resume,
    currentUser: state.sharedReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postExperience,
      getCurrentUser,
      getCompanies,
      postCompany,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceNewPanel);
