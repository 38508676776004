import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Component Specific Imports
import {
  getCurrentUser,
  getProfile,
  getSchoolByInstitutionAdminUser,
  getSchoolById,
} from "../actions";

import CandidateJobFairTable from "./CandidateJobFairTable";
import axios from "axios";

const contentWrapper = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
};

const logoStyle = {
  width: "10%",
};

const defaultLogo = require(`${"../../../assets/img/school_mascot/default.png"}`);
// TODO actually create a default picture here

class CandidateJobFairBrowseList extends Component {
  state = {
    showUpload: false,
    images: [],
    createNewJobFairModalOpen: false,
    startDate: Date.now(), // local state independent of localNewJobFair's nested date field only used for the calendar
    endDate: Date.now(), // local state independent of localNewJobFair's nested date field only used for the calendar
  };

  getSchoolForCandidate() {
    const url = `employer/student_school_map`;
    const token = localStorage.getItem('token');
    return axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
  }

  async componentDidMount() {
    this.props.getProfile();
    this.props.getCurrentUser();
    // this.getSchoolByInstitutionAdminUser();  // For admin
    let result = await this.getSchoolForCandidate();
    result = result?.data?.results;
    const schoolId = result.length > 0 ? result[0].school : null;
    this.props.getSchoolById(schoolId);
  }

  main() {
    let logo = defaultLogo; // Handles case when image is not in database and not in media folder.
    if (this.props?.school?.mascot) {
      logo = `../../../media/mascot${
        this.props.school.pk
      }.${this.props.school.mascot.split(".").pop()}`;
    }

    return (
      <div style={contentWrapper}>
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src={logo}
              alt="logo"
              onError={this.addDefaultImage}
              style={logoStyle}
            />

            <h3>
              Upcoming Job Fairs at {this.props.school.schoolName}
            </h3>
            {/* Fetch the school this admin user is bound to. admin user signs up with email the school tagged them in
            thus that email is their login id.*/}

            <hr />
            <CandidateJobFairTable school={this.props.school} history={this.props.history} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.main();
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    school: state.employerReducer.school,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProfile,
      getCurrentUser,
      getSchoolByInstitutionAdminUser,
      getSchoolById,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJobFairBrowseList);
