
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Component Specific Imports
import {
    getCurrentUser,
    updateUserField,
    patchUser
} from '../actions';

// Bootstrap Resources
import { Button, Row, Col, Panel, Form } from 'react-bootstrap';
import RenderInput from '../shared/RenderInput';


class GeneralSettings extends Component {

    componentDidMount () {
        this.props.getCurrentUser();
    }

    async onSubmit () {
        let userProp = {
            first_name: '',
            last_name: ''
        };

        let currentUser = null;
        if (
            this.props.currentUser
            && this.props.currentUser
            && Object.keys(this.props.currentUser).length > 0
        ) {
            currentUser = this.props.currentUser;
            userProp = currentUser;
        }

        if (currentUser) {
            const {
                first_name,
                last_name
            } = userProp;

            await this.props.patchUser({
                first_name,
                last_name
            }, currentUser.id);
            await this.props.getCurrentUser();
            // Sync up front-end state with db after save.
            alert("Successfully Saved!");
        }
    };

    updateField = (field, value) => {
        // have this update redux store based on field in state via action directly
        this.props.updateUserField(field, value);
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    generalSettingsForm = () => {
        let userProp = {
            first_name: '',
            last_name: ''
        };

        if (
            this.props.currentUser
            && this.props.currentUser
            && Object.keys(this.props.currentUser).length > 0
        ) {
            userProp = this.props.currentUser;
        } else {
            console.log('error! Log me');
        }

        const {
            first_name,
            last_name,
        } = userProp;

        return (
            <Form>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='First Name'
                          name='first_name'
                          placeholder='First Name'
                          initializedValue={first_name}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderInput
                          label='Last Name'
                          name='last_name'
                          placeholder='Last Name'
                          initializedValue={last_name}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                {/*<Row>*/}
                {/*    <Col md={4}>*/}
                {/*        <RenderInput*/}
                {/*          label='Password'*/}
                {/*          name='password'*/}
                {/*          placeholder='Password'*/}
                {/*          initializedValue={state}*/}
                {/*          type="input"*/}
                {/*          onChange={this.handleChange}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*    <Col md={4}>*/}
                {/*        <RenderInput*/}
                {/*          label='Confirm Password'*/}
                {/*          name='confirmPassword'*/}
                {/*          placeholder='Confirm Password'*/}
                {/*          initializedValue={state}*/}
                {/*          type="input"*/}
                {/*          onChange={this.handleChange}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Form>
        );
    };

    render() {
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>General Settings</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    { this.generalSettingsForm() }
                </Panel.Body>
                <Panel.Footer>
                    <Button
                      onClick={() => this.onSubmit()}
                      style={{ marginLeft: '5px' }}
                    >
                        Save
                    </Button>
                </Panel.Footer>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getCurrentUser,
        updateUserField,
        patchUser
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
