
import * as events from '../actions/events';


const initialState = {
    user: {},
    currentUser: {},
    profile: {},
    auth: {},
    sign_up_error: null,
    careerTracks: [],
    skillRecommendations: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case events.GET_USERS:
            return {
                ...state,
                user: action.payload
            };
        case events.GET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload && action.payload.data ?
                    action.payload.data :
                    {}
            };
        case events.UPDATE_USER_FIELD: {
            const new_result = state.currentUser;
            new_result[action.payload.field] = action.payload.value;

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    data: {
                        ...state.currentUser,
                        new_result
                    }
                }
            };
        }
        case events.GET_PROFILE:
            return {
                ...state,
                profile: action?.payload?.data?.results ? action.payload.data.results[0] : {}

            };
        case events.UPDATE_PROFILE_FIELD: {
            const update_profile = state.profile;  // create a copy
            update_profile[action.payload.field] = action.payload.value;  // update the field under this profile

            return {
                ...state,
                profile: update_profile
            };
        }
        case events.HANDLE_LOGIN: {
            const { access, refresh } = action.payload.data;
            localStorage.setItem('token', access);

            return {
                ...state,
                auth: {
                    access,
                    refresh
                }
            };
        }
        case events.HANDLE_SIGNUP: {
            const { access, refresh } = action.payload.data.tokens;
            localStorage.setItem('token', access);
            // Debugging: If logged in via session auth for drf admin page, this will crap out
            return {
                ...state,
                auth: {
                    access,
                    refresh
                }
            };
        }
        case events.SIGN_UP_ERROR: {
            return {
                ...state,
                sign_up_error: action.payload
            };
        }
        case events.GET_CAREER_TRACKS: {
           return {
                ...state,
                careerTracks: action?.payload?.data?.results ? action.payload.data.results : []
            };
        }
        case events.GET_SKILL_RECOMMENDATIONS: {
            return {
                ...state,
                skillRecommendations: action?.payload?.data ? action.payload.data : {}
            }
        }
        default:
            return state;
    }
}
