import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, ControlLabel, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import RenderInput from "../shared/RenderInput";
import RenderSelect from "../shared/RenderSelect";
// import PhoneInput from "react-phone-input-2";
import { convertStateProvincesToFormOptionList, companySizeSelection } from "../shared/utils";
import { bindActionCreators } from "redux";
import { patchCompany } from "../actions";
import { handleChange } from "../shared/utils";

const logoStyle = {
  width: "10%",
};

class EmployerSettings extends Component {
  state = {
    showUpload: false,
    images: [],

    // Form fields state -> don't use non nullish empty values or initialize form value will be ignored for these in value field
    companyName: '',
    city: '',
    about: '',
    companyWebsite: '',
    state: null,
    country: null,
    companySize: null,
  };

  handleBlur = () => {
    const value = {};
    const fieldName = event.target.name;
    value[fieldName] = event.target.value;
    this.props.patchCompany(this.props.employer_of_recruiter.pk, value);
    // TODO: to be hot reloaded, the name field should update the compaany redux state pocket
    
  };

  settingsForm = () => {
    return (
      <Form>
        <Row>
          <Col md={4}>
            <RenderInput
              label="Company Name"
              name="companyName"
              placeholder="Company Name"
              value={this.state.companyName}
              initializedValue={this.props.employer_of_recruiter?.companyName}
              type="input"
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Col>
          <Col md={4}>
            <RenderSelect
              label="Company Size"
              name="companySize"
              type="select"
              value={this.state.companySize}
              initializedValue={this.props.employer_of_recruiter?.companySize}
              onChange={handleChange}
              options={companySizeSelection}
              onBlur={this.handleBlur}
            />
          </Col>
          <Col md={4}>
            <RenderInput
              label="Company Website"
              name="companyWebsite"
              placeholder="Company Website"
              value={this.state.companyWebsite}
              initializedValue={this.props.employer_of_recruiter?.companyWebsite}
              type="input"
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <RenderInput
              label="City"
              name="city"
              placeholder="City"
              value={this.state.city}
              initializedValue={this.props.employer_of_recruiter?.city}
              type="input"
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Col>
          <Col md={4}>
            <RenderSelect
              label="State"
              name="state"
              type="select"
              value={this.state.state}
              initializedValue={this.props.employer_of_recruiter?.state}
              onChange={handleChange}
              options={convertStateProvincesToFormOptionList()}
              onBlur={this.handleBlur}
            />
          </Col>
          <Col md={4}>
            <RenderSelect
              label="Country"
              name="country"
              type="select"
              value={this.state.country}
              initializedValue={this.props.employer_of_recruiter?.country}
              onChange={handleChange}
              options={[{ id: "USA", name: "United States" }]}
              onBlur={this.handleBlur}
            />
          </Col>
        </Row>
        {/*<Row>*/}
        {/*  <Col md={4}>*/}
        {/*    <ControlLabel>Phone Number</ControlLabel>*/}
        {/*    /!* TODO need a way to trigger onBlur on this nonstandard form field *!/*/}
        {/*    <PhoneInput*/}
        {/*      placeholder="Phone Number"*/}
        {/*      country={"us"}*/}
        {/*      value={this.props.employer_of_recruiter?.phoneNumber}*/}
        {/*      onChange={handleChange}*/}
        {/*      inputProps={{*/}
        {/*        name: "phoneNumber",*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row>
          <Col md={12}>
            <br />
            <RenderInput
              label="About your company"
              name="about"
              placeholder="Write a few sentences to share why candidates should work here."
              value={this.state.about}
              initializedValue={this.props.employer_of_recruiter?.about}
              rows={10}
              type="textarea"
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Col>
        </Row>
      </Form>
    );
  };

  toggleProfileImage = () => {
    this.setState({ showUpload: !this.state.showUpload });
  };

  showUploadModal() {
    return (
      <Modal
        show={this.state.showUpload}
        bsSize="large"
        onHide={() => this.toggleProfileImage()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose a Company Logo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <input
            type="file"
            id="single"
            onChange={(e) => {
              const file = e.target.files[0];
              const formData = new FormData();
              formData.append("image", file);

              const url = `/image_upload?user_type=EMPLOYER`;
              const token = localStorage.getItem("token");

              axios
                .put(url, formData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/x-www-form-urlencoded",
                  },
                })
                .then(() => {
                  caches
                    .keys()
                    .then((keyList) =>
                      Promise.all(keyList.map((key) => caches.delete(key)))
                    );
                  window.location.reload();
                  caches
                    .keys()
                    .then((keyList) =>
                      Promise.all(keyList.map((key) => caches.delete(key)))
                    );
                });
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    if (this.props?.employer_of_recruiter) {
      return (
          <div style={{marginTop: "20px"}}>
            <div>
              <p>
                <strong>Update Company Logo</strong>
              </p>
            </div>
            <img
                src={this.props.logoPath}
                alt="logo"
                onError={this.addDefaultImage}
                style={logoStyle}
                onClick={() => {
                  this.toggleProfileImage();
                }}
            />
            {this.showUploadModal()}
            {this.settingsForm()}
          </div>
      );
    }
    return <p>No Company found, please contact the Findfit team.</p>
  }
}

function mapStateToProps(state) {
  return {
    users: state.sharedReducer.users,
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    employer_of_recruiter: state.employerReducer.employer_of_recruiter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      patchCompany,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerSettings);
