
// Shared Reducer Imports
export const GET_USERS = 'GET_USERS';
export const PATCH_USER = 'PATCH_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD';
export const GET_PROFILE = 'GET_PROFILE';
export const PATCH_PROFILE = 'PATCH_PROFILE';
export const UPDATE_PROFILE_FIELD = 'UPDATE_PROFILE_FIELD';
export const GET_CAREER_TRACKS = 'GET_CAREER_TRACKS';
export const GET_SKILL_RECOMMENDATIONS = 'GET_SKILL_RECOMMENDATIONS'

// Resume Reducer Imports
export const GET_ACCESS_TO_RESUME = 'GET_ACCESS_TO_RESUME';
export const GET_RESUMES = 'GET_RESUMES';
export const GET_RESUME = 'GET_RESUME';
export const RESUME_SELECTED = 'RESUME_SELECTED';
export const CREATE_RESUME = 'CREATE_RESUME';
export const EDIT_RESUME = 'EDIT_RESUME';
export const DELETE_RESUME = 'DELETE_RESUME';
export const GET_SUMMARY = 'GET_SUMMARY';
export const SUMMARY_UPDATE_SUCCESS = 'SUMMARY_UPDATE_SUCCESS';
export const GET_COVER_LETTER = 'GET_COVER_LETTER';
export const COVER_LETTER_UPDATE_SUCCESS = 'COVER_LETTER_UPDATE_SUCCESS';
export const GET_HIGHLIGHTS = 'GET_HIGHLIGHTS';
export const HIGHLIGHT_CREATE_SUCCESS = 'HIGHLIGHT_CREATE_SUCCESS';
export const HIGHLIGHT_UPDATE_SUCCESS = 'HIGHLIGHT_UPDATE_SUCCESS';
export const DELETE_HIGHLIGHT = 'DELETE_HIGHLIGHT';
export const GET_EDUCATIONS = 'GET_EDUCATIONS';
export const GET_SCHOOLS = 'GET_SCHOOLS';
export const SCHOOL_CREATE_SUCCESS = 'SCHOOL_CREATE_SUCCESS';
export const EDUCATION_CREATE_SUCCESS = 'EDUCATION_CREATE_SUCCESS';
export const EDUCATION_UPDATE_SUCCESS = 'EDUCATION_UPDATE_SUCCESS';
export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const GET_RAW_LANGUAGES = 'GET_RAW_LANGUAGES';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const LANGUAGE_CREATE_SUCCESS = 'LANGUAGE_CREATE_SUCCESS';
export const LANGUAGE_UPDATE_SUCCESS = 'LANGUAGE_UPDATE_SUCCESS';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const GET_REFERENCES = 'GET_REFERENCES';
export const DELETE_REFERENCE = 'DELETE_REFERENCE';
export const REFERENCE_UPDATE_SUCCESS = 'REFERENCE_UPDATE_SUCCESS';
export const REFERENCE_CREATE_SUCCESS = 'REFERENCE_CREATE_SUCCESS';
export const GET_EXPERIENCES = 'GET_EXPERIENCES';
export const EXPERIENCE_CREATE_SUCCESS = 'EXPERIENCE_CREATE_SUCCESS';
export const EXPERIENCE_UPDATE_SUCCESS = 'EXPERIENCE_UPDATE_SUCCESS';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const GET_SKILL_GROUPS = 'GET_SKILL_GROUPS';
export const SKILL_GROUP_CREATE_SUCCESS = 'SKILL_GROUP_CREATE_SUCCESS';
export const SKILL_GROUP_UPDATE_SUCCESS = 'SKILL_GROUP_UPDATE_SUCCESS';
export const DELETE_SKILL_GROUP = 'DELETE_SKILL_GROUP';
export const GET_USER_SKILL_MAP = 'GET_USER_SKILL_MAP';
export const USER_SKILL_MAP_CREATE_SUCCESS = 'USER_SKILL_MAP_CREATE_SUCCESS';
export const USER_SKILL_MAP_UPDATE_SUCCESS = 'USER_SKILL_MAP_UPDATE_SUCCESS';
export const GET_SKILL_HOURS = 'GET_SKILL_HOURS';
export const PUT_SKILL_HOURS = 'PUT_SKILL_HOURS';
export const POST_SKILL_HOURS = 'POST_SKILL_HOURS';
export const GET_COMPANIES = 'GET_COMPANIES';
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const HANDLE_LOGIN = 'HANDLE_LOGIN';
export const HANDLE_SIGNUP = 'HANDLE_SIGNUP';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

// Dialog Constants
export const TOGGLE_SKILL_MODAL = 'TOGGLE_SKILL_MODAL';
export const TOGGLE_SUMMARY_MODAL = 'TOGGLE_SUMMARY_MODAL';
export const TOGGLE_HIGHLIGHTS_MODAL = 'TOGGLE_HIGHLIGHTS_MODAL';
export const TOGGLE_LANGUAGE_MODAL = 'TOGGLE_LANGUAGE_MODAL';
export const TOGGLE_EDUCATION_MODAL = 'TOGGLE_EDUCATION_MODAL';
export const TOGGLE_REFERENCES_MODAL = 'TOGGLE_REFERENCES_MODAL';
export const TOGGLE_EXPERIENCE_MODAL = 'TOGGLE_EXPERIENCE_MODAL';
export const TOGGLE_DELETE_RESUME_ITEM_MODAL = 'TOGGLE_DELETE_RESUME_ITEM_MODAL';

// Employer Reducer Imports
export const GET_JOB_OPENINGS = 'GET_JOB_OPENINGS';
export const GET_JOB_OPENING = 'GET_JOB_OPENING';
export const CREATE_JOB_OPENING = 'CREATE_JOB_OPENING';
export const JOB_OPENING_UPDATE_SUCCESS = 'JOB_OPENING_UPDATE_SUCCESS';
export const APPLY_TO_JOB_SUCCESS = 'APPLY_TO_JOB_SUCCESS';
export const UN_APPLY_TO_JOB_SUCCESS = 'UN_APPLY_TO_JOB_SUCCESS';
export const DELETE_JOB_OPENING = 'DELETE_JOB_OPENING';
export const GET_SKILLS = 'GET_SKILLS';
export const DELETE_USER_SKILL_MAP = 'DELETE_USER_SKILL_MAP';
export const SKILL_CREATE_SUCCESS = 'SKILL_CREATE_SUCCESS';
export const GET_JOB_OPENING_SKILL_MAP = 'GET_JOB_OPENING_SKILL_MAP';
export const GET_JOB_OPENING_APPLICANT_MAP = 'GET_JOB_OPENING_APPLICANT_MAP';
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_JOB_FAIRS = 'GET_JOB_FAIRS';

// TODO: Below need reducer instead of hard refreshing page to reflect updates
export const CREATE_JOB_FAIR = 'CREATE_JOB_FAIR';
export const EDIT_JOB_FAIR = 'EDIT_JOB_FAIR';
export const GET_EMPLOYERS_ATTENDING_JOB_FAIRS = 'GET_EMPLOYERS_ATTENDING_JOB_FAIRS';
export const DELETE_EMPLOYER_JOB_FAIR_MAP = 'DELETE_EMPLOYER_JOB_FAIR_MAP';
export const EMPLOYER_JOB_FAIR_MAP_ACCEPT_SUCCESS = 'EMPLOYER_JOB_FAIR_MAP_ACCEPT_SUCCESS';
export const GET_JOB_FAIR = 'GET_JOB_FAIR';
export const GET_ENROLLED_JOB_FAIRS = 'GET_ENROLLED_JOB_FAIRS';
export const GET_COMPANY = 'GET_COMPANY';
export const PATCH_COMPANY = 'PATCH_COMPANY';
export const PATCH_SCHOOL = 'PATCH_SCHOOL';
export const APPLY_TO_COMPANY = 'APPLY_TO_COMPANY';
export const GET_COMPANY_APPLICANT_MAP = 'GET_COMPANY_APPLICANT_MAP';
export const DELETE_COMPANY_APPLICANT_MAP = 'DELETE_COMPANY_APPLICANT_MAP';
export const GET_EMPLOYER_FOR_EMPLOYEE = 'GET_EMPLOYER_FOR_EMPLOYEE';
export const DELETE_EMPLOYER_JOB_FAIR_MAP_WITH_JOB_FAIR_ID = 'DELETE_EMPLOYER_JOB_FAIR_MAP_WITH_JOB_FAIR_ID';
export const EMPLOYER_APPLY_TO_JOB_FAIR = 'EMPLOYER_APPLY_TO_JOB_FAIR';
// export const GET_CANDIDATE_SCHOOL_MAP = 'GET_CANDIDATE_SCHOOL_MAP';
