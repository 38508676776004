
import React, { Component } from 'react';


class ActivationEmailConfirm extends Component {

    render() {
        return (
            <div style={{ marginTop: "40px" }}>
                <p><strong>Please check your email for your verification link</strong></p>
            </div>
        );
    }
}

export default ActivationEmailConfirm;
