
import React  from 'react';
import { Link } from 'react-router-dom';


function JobOpeningList({ jobOpenings }) {
    return jobOpenings.map((jobOpening) => {
        return (
            <div className="row" style={{ marginBottom: '20px' }}>
                <Link key={jobOpening.job_opening_pk} to={ `/home/employer/jobopening/${jobOpening.job_opening_pk}` }>
                    <div
                       className="col-sm-10 col-sm-offset-1 jobOpeningListBox"
                       style={{ minHeight: '100px', paddingTop: '10px', paddingBottom: '10px' }}
                       key={jobOpening.job_opening_pk}
                    >
                        <h4 style={{ color: '#2b71b1' }}>{ jobOpening.companyName }</h4>
                        <p>{ jobOpening.role }</p>
                    </div>
                </Link>
            </div>
        );
    });
}

export default JobOpeningList