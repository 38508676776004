
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import DatePicker from 'react-datepicker';

// Libs
import moment from 'moment';

// App Resources
import RenderInput from '../../shared/RenderInput';
import {
    getCurrentUser,
    getExperiences,
    postHighlight
} from '../../actions';

// Bootstrap Resources
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Panel, Row } from "react-bootstrap";


const RenderExperienceSelect = ({ label, type, experience, experiences, name, onChange }) => {
    // TODO: Add error checking

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={experience ? experience : -1}
              onChange={onChange}
            >
                <option value={-1} key={-1}>Select where you and they worked</option>
                {
                    experiences.data.results.map(experienceObj => (
                        <option value={experienceObj.pk} key={experienceObj.pk}>{ experienceObj.companyName }</option>
                ))}
            </FormControl>
        </FormGroup>
    );
};


class HighlightsNewPanel extends Component {
    state = {
        editing: !this.props.hasExistingData,
        // input placeholders are different from the default values
        // used in dropdowns
        highlightDate: Date.now(),  // local state independent of highlightDate's nested field only used for the calendar
        localHighlight: {
            // TODO: Update below fields
            user: null,
            resumeVersion: this.props.resume ? this.props.resume.pk : 0,
            experience: 0,
            quantity: '',
            highlightDate: moment(Date.now()).format('YYYY-MM-DD'),
            fullDescription: ''
        }
    };

    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    // TODO: Repeated code between dialogs scream HOC
    updateHighlightField = (field, value) => {
        const modifiedHighlight = cloneDeep(this.state.localHighlight);
        modifiedHighlight[field] = value;
        this.setState({ localHighlight: modifiedHighlight })
    };

    onSubmit = () => {
        this.props.postHighlight(this.state.localHighlight);
        this.toggleEdit();
    };

    handleChange = () => {
        this.updateHighlightField(event.target.name, event.target.value);
    };

    handleHighlightDateChange = (date) => {
        this.setState({
            highlightDate: date
        }); // save  this to local datepicker component state.
        const highlightDateFormatted = moment(date).format('YYYY-MM-DD'); // formatted for sending date to post request
        this.updateHighlightField('highlightDate', highlightDateFormatted);
    };

    renderForm = () => {
        const {
            quantity,
            fullDescription,
            experience,
        } = this.state.localHighlight;

        return (
            <Panel.Body>
                <Form>
                    <Row>
                        <Col md={4}>
                            <RenderInput
                              label='Quantitative Impact of your Highlight*'
                              name='quantity'
                              placeholder='30% Sales Increase'
                              initializedValue={quantity}
                              type="input"
                              onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={3}>
                            <FormGroup controlId='highlightDate'>
                                <ControlLabel>Date*</ControlLabel>
                                <br/>
                                <DatePicker
                                  selected={this.state.highlightDate} // use state for datepicker not in localHighlight's state to be send to server
                                  onChange={this.handleHighlightDateChange}
                                  className="form-control"
                                  maxDate={moment().toDate()}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <RenderExperienceSelect
                              label='Company*'
                              name='experience'
                              type='select'
                              experience={experience}
                              experiences={this.props.experiences}
                              onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RenderInput
                              label='Full Description (Seen in the detailed view)'
                              name='fullDescription'
                              placeholder='Captured leads from high valued customers from website visits.'
                              initializedValue={fullDescription}
                              type="textarea"
                              onChange={this.handleChange}
                              rows={10}
                            />
                        </Col>
                    </Row>
                    <div className="modal-space-between-confirm-buttons">
                        <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                        <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.toggleEdit()}>Cancel</Button>
                    </div>
                </Form>
            </Panel.Body>
        );
    };


    render () {
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading onClick={() => this.toggleEdit()}>
                    <Row>
                        <Col md={11}>
                            <Glyphicon glyph="plus" />
                            <p><strong>{' '}Add a New Highlight</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                { this.state.editing ? this.renderForm() : null }
            </Panel>
        )
    }
}


function mapStateToProps(state) {
    return {
        experiences: state.resumeReducer.experiences,
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postHighlight,
            getExperiences,
            getCurrentUser
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsNewPanel);
