
import React, { Component } from 'react';
import ProfileSettings from './ProfileSettings';
import GeneralSettings from './GeneralSettings';
import { Row } from 'react-bootstrap';


const lhStyle = {
    backgroundColor: '#2d2d2d',
    color: 'white',
    minHeight: 'calc(100vh)',
    alignContent: 'center',
    textAlign: 'center',
};


const rhStyle = {
    backgroundColor: '#e8e8e8',
    minHeight: 'calc(100vh)',
    paddingTop: '20px',
};

const contentStyle = {
    minHeight: '100%',
};


class Settings extends Component {

    state = {
        page: 'profile'
    };

    selectPage = (page) => {
        this.setState({ page })
    };

    left () {
        return (
            <Row style={lhStyle}>
                <div className="sidebar">
                    <ul>
                        <li
                            onClick={() => this.selectPage('general')}
                            style={{ backgroundColor: this.state.page === 'general' ? '#7f838a': null }}
                        >
                            <h5>General Settings</h5>
                        </li>
                        <li
                            onClick={() => this.selectPage('profile')}
                            style={{ backgroundColor: this.state.page === 'profile' ? '#7f838a': null }}
                        >
                            <h5>Profile Settings</h5>
                        </li>
                    </ul>
                </div>
            </Row>
        );
    }

    page () {
        if (this.state.page === 'profile') {
            return (
                <ProfileSettings/>
            );
        } else if (this.state.page === 'general') {
            return (
                <GeneralSettings/>
            )
        }
    }

    right () {
        return (
            <Row style={rhStyle}>
                <div
                  className='col-sm-10 col-sm-offset-1'
                  style={{ marginTop: "35px" }}
                >
                    { this.page() }
                </div>
            </Row>
        );
    }

    content () {
        return (
            <Row style={contentStyle}>
                <div className="col-sm-2">
                    { this.left() }
                </div>
                <div className="col-sm-10">
                    { this.right() }
                </div>
            </Row>
        );
    }

    render() {
        return (
            this.content()
        );
    }
}


export default Settings;
