import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import JobOpeningList from "./JobOpeningList";
import { COMPANY_EMPLOYEE_SIZE_ENUM_MAP } from "../shared/utils";

// Component Specific Imports
import {
  getUsers,
  getCurrentUser,
  getProfile,
  getCompany,
  getJobOpenings,
  applyToCompany,
  getResumes,
  getCompanyApplicantMap,
} from "../actions";
// way to get job fair and check if user belongs to the school that is attending job fair using backend token.

// Bootstrap Resources
import { Button, Tabs, Tab, Modal, Form, Row, Col } from "react-bootstrap";
import { cloneDeep } from "lodash";
import RenderSelect from "../shared/RenderSelect";

const contentWrapper = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
};

const logoStyle = {
  width: "10%",
};

const defaultCompanyLogo = require(`${"../../../assets/img/company_logo/default.png"}`);

class JobFairCompany extends Component {
  state = {
    resume_id: null, // used for apply flow only.
    applyOpen: false,
  };

  componentDidMount() {
    const companyId = this.props.match.params.pk; // id from url

    this.props.getUsers(); // loaded by map state to props
    this.props.getProfile();
    this.props.getCurrentUser();
    this.props.getCompany(companyId);
    this.props.getJobOpenings(companyId);
    this.props.getResumes();
    this.props.getCompanyApplicantMap(companyId);
  }

  addDefaultImage(e) {
    e.target.src = defaultCompanyLogo;
  }

  toggleApplyToCompanyModal = () => {
    this.setState({
      applyOpen: !this.state.applyOpen,
    });
  };

  async apply() {
    const { resume_id } = this.state;
    const { company, currentUser } = this.props;

    await this.props.applyToCompany({
      company_id: company.pk,
      user_id: currentUser.id,
      resume_id: resume_id,
    });
    this.toggleApplyToCompanyModal();
    alert("Successfully sent your resume!");
    window.location.reload();
  }

  updateApplyField = (field, value) => {
    // Update the resume selected for the job application.
    this.setState({ resume_id: parseInt(value) });
  };

  handleChange = () => {
    this.updateApplyField(event.target.name, event.target.value);
  };

  alreadyApplied = () => {
    // only used for jobseeker
    let hasAlreadyApplied = false;

    this.props.companyApplicantMap.forEach((each) => {
      if (each.company.pk === this.props.company.pk) {
        hasAlreadyApplied = true;
        return; // return early from the loop when a match found
      }
    });

    // above return doesn't return from the function, only from the loop, thus 2nd return here
    return hasAlreadyApplied;
  };

  applyModal() {
    const { resumes } = this.props;
    const resumeList = resumes && resumes.length ? cloneDeep(resumes) : [];
    resumeList.forEach((each) => {
      each.id = each.pk;
      each.name = each.resumeName;
    });

    resumeList.unshift({
      id: 0,
      name: "Pick a resume to apply with.",
    });

    return (
      <Modal
        show={this.state.applyOpen}
        bsSize="large"
        onHide={() => this.toggleApplyToCompanyModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col md={3}>
                <RenderSelect
                  label="Choose a resume to apply with"
                  name="resume_id"
                  type="select"
                  onChange={this.handleChange}
                  options={resumeList}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.apply()}>
            Apply
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleApplyToCompanyModal()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  main() {
    const { company, profile } = this.props;

    // this could be a helper function shared with job fair list of companies, taking in a company object as a param
    let logo = defaultCompanyLogo; // Handles case when image is not in database and not in media folder.
    if (company.companyLogo) {
      logo = `../../../media/companyLogo${company.pk}.${company.companyLogo
        .split(".")
        .pop()}`;
    }

    return (
      <div style={contentWrapper}>
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src={logo}
              alt="logo"
              onError={this.addDefaultImage}
              style={logoStyle}
            />
            <h3>{company ? company.companyName : ""}</h3>
            <a href={company.companyWebsite} target="_blank">
              <p>{company.companyWebsite}</p>
            </a>
            <br />
            <p>
              <strong>
                {COMPANY_EMPLOYEE_SIZE_ENUM_MAP[company.companySize]} Employees
              </strong>
            </p>

            <Tabs
              defaultActiveKey="jobFair"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="jobFair" title="Overview">
                {/* Add a component here and import it.*/}
                <div style={{ marginBottom: "20px" }}>
                  <Button
                    disabled={
                      this.alreadyApplied() ||
                      profile?.isRecruiter ||
                      profile?.isSchoolUser
                    }
                    bsStyle="primary"
                    style={{ textAlign: "center", marginTop: "10px" }}
                    onClick={() => this.toggleApplyToCompanyModal()}
                  >
                    {this.alreadyApplied()
                      ? "Resume Sent - You can still apply to specific jobs"
                      : `Drop off a Resume to ${company.companyName} - You can still apply to specific jobs.`}
                  </Button>
                </div>
                <p>{company.about}</p>
              </Tab>
              {(!profile?.isRecruiter && !profile?.isSchoolUser) && (
                <Tab eventKey="admin" title="Apply to Specific Jobs">
                  <div style={{ marginTop: "20px" }}>
                    <JobOpeningList jobOpenings={this.props.jobOpenings} />
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
        {this.applyModal()}
      </div>
    );
  }

  render() {
    return this.main();
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    company: state.resumeReducer.company,
    jobOpenings: state.employerReducer.jobOpenings,
    resumes: state.resumeReducer.resumes,
    companyApplicantMap: state.employerReducer.companyApplicantMap,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUsers,
      getProfile,
      getCurrentUser,
      getCompany,
      getJobOpenings,
      applyToCompany,
      getResumes,
      getCompanyApplicantMap,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobFairCompany);
