
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Component Specific Imports
import { getCurrentUser, getProfile, patchProfile } from '../actions';
import RenderInput from '../shared/RenderInput';
import RenderSelect from '../shared/RenderSelect';

// Bootstrap Resources
import { Button, Col, Panel, Row, Form } from 'react-bootstrap';


class EmployerAddProfile extends Component {

    state = {
        city: '',
        state: 'CA',
        country: 1,
        title: ''
    };

    componentDidMount () {
        this.props.getCurrentUser();
        this.props.getProfile();
    }

    async onSubmit () {
        const {
            city,
            state,
            country,
            title
        } = this.state;  // todo refactor this like candidate add profile

        const { profile } = this.props;

        if (profile) {
            await this.props.patchProfile({
                city,
                state,
                country,
                title
            }, profile.pk);
        }
        this.props.history.push('/home/dashboard');
    };

    updateField = (field, value) => {
        this.setState({ [field]: value })
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    profileForm = () => {
        const {
            city,
            state,
            country,
            title
        } = this.state;

        return (
            <Form>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='City'
                          name='city'
                          placeholder='City'
                          initializedValue={city}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderInput
                          label='State'
                          name='state'
                          placeholder='State'
                          initializedValue={state}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderSelect
                          label='Country'
                          name='country'
                          type='select'
                          initializedValue={country}
                          onChange={this.handleChange}
                          options={[
                              {id:1, name: 'USA'}
                          ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='Title'
                          name='title'
                          placeholder='Title'
                          initializedValue={title}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    content () {
        return (
            <div
              className='col-sm-8 col-sm-offset-2'
              style={{ marginTop: "35px" }}
            >
                <Panel style={{ marginBottom: '15px' }}>
                    <Panel.Heading>
                        <Row>
                            <Col md={11}>
                                <p><strong>Basic Information</strong></p>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body>
                        { this.profileForm() }
                    </Panel.Body>
                    <Panel.Footer>
                        <Button
                          bsStyle="primary"
                          onClick={() => this.onSubmit()}
                          style={{ marginLeft: '5px' }}
                        >
                            Save and Continue
                        </Button>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }

    render() {
        return (
            this.content()
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.sharedReducer.currentUser,
        profile: state.sharedReducer.profile
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getCurrentUser,
        getProfile,
        patchProfile
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerAddProfile);
