
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep, debounce } from 'lodash';

// Libs
import moment from 'moment'

// Shared Resources
import RenderInput from '../../shared/RenderInput';
import {
    degreeOptions,
    majorOptions,
    is_form_valid,
    RenderMajorSelect,
    RenderDegreeSelect,
} from '../../shared/utils';

// Bootstrap Resources
import {
    Panel,
    Row,
    Col,
    Glyphicon,
    FormGroup,
    ControlLabel,
    Form,
    Button,
    Checkbox
} from 'react-bootstrap';
import DatePicker from "react-datepicker/es";
import { Typeahead } from 'react-bootstrap-typeahead';

// App Resources
import { postEducation, postSchool, getCurrentUser, getSchools } from '../../actions';


class EducationNewPanel extends Component {

    // Constructor
    state = {
        editing: !this.props.hasExistingData,  // initialized to open if there is no data, so user can go straight to editing.
        // input placeholders are different from the default values used in dropdowns
        classesTakenPlaceholder: 'Classes Taken',
        clubsAndActivitiesPlaceholder: 'Clubs & Activities',
        remarksPlaceholder: 'Joined a sports team and graduated after an extra year.',
        gpaPlaceholder: '4.0',
        startDate: Date.now(),  // local state independent of localEducation's nested date field only used for the calendar
        endDate: Date.now(),  // local state independent of localEducation's nested date field only used for the calendar
        localEducation: {
            user: null,
            resumeVersion: null,
            school: -1,
            classesTaken: null,
            clubsAndActivities: null,
            remarks: '',
            gpa: null,
            major: 0,
            degree: null,
            startDate: moment(Date.now()).format('YYYY-MM-DD'),
            endDate: moment(Date.now()).format('YYYY-MM-DD'),
            ifCurrentSchool: false,
        }
    };

    async componentDidMount() {
        // set the user
        await this.props.getCurrentUser();
        this.setState({
            localEducation: {
                ...this.state.localEducation,
                resumeVersion: this.props.resume.pk,
                user: this.props.currentUser.id
            }
        });
    }

    resetForms = () => {
        /* Resets state back to initial */
        this.setState({
            localEducation: {
                user: null,
                resumeVersion: this.state.localEducation.resumeVersion,
                school: -1,
                classesTaken: null,
                clubsAndActivities: null,
                remarks: '',
                gpa: null,
                major: 0,
                degree: null,
                startDate: moment(Date.now()).format('YYYY-MM-DD'),
                endDate: moment(Date.now()).format('YYYY-MM-DD'),
                ifCurrentSchool: false,
            },
            startDate: Date.now(),  // local state independent of localEducation's nested date field only used for the calendar
            endDate: Date.now(),  // see above commment
        })
    };
    
    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
        this.resetForms();
    };

    updateSchoolName = (schoolId) => {
        let schoolName = null;
        schoolName = this.props.schools.filter(school => {
            if (school.pk === parseInt(schoolId)) {
                return school.schoolName; // local return for "filter" statement.
            }
        });
        return schoolName[0];
    };

    updateEducationField = (field, value) => {
        const modifiedEducation = cloneDeep(this.state.localEducation);
        modifiedEducation[field] = value;

        if (field === 'school') {
            const schoolObj = this.updateSchoolName(value);
            if (schoolObj) {
                modifiedEducation['schoolName']= schoolObj.schoolName;
            }
        }

        this.setState({ localEducation: modifiedEducation })
    };

    updateField = (field, value) => {
        const modified = cloneDeep(this.state.localEducation);

        if (field === 'school' && Array.isArray(value) && value[0].customOption) {
            const schoolName = value[0].label;
            if (schoolName.length) {
                this.props.postSchool({
                    schoolName: schoolName
                }).then(res => {
                    value = res.payload.data.pk;
                    modified[field] = value;
                    this.setState({ localEducation: modified });
                    return;
                });
            }
        }

        modified[field] = value;
        this.setState({ localEducation: modified });
    };

    onSubmit = () => {
        // validate forms.
        const {
            school,
            major,
            degree,
            startDate,
            endDate,
            ifCurrentSchool
        } = this.state.localEducation;

        if (
            !is_form_valid([
                school,
                major,
                degree,
                startDate,
                (endDate || ifCurrentSchool)
            ])
        ) {
            alert("Errors in form - please correct before saving.");
            // don't save to db and don't toggle/erase data.
        } else if (school === -1) {  // -1 is the default null placeholder.
            alert("You must select a school. If this a new school, 1. Please type in your school name and 2. Click 'Add new:'");
        } else if (endDate && endDate < startDate) {
            alert("Graduation Date must be after your Start Date");
        } else {
            // if doesn't exist create one.
            this.props.postEducation(this.state.localEducation);
            this.toggleEdit();
        }
    };

    handleChange = () => {
        this.updateEducationField(event.target.name, event.target.value);
    };

    handleToggleCheckbox = () => {
        // similar to handleChange
        this.updateField(event.target.name, !this.state.localEducation.ifCurrentSchool);
    };

    handleStartDateChange = (date) => {
        this.setState({
            startDate: date
        }); // save  this to local datepicker component state.
        const startDateFormatted = moment(date).format('YYYY-MM-DD');
        this.updateEducationField('startDate', startDateFormatted);
    };

    handleEndDateChange = (date) => {
        this.setState({
            endDate: date
        }); // save  this to local datepicker component state.
        const endDateFormatted = moment(date).format('YYYY-MM-DD');
        this.updateEducationField('endDate', endDateFormatted);
    };

  async schoolFilter(input) {
    const debouncedFilter = debounce(() => {
      console.log("====>", input);
    }, 500);
    await this.props.getSchools(input, null);  // take an option to filter by name
    debouncedFilter();
  }

    renderForm = () => {
        const {
            classesTaken,
            clubsAndActivities,
            remarks,
            gpa,
            major,
            degree,
            ifCurrentSchool,
        } = this.state.localEducation;

        const optionsList = this.props.schools;

        return (
            <Panel.Body>
                <Form>
                    <Row>
                        <Col md={4}>
                            <div style={{ paddingBottom: '5px' }}><strong>School*</strong></div>
                            <Typeahead
                              allowNew
                              labelKey={option => `${option.schoolName}`}
                              options={optionsList}
                              placeholder='Start typing a school name'
                              newSelectionPrefix="Add new: "
                              style={{ marginBottom: '10px' }}
                              onInputChange={(input) => this.schoolFilter(input)}
                              onChange={selected => {
                                if (selected.length > 0) {
                                    if (selected[0].pk) {
                                        this.updateField('school', selected[0].pk);
                                    } else {
                                        this.updateField('school', selected);
                                    }
                                  }
                                }
                              }
                            />
                        </Col>
                        <Col md={2}>
                            <FormGroup controlId='startDate'>
                                <ControlLabel>Start Date*</ControlLabel>
                                <br/>
                                <DatePicker
                                  selected={this.state.startDate}
                                  onChange={this.handleStartDateChange}
                                  className="form-control"
                                  maxDate={moment().toDate()}
                                />
                            </FormGroup>
                        </Col>
                        {
                            ifCurrentSchool
                            ? null
                            : (
                                <Col md={2}>
                                    <FormGroup controlId='endDate'>
                                        <ControlLabel>Graduation*</ControlLabel>
                                        <br/>
                                        <DatePicker
                                          selected={this.state.endDate}
                                          onChange={this.handleEndDateChange}
                                          className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            )
                        }
                        <Col md={3}>
                            <Checkbox
                              name='ifCurrentSchool'
                              checked={ifCurrentSchool}
                              // ensure checked state reflects current state of ifCurrentSchool correctly
                              onChange={this.handleToggleCheckbox}
                            >
                                I currently study here
                            </Checkbox>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={4}>
                            <RenderMajorSelect
                              label='Major*'
                              name='major'
                              initializedValue={major}
                              type="select"
                              onChange={this.handleChange}
                              options={majorOptions}
                            />
                        </Col>
                        <Col md={4}>
                            <RenderDegreeSelect
                              label='Degree*'
                              name='degree'
                              initializedValue={degree}
                              type="select"
                              onChange={this.handleChange}
                              options={degreeOptions}
                            />
                        </Col>
                        <Col md={4}>
                            <RenderInput
                              label='GPA'
                              name='gpa'
                              placeholder={this.state.gpaPlaceholder}
                              initializedValue={gpa}
                              type="input"
                              onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RenderInput
                              label='Classes Taken'
                              name='classesTaken'
                              type='textarea'
                              placeholder={this.state.classesTakenPlaceholder}
                              initializedValue={classesTaken}
                              onChange={this.handleChange}
                              rows={5}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RenderInput
                              label='Clubs and Activities'
                              name='clubsAndActivities'
                              type='textarea'
                              placeholder={this.state.clubsAndActivitiesPlaceholder}
                              initializedValue={clubsAndActivities}
                              onChange={this.handleChange}
                              rows={3}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RenderInput
                              label='Miscellaneous Remarks'
                              name='remarks'
                              type='textarea'
                              placeholder={this.state.remarksPlaceholder}
                              initializedValue={remarks}
                              onChange={this.handleChange}
                              rows={3}
                            />
                        </Col>
                    </Row>
                    <div className="modal-space-between-confirm-buttons">
                        <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                        <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.toggleEdit()}>Cancel</Button>
                    </div>
                </Form>
            </Panel.Body>
        );
    };

    render () {
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading onClick={() => this.toggleEdit()}>
                    <Row>
                        <Col md={11}>
                            <Glyphicon glyph="plus"/>
                            <p><strong>{' '}Add a new Education</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                { this.state.editing ? this.renderForm() : null }
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        schools: state.resumeReducer.schools,
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postEducation,
            postSchool,
            getCurrentUser,
            getSchools,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EducationNewPanel);
