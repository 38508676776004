// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Libs
import moment from "moment";

// App Resources
import EducationPreviewPanel from "./EducationPreviewPanel";
import EducationEditPanel from "./EducationEditPanel";
import {
  deleteEducation,
  getCurrentUser,
  getSchools,
  toggleDeleteResumeItemModal,
} from "../../actions";

// Bootstrap Resources
import { Panel, Row, Col, Glyphicon } from "react-bootstrap";

// Import Helpers
import { resume_owner_is_current_user } from "../../shared/utils";

class EducationPanel extends Component {
  state = {
    editing: false,
    defaultSelectedOptions: [],
  };

  async componentDidMount() {
    await this.props.getCurrentUser();
    await this.props.getSchools(null, this.props.education.school); // ensure we can get the prepopulated string mapped to a schools list option.
    const thisSchool = this.props.schools.filter(
      (obj) => obj.pk === this.props.education.school,
    );
    this.setState({
      defaultSelectedOptions: thisSchool,
    });
  }

  toggleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  showDeleteModal = (education_id) => {
    this.props.toggleDeleteResumeItemModal("education", () =>
      this.props.deleteEducation(education_id),
    );
  };

  renderBody = () => {
    const { education } = this.props;
    return this.state.editing ? (
      <EducationEditPanel
        education={education}
        defaultSelected={this.state.defaultSelectedOptions} // needs to be set before component mounts*
        toggleEdit={this.toggleEdit}
      />
    ) : (
      <EducationPreviewPanel education={education} />
    );
  };

  render() {
    const { education } = this.props;
    return (
      <Panel style={{ marginBottom: "15px" }}>
        <Panel.Heading>
          <Row>
            <Col md={4}>
              <p>
                <strong>{education.schoolName}</strong>
              </p>
            </Col>
            <Col md={5}>
              <p>
                <em>
                  {education.degree}, {education.major}{" "}
                  {education.gpa ? `(${education.gpa})` : null}
                </em>
              </p>
            </Col>
            <Col md={2}>
              <p>
                <em>
                  {moment(education.startDate).isValid()
                    ? moment(education.startDate).format("YYYY")
                    : ""}
                  -
                  {education.ifCurrentSchool
                    ? "Present"
                    : moment(education.endDate).isValid()
                      ? moment(education.endDate).format("YYYY")
                      : ""}
                </em>
              </p>
            </Col>
            <Col md={1}>
              {resume_owner_is_current_user(
                this.props.resume,
                this.props.currentUser,
              ) ? (
                <div>
                  <Glyphicon glyph="pencil" onClick={this.toggleEdit} />
                  &nbsp;
                  <Glyphicon
                    glyph="remove"
                    onClick={() => this.showDeleteModal(education.pk)}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </Panel.Heading>
        {this.renderBody()}
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    schools: state.resumeReducer.schools,
    resume: state.resumeReducer.resume, // assumes resume state is fetched into redux when this page loads
    currentUser: state.sharedReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteEducation,
      getCurrentUser,
      getSchools,
      toggleDeleteResumeItemModal,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EducationPanel);
