
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Libs
import moment from 'moment'

// Import Helpers
import { getCompanyName } from '../../shared/utils';

// App Resources
import { toggleHighlightsModal } from '../../actions';
import HighlightsNewPanel from './HighlightsNewPanel';
import HighlightsPanel from './HighlightsPanel';

// Bootstrap Resources
import { Button, Modal, Row } from 'react-bootstrap';

// Import Shared
import { resume_owner_is_current_user } from '../../shared/utils';


class HighlightsModal extends Component {

    render() {
        const { isOpen } = this.props.highlightsModal;
        let body = [];
        const { highlights, experiences } = this.props;
        const hasExistingData = !!(highlights && highlights.data && highlights.data.results && highlights.data.results.length);

        if (hasExistingData) {
            body = highlights.data.results.map((highlight) => {
                const companyName = getCompanyName(highlight, experiences);
                // toggle this to edit if this element's local state is in edit mode...
                return (
                    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <Row>
                            <HighlightsPanel highlight={highlight} companyName={companyName} />
                        </Row>
                        <hr className="hrstyle"/>
                    </div>
                );
            });
        }

        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            body.unshift(
                <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                    <Row>
                        <HighlightsNewPanel highlight={null} companyName={null} hasExistingData={hasExistingData} />
                    </Row>
                    <hr className="hrstyle"/>
                </div>
            );
        }

        const title = (
            <strong>Career Highlights</strong>
        );

        return (
            <div>
                <Modal
                  show={ isOpen }
                  bsSize="large"
                  onHide={() => this.props.toggleHighlightsModal()}
                  backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {body}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleHighlightsModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        highlightsModal: state.resumeReducer.highlightsModal,
        highlights: state.resumeReducer.highlights,
        experiences: state.resumeReducer.experiences
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleHighlightsModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsModal);
