
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

// App Resources
import LanguageEditPanel from  './LanguageEditPanel';
import { deleteLanguage, getCurrentUser, toggleDeleteResumeItemModal } from '../../actions';

// Bootstrap Resources
import { Glyphicon } from 'react-bootstrap';

// Import Imgs
import glyph1 from '../../../../assets/img/glyphimg1bars.png';
import glyph2 from '../../../../assets/img/glyphimg2bars.png';
import glyph3 from '../../../../assets/img/glyphimg3bars.png';
import glyph4 from '../../../../assets/img/glyphimg4bars.png';
import glyph5 from '../../../../assets/img/glyphimg5bars.png';

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class LanguagePanel extends Component {
    state = {
        editing: false
    };

    async componentDidMount() {
        await this.props.getCurrentUser();
    }

    toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    glyphImgArr = [glyph1, glyph2, glyph3, glyph4, glyph5];

    renderPreview = (language) => (
        <div>
            <Row style={{ height: "30px" }}>
                <div className="col-sm-4">
                    <p><strong>{ language.languageNameEnglish } </strong></p>
                </div>
                <div className="col-sm-2">
                    <img src={ this.glyphImgArr[language.speaking - 1] } width="22" height="22" alt="glyphImg"/>
                </div>
                <div className="col-sm-2">
                    <img src={ this.glyphImgArr[language.reading - 1] } width="22" height="22" alt="glyphImg"/>
                </div>
                <div className="col-sm-2">
                    <img src={ this.glyphImgArr[language.writing - 1] } width="22" height="22" alt="glyphImg"/>
                </div>
                <div className="col-sm-2">
                    {
                        resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                            (
                                <div>
                                    <Glyphicon glyph="pencil" onClick={this.toggleEdit} />
                                    &nbsp;
                                    <Glyphicon glyph="remove" onClick={() => this.showDeleteModal(language)} />
                                </div>
                            ) :
                            null
                    }
                </div>
            </Row>
            <Row>
                <div className="col-sm-offset-4 col-sm-8">
                    <p>{ language.remarks && `Remarks: ${language.remarks}` }</p>
                </div>
            </Row>
        </div>
    );

    showDeleteModal = (language) => {
        this.props.toggleDeleteResumeItemModal(
            'language',
            () => this.props.deleteLanguage(language.pk)
        );
    };

    renderBody = (language) => {
        return (this.state.editing
            ? <LanguageEditPanel language={language} toggleEdit={this.toggleEdit} />
            : this.renderPreview(language));
    };

    render () {
        const { language } = this.props;
        return this.renderBody(language);
    }
}


function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,  // assumes resume state is fetched into redux when this page loads
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            deleteLanguage,
            getCurrentUser,
            toggleDeleteResumeItemModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePanel);
