
// React Imports
import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Headroom from 'react-headroom';

// Component imports
import ActivationEmailConfirm from './components/ActivationEmailConfirm';
import Navigation from './components/navbar';
import Home from './components/Home';
import Welcome from './components/Welcome';
import Employer from './components/Employer';
import Onboarding from './components/onboarding';
import EmployerOnboarding from './components/EmployerOnboarding';
import PrivateRoute from './components/PrivateRoute';
import Resume from './components/resume';
import JobOpening from './components/JobOpening';
import JobOpeningListWrapper from './components/JobOpeningListWrapper';
import Settings from './components/Settings';
import School from './components/School';
import JobFair from './components/JobFair';
import JobFairCompany from './components/JobFairCompany';
import CandidateJobFairBrowseList from './components/CandidateJobFairBrowseList';
import EmployerDashboard from './components/EmployerDashboard';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPassword from './components/ResetPassword';

// Redux imports
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import  ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import allReducers from './reducers';

// CSS Imports
import 'react-bootstrap-typeahead/css/Typeahead.css';


// Create Store
const createStoreWithMiddleware = applyMiddleware(thunk, ReduxPromise)(createStore);
const store = createStoreWithMiddleware(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// SCSS Import
import '../scss/style.scss'


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <div className="container-fluid" style={{ minHeight: '100%' }}>
                <Headroom>
                    <Navigation />
                </Headroom>
                <Switch>
                    <Route exact path='/home' component={Welcome} />
                    <Route exact path='/home/employer' component={Employer} />

                    {/* Below route doesn't need auth, we simply check token sent via email in reset password save API call */}
                    <Route exact path='/home/reset_password_request' component={ResetPasswordRequest} />
                    <Route path='/home/reset_password' component={ResetPassword} />

                    <PrivateRoute exact path='/home/activation_email_confirm' component={ActivationEmailConfirm} />
                    <PrivateRoute path='/home/dashboard' component={Home} />
                    <PrivateRoute path='/home/employer/dashboard' component={EmployerDashboard} />
                    <PrivateRoute path='/home/employer/jobopening/:pk' component={JobOpening} />
                    <PrivateRoute path='/home/employer/jobopeninglist' component={JobOpeningListWrapper} />
                    <PrivateRoute path='/home/onboarding' component={Onboarding} />
                    <PrivateRoute path='/home/employer/onboarding' component={EmployerOnboarding} />
                    <PrivateRoute path='/home/school/dashboard' component={School} />
                    <PrivateRoute path='/home/jobfair/:pk' component={JobFair} />
                    <PrivateRoute path='/home/jobfaircompany/:pk' component={JobFairCompany} />
                    <PrivateRoute path='/home/candidatejobfairbrowselist' component={CandidateJobFairBrowseList} />
                    <PrivateRoute path='/home/settings' component={Settings} />
                    <PrivateRoute path='/home/resume/:pk' component={Resume} />
                </Switch>
            </div>
        </BrowserRouter>
    </Provider>,

    document.getElementById('root')
);
