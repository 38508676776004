
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// Shared Resources
import {
    is_form_valid,
    referenceRelationshipOptions,
} from "../../shared/utils";

// App Resources
import RenderInput from '../../shared/RenderInput';
import {
    postReference,
    getExperiences,
    getCurrentUser
} from '../../actions';

// Bootstrap Resources
import {
    Button,
    ControlLabel,
    Form,
    FormGroup,
    FormControl,
    Panel,
    Row,
    Col,
    Glyphicon
} from 'react-bootstrap';
import RenderSelect from "../../shared/RenderSelect";


const RenderExperienceSelect = ({ label, type, experience, experiences, name, onChange }) => {
    // TODO: Add error checking

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              value={experience ? experience : -1}
              onChange={onChange}
            >
                <option value={-1} key={-1}>Select where you and they worked</option>
                {
                    experiences.data.results.map(experienceObj => (
                        <option value={experienceObj.pk} key={experienceObj.pk}>{ experienceObj.companyName }</option>
                ))}
            </FormControl>
        </FormGroup>
    );
};

// const RenderDayOfWeekSelect = ({ label, name, type, initializedValue, onChange }) => {
//     // TODO: Add error checking
//
//     return (
//         <FormGroup controlId='name'>
//             <ControlLabel>{label}</ControlLabel>
//             <FormControl
//               componentClass={type}
//               name={name}
//               value={initializedValue ? initializedValue : 1}
//               onChange={onChange}
//             >
//                 <option value={1}>Monday</option>
//                 <option value={2}>Tuesday</option>
//                 <option value={3}>Wednesday</option>
//                 <option value={4}>Thursday</option>
//                 <option value={5}>Friday</option>
//                 <option value={6}>Saturday</option>
//                 <option value={7}>Sunday</option>
//             </FormControl>
//         </FormGroup>
//     );
// };
//
// const RenderHourPickerSelect = ({ label, name, type, initializedValue, onChange }) => {
//     // TODO: Add error checking
//     return (
//         <FormGroup controlId='name'>
//             <ControlLabel>{label}</ControlLabel>
//             <FormControl
//               componentClass={type}
//               name={name}
//               value={initializedValue ? initializedValue : '12:00'}
//               onChange={onChange}
//             >
//                 <option value='12:00'>12:00</option>
//                 <option value='1:00'>1:00</option>
//                 <option value='1:30'>1:30</option>
//                 <option value='2:00'>2:00</option>
//                 <option value='2:30'>2:30</option>
//                 <option value='3:00'>3:00</option>
//                 <option value='3:30'>3:30</option>
//                 <option value='4:00'>4:00</option>
//                 <option value='4:30'>4:30</option>
//                 <option value='5:00'>5:00</option>
//                 <option value='5:30'>5:30</option>
//                 <option value='6:00'>6:00</option>
//                 <option value='6:30'>6:30</option>
//                 <option value='7:00'>7:00</option>
//                 <option value='7:30'>7:30</option>
//                 <option value='8:00'>8:00</option>
//                 <option value='8:30'>8:30</option>
//                 <option value='9:00'>9:00</option>
//                 <option value='9:30'>9:30</option>
//                 <option value='10:00'>10:00</option>
//                 <option value='10:30'>10:30</option>
//                 <option value='11:00'>11:00</option>
//                 <option value='11:30'>11:30</option>
//             </FormControl>
//         </FormGroup>
//     );
// };
//
// const RenderAmPmSelect = ({ label, type, name, initializedValue, onChange }) => {
//     // TODO: Add error checking
//
//     return (
//         <FormGroup controlId='name'>
//             <ControlLabel>{label}</ControlLabel>
//             <FormControl
//               componentClass={type}
//               name={name}
//               value={initializedValue ? initializedValue : 0}
//               onChange={onChange}
//             >
//                 <option value="0">AM</option>
//                 <option value="1">PM</option>
//             </FormControl>
//         </FormGroup>
//     );
// };


class ReferencesEditPanel extends Component {

    // Constructor
    state = {
        // input placeholders are different from the default values
        // used in dropdowns
        firstNamePlaceholder: 'First Name',
        lastNamePlaceholder: 'Last Name',
        relationshipPlaceholder: 'Relationship',
        phonePlaceholder: 'Phone',
        emailPlaceholder: 'Email',
        edit: !this.props.hasExistingData,  // initialized to open if there is no data, so user can go straight to editing.,,
        localReference: {
            user: null,
            resumeVersion: null,
            experience: 0,
            firstNameOfRef: null,
            lastNameOfRef: null,
            relationship: null,
            refEmail: null,
            refPhone: null,
            refTimeZone: 1,
            // timeAvailableStartDate: 1,
            // timeAvailableEndDate: 5,
            // timeAvailableStartTime: '9:00',
            // startTimeAMPM: 0,
            // timeAvailableEndTime: '5:00',
            // endTimeAMPM: 1
        }
    };

    async componentDidMount() {
        // set the user
        await this.props.getCurrentUser();
        this.setState({
            localReference: {
                ...this.state.localReference,
                resumeVersion: this.props.resume.pk,
                user: this.props.currentUser.id
            }
        });
    }

    toggleEdit = () => {
        this.setState({ edit: !this.state.edit });
    };

    updateReferenceField = (field, value) => {
        const modifiedReference = cloneDeep(this.state.localReference);
        modifiedReference[field] = value;
        this.setState({ localReference: modifiedReference })
    };

    onSubmit = () => {
        // validate forms.
        const {
            firstNameOfRef,
            lastNameOfRef,
            relationship,
            experience,
            refEmail,
            refPhone,
        } = this.state.localReference;

        if (
            !is_form_valid([
                firstNameOfRef,
                lastNameOfRef,
                relationship,
                experience,
                refEmail,
                refPhone,
            ])
        ) {
            return;
            // don't save to db and don't toggle/erase data.
        }
        this.props.postReference(this.state.localReference);
        this.toggleEdit();
    };

    handleChange = () => {
        this.updateReferenceField(event.target.name, event.target.value);
    };

    renderForm = () => {
        return (
            <Panel.Body>
                <Form>
                    <Row>
                        <Col md={6}>
                            <RenderInput
                              label='First Name*'
                              name='firstNameOfRef'
                              placeholder={this.props.firstNamePlaceholder}
                              type="input"
                              onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <RenderInput
                              label='Last Name*'
                              name='lastNameOfRef'
                              placeholder={this.props.lastNamePlaceholder}
                              type="input"
                              onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <RenderExperienceSelect
                              label='Company*'
                              name='experience'
                              type='select'
                              experience={this.state.localReference.experience}
                              experiences={this.props.experiences}
                              onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <RenderSelect
                              label='Relationship*'
                              name='relationship'
                              type='select'
                              initializedValue={0}
                              onChange={this.handleChange}
                              options={referenceRelationshipOptions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <ControlLabel>Phone*</ControlLabel>
                            <PhoneInput
                              placeholder={this.props.phonePlaceholder}
                              country={'us'}
                              value={this.state.localReference.refPhone}
                              onChange={this.handleChange}
                              inputProps={{
                                  name: 'refPhone'
                              }}
                            />
                        </Col>
                        <Col md={6}>
                            <RenderInput
                              label='Email*'
                              name='refEmail'
                              placeholder={this.props.emailPlaceholder}
                              type="input"
                              onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col md={6}>*/}
                    {/*        <RenderDayOfWeekSelect*/}
                    {/*          label='Start Date'*/}
                    {/*          name='timeAvailableStartDate'*/}
                    {/*          initializedValue={this.state.localReference.timeAvailableStartDate}*/}
                    {/*          type='select'*/}
                    {/*          onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col md={6}>*/}
                    {/*        <RenderDayOfWeekSelect*/}
                    {/*          label='End Date'*/}
                    {/*          name='timeAvailableEndDate'*/}
                    {/*          initializedValue={this.state.localReference.timeAvailableEndDate}*/}
                    {/*          type='select'*/}
                    {/*          onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    <Col md={4}>*/}
                    {/*        <RenderHourPickerSelect*/}
                    {/*          label='From'*/}
                    {/*          name='timeAvailableStartTime'*/}
                    {/*          type='select'*/}
                    {/*          initializedValue={this.state.localReference.timeAvailableStartTime}*/}
                    {/*          onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col md={2}>*/}
                    {/*        <RenderAmPmSelect*/}
                    {/*          label='AM/PM'*/}
                    {/*          name='startTimeAMPM'*/}
                    {/*          type='select'*/}
                    {/*          initializedValue={this.state.localReference.startTimeAMPM}*/}
                    {/*          onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col md={4}>*/}
                    {/*        <RenderHourPickerSelect*/}
                    {/*          label='To'*/}
                    {/*          name='timeAvailableEndTime'*/}
                    {/*          type='select'*/}
                    {/*          initializedValue={this.state.localReference.timeAvailableEndTime}*/}
                    {/*          onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col md={2}>*/}
                    {/*        <RenderAmPmSelect*/}
                    {/*          label='AM/PM'*/}
                    {/*          name='endTimeAMPM'*/}
                    {/*          type='select'*/}
                    {/*          initializedValue={this.state.localReference.endTimeAMPM}*/}
                    {/*          onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <div className="modal-space-between-confirm-buttons">
                        <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                        <Button bsStyle="warning" style={{ marginLeft: '5px' }} onClick={() => this.toggleEdit()}>Cancel</Button>
                    </div>
                </Form>
            </Panel.Body>
        );
    };

    render() {
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading onClick={() => this.toggleEdit()}>
                    <Row>
                        <Col md={11}>
                            <Glyphicon glyph="plus" />
                            <p><strong>{' '}Add a New Reference</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                { this.state.edit ? this.renderForm() : null }
            </Panel>
        );
    }
}


function mapStateToProps(state) {
    return {
        experiences: state.resumeReducer.experiences,
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            postReference,
            getExperiences,
            getCurrentUser
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesEditPanel);
