// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Libs
import moment from "moment";

// App Resources
import ExperienceEditPanel from "./ExperienceEditPanel";
import {
  deleteExperience,
  getCurrentUser,
  getCompanies,
  toggleDeleteResumeItemModal,
} from "../../actions";

// Bootstrap Resources
import { Row, Col, Glyphicon } from "react-bootstrap";

// Import Helpers
import { resume_owner_is_current_user } from "../../shared/utils";

class ExperiencePanel extends Component {
  state = {
    editing: false,
    defaultSelectedOptions: [],
  };

  async componentDidMount() {
    await this.props.getCurrentUser();
    await this.props.getCompanies(null, this.props.experience.company); // ensure we can get the prepopulated string mapped to a companies list option.
    const thisCompany = this.props.companies.filter(
      (obj) => obj.pk === this.props.experience.company,
    );
    this.setState({
      defaultSelectedOptions: thisCompany,
    });
  }

  toggleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  showDeleteModal = (experience_id) => {
    this.props.toggleDeleteResumeItemModal(
      "experience",
      () => this.props.deleteExperience(experience_id),
      "Deleting this experience will remove the experience field across all references and highlights bound " +
        "to this experience.",
      true,
    );
  };

  renderPreview = (experience) => {
    const startDateFormatted = moment(experience.startDate).isValid()
      ? moment(experience.startDate).format("MM/YYYY")
      : "";
    const endDateFormatted = experience.ifCurrentExperience
      ? "Present"
      : moment(experience.endDate).isValid()
        ? moment(experience.endDate).format("MM/YYYY")
        : "";

    return (
      <div>
        <Row>
          <p className="col-sm-5">
            <em>
              {startDateFormatted} - {endDateFormatted}
            </em>{"  "}
            <strong>{experience.companyName}</strong>
          </p>
          <p className="col-sm-3">
            <strong>{experience.companyCity}</strong>
          </p>
          <p className="col-sm-3">
            <strong>{experience.position}</strong>
          </p>
          <Col md={1}>
            {resume_owner_is_current_user(
              this.props.resume,
              this.props.currentUser,
            ) ? (
              <div>
                <Glyphicon glyph="pencil" onClick={this.toggleEdit} />
                &nbsp;
                <Glyphicon
                  glyph="remove"
                  onClick={() => this.showDeleteModal(experience.pk)}
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <div className="marginLeft15 keep-whitespace">{experience.summary}</div>
      </div>
    );
  };

  renderBody = () => {
    const { experience } = this.props;
    return this.state.editing ? (
      <ExperienceEditPanel
        experience={experience}
        defaultSelected={this.state.defaultSelectedOptions}  // needs to be set before component mounts*
        toggleEdit={this.toggleEdit}
      />
    ) : (
      this.renderPreview(experience)
    );
  };

  render() {
    return this.renderBody();
  }
}

function mapStateToProps(state) {
  return {
    companies: state.resumeReducer.companies,
    resume: state.resumeReducer.resume, // assumes resume state is fetched into redux when this page loads
    currentUser: state.sharedReducer.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      getCompanies,
      deleteExperience,
      toggleDeleteResumeItemModal,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencePanel);
