
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Libs
import moment from 'moment';

// App Resources
import RenderSkillGroup from './RenderSkillGroup';
import SkillModal from './Dialogs/SkillModal';
import SummaryModal from './Dialogs/SummaryModal';
import HighlightsModal from './Dialogs/HighlightsModal';
import LanguageModal from './Dialogs/LanguageModal';
import EducationModal from './Dialogs/EducationModal';
import ReferencesModal from './Dialogs/ReferencesModal';
import ExperienceModal from './Dialogs/ExperienceModal';
import DeleteResumeItemModal from './Dialogs/DeleteResumeItemModal';

// Bootstrap
import { Row } from 'react-bootstrap';

// Import Imgs
import glyph1 from '../../../assets/img/glyphimg1bars.png';
import glyph2 from '../../../assets/img/glyphimg2bars.png';
import glyph3 from '../../../assets/img/glyphimg3bars.png';
import glyph4 from '../../../assets/img/glyphimg4bars.png';
import glyph5 from '../../../assets/img/glyphimg5bars.png';

// Import Helpers
import { getCompanyName } from '../shared/utils';
import { resume_owner_is_current_user } from '../shared/utils';

// Component Specific Imports
import {
    getCurrentUser,
    getResume,
    getHasAccessToResume,
    getSummary,
    getCoverLetter,
    getHighlights,
    getEducations,
    getSchools,
    getLanguages,
    getReferences,
    getExperiences,
    getSkillGroups,
    getUserSkillMap,
    getSkillHours,
    toggleSkillModal,
    toggleSummaryModal,
    toggleHighlightsModal,
    toggleLanguageModal,
    toggleEducationModal,
    toggleReferencesModal,
    toggleExperienceModal
} from '../actions';


class Resume extends Component {

    async componentDidMount () {
        // load data into map state to props
        const resumeId = this.props.match.params.pk; // id from url
        await this.props.getCurrentUser();
        await this.props.getResume(resumeId);
        await this.props.getHasAccessToResume(resumeId);
        await this.props.getSummary(resumeId);
        await this.props.getCoverLetter(resumeId);
        await this.props.getHighlights(resumeId);
        await this.props.getEducations(resumeId);
        await this.props.getSchools();
        await this.props.getLanguages(resumeId);
        await this.props.getReferences(resumeId);
        await this.props.getExperiences(resumeId);
        await this.props.getSkillGroups(resumeId);
        await this.props.getUserSkillMap(resumeId);
        await this.props.getSkillHours(resumeId);
    }

    renderNoExperience = () => (
        <div style={{ backgroundColor: "#efefef", borderRadius: "3px", padding: "5px", marginTop: "5px" }}>
            <h5>Enter experiences before filling out this section.</h5>
        </div>
    );
    
    hasExperiences (experiences) {
        return experiences.data && experiences.data.results.length > 0
    }

    hasReferences (references) {
        return references.data && references.data.results.length > 0
    }

    hasHighlights (highlights) {
        return highlights.data && highlights.data.results.length > 0
    }

    hasSkillGroups (skillGroups) {
        return skillGroups.data && skillGroups.data.results.length > 0
    }

    renderSummary() {
        let summaryModal = <SummaryModal resume={this.props.resume} />;
        let body = <span></span>;
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px"}}>
                    <h5>Write something about yourself.</h5>
                </div>
            );
        }

        if (this.props.summary) {
            const { summary } = this.props;
            const { summaryText } = summary;

            body = <p className="keep-whitespace">{summaryText}</p>;
            summaryModal = <SummaryModal summary={summary} resume={this.props.resume} />;
        }

        return (
            <div>
                <div className="col-sm-6 resumeformatLHBox" onClick={() => this.props.toggleSummaryModal()}>
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline"><strong>Summary</strong></div>
                    </div>
                    <div className="resumeformatContentArea">
                        { body }
                    </div>
                </div>
                { summaryModal }
            </div>
        )
    }

    renderHighlights() {
        let highlights = null;
        const highlightsModal = <HighlightsModal />;
        let companyName = null;
        let body = (<span></span>);
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{ backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px" }}>
                    <h5>Enter notable career or education highlights.</h5>
                </div>
            );
        }

        if (this.hasHighlights(this.props.highlights)) { // I think a change in highlights prop here doesn't rerender this component thus child components stay stale
            highlights = this.props.highlights.data.results;

            body = highlights.map((highlight) => {
                companyName = getCompanyName(highlight, this.props.experiences, true);

                return (
                    <div key={ highlight.pk }>
                        <p><strong>{ highlight.quantity }</strong> ({ companyName }{ moment(highlight.highlightDate).format('YYYY') })</p>
                        <hr className="hrstyle" />
                    </div>
                );
            });
        }

        const shouldDisableHighlight = (
            !this.hasExperiences(this.props.experiences)
            && !this.hasHighlights(this.props.highlights)
            && resume_owner_is_current_user(this.props.resume, this.props.currentUser)
            // Don't show this helper box if recruiter viewing.
        );

        return(
            <div>
                <div
                    className="col-sm-6 resumeformatRHBox"
                    style={{
                        opacity: shouldDisableHighlight ? 0.7 : 1,
                        pointerEvents: shouldDisableHighlight ? "none" : "initial"
                    }}
                    onClick={() => this.props.toggleHighlightsModal()}
                >
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline"><strong>Career Highlights</strong></div>
                    </div>
                    <div className="resumeformatContentArea">
                        { shouldDisableHighlight ? this.renderNoExperience() : body }
                    </div>
                </div>
                { highlightsModal }
            </div>
        )
    }

    renderEducations() {
        let educations = null;
        let schools = null;
        if (this.props.schools.length > 0) {
            schools = this.props.schools;
        }

        let educationModal = <EducationModal schools={schools} />;
        let body = <span></span>;
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px"}}>
                    <h5>Enter places you have studied (High School, College, Grad School, Bootcamps).</h5>
                </div>
            );
        }

        if (this.props.educations.data && this.props.educations.data.results.length > 0) {
            educations = this.props.educations.data.results;
            educationModal = <EducationModal schools={schools} />;

            body = educations.map((education) => {
            const endDateFormatted = moment(education.endDate).isValid() ? moment(education.endDate).format('YYYY') : '-----';
            const startDateFormatted = moment(education.startDate).isValid() ? moment(education.startDate).format('YYYY') : '-----';

                return(
                    <div>
                        <Row>
                            <div className="col-sm-6">
                                <p><em>{ startDateFormatted }-{ endDateFormatted }</em></p>
                                <span>&nbsp;</span>
                                <p><strong>{ education.schoolName }</strong></p>
                            </div>
                            <div className="col-sm-6">
                                <p>{ education.degreeAbbreviated }</p>
                                <span>&nbsp;</span>
                                <p>{ education.major }</p>
                            </div>
                        </Row>
                        <hr className="hrstyle" />
                    </div>
                );
            });
        }
        return(
            <div>
                <div className="col-sm-5 resumeformatEducationBox" onClick={() => this.props.toggleEducationModal()}>
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline"><strong>Education</strong></div>
                    </div>
                    <div className="resumeformatContentArea">
                        { body }
                    </div>
                </div>
                { educationModal }
            </div>
        )
    }

    renderLanguages() {
        let languages = null;
        const glyphImgArr = [glyph1, glyph2, glyph3, glyph4, glyph5];
        const languageModal = <LanguageModal />;
        let body = <span></span>;
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px"}}>
                    <h5>Enter Languages you know.</h5>
                </div>
            );
        }

        if (this.props.languages.data && this.props.languages.data.results.length > 0) {
            languages = this.props.languages.data.results;

            body = languages.map((language) => {
                return(
                    <div>
                        <Row>
                            <div className="col-sm-9">
                                <p><strong>{ language.languageNameEnglish } </strong></p>
                            </div>
                            <div className="col-sm-3">
                                <img src={ glyphImgArr[language.speaking - 1] } width="12" height="12" alt="glyphImg"/>
                            </div>
                        </Row>
                        <hr className="hrstyle" />
                    </div>
                );
            });
        }
        return(
            <div>
                <div className="col-sm-2half resumeformatLanguageBox" onClick={() => this.props.toggleLanguageModal()}>
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline"><strong>Languages</strong></div>
                    </div>
                    <div className="resumeformatContentArea">
                        { body }
                    </div>
                </div>
                { languageModal }
            </div>
        )
    }

    renderReferences() {
        let references = null;
        const referencesModal = <ReferencesModal />;
        // TODO: INitializing above causes problem if there is preview data.
        let body = (<span></span>);
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px"}}>
                    <h5>Enter your professional references.</h5>
                </div>
            );
        }

        if (this.hasReferences(this.props.references)) {
            references = this.props.references.data.results;

            body = references.map((reference) => {
                let companyName = getCompanyName(reference, this.props.experiences);

                return (
                    <div>
                        <Row>
                            <div className="col-sm-7">
                                <p><strong>{ reference.firstNameOfRef } { reference.lastNameOfRef }</strong></p>
                            </div>
                            <div className="col-sm-5" style={{textAlign: 'right'}}>
                                <p>{ companyName }</p>
                            </div>
                        </Row>
                        <hr className="hrstyle" />
                    </div>
                );
            });
        }

        const shouldDisableReferences = (
            !this.hasExperiences(this.props.experiences)
            && !this.hasReferences(this.props.references)
            && resume_owner_is_current_user(this.props.resume, this.props.currentUser)
            // Don't show this helper box if recruiter viewing.
        );
        return (
            <div>
                <div
                    className="col-sm-4 resumeformatReferencesBox"
                    style={{
                        opacity: shouldDisableReferences ? 0.7 : 1,
                        pointerEvents: shouldDisableReferences ? "none" : "initial"
                    }}
                    onClick={() => this.props.toggleReferencesModal()}>
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline"><strong>References</strong></div>
                    </div>
                    <div className="resumeformatContentArea">
                        {shouldDisableReferences ? this.renderNoExperience() : body}
                    </div>
                </div>
                { referencesModal }
            </div>
        )
    }

    renderExperience() {
        let experiences = null;
        const experienceModal = <ExperienceModal />;
        let body = <span></span>;
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px"}}>
                    <h5>Enter your Work Experiences.</h5>
                </div>
            );
        }

        if (this.hasExperiences(this.props.experiences)) {
            experiences = this.props.experiences.data.results;

            body = experiences.map((experience) => {
                const endDateFormatted = experience.ifCurrentExperience
                  ? "Present"
                  : moment(experience.endDate).isValid()
                    ? moment(experience.endDate).format("MMMM YYYY")
                    : "";

                return(
                    <div>
                        <Row>
                            <p className="col-sm-5"><em>{moment(experience.startDate).format('MMMM YYYY')} - {endDateFormatted}</em>&nbsp; <strong>{ experience.companyName }</strong></p>
                            <p className="col-sm-3"><strong>{ experience.companyCity }</strong></p>
                            <p className="col-sm-4"><strong>{ experience.position }</strong></p>
                        </Row>
                        <pre className="marginLeft15">
                            { experience.summary }
                        </pre>
                        <hr className="hrstyle" />
                    </div>
                );
            });
        }
        return(
            <div>
                <div className="col-sm-12 resumeformatMainBox" onClick={() => this.props.toggleExperienceModal()}>
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline"><strong>Experiences</strong></div>
                    </div>
                    <div className="resumeformatContentArea">
                        { body }
                    </div>
                </div>
                { experienceModal }
            </div>
        )
    }

    renderSkills() {
        let skillGroups = null;
        let userSkillMap = null;
        let skillHours = null;
        let experiences = null; // refactor and below this out of this function

        if (this.hasExperiences(this.props.experiences)) {
            experiences = this.props.experiences.data.results;
        }

        // Initialize skillHours from props.
        if (this.props.skillHours.data && this.props.skillHours.data.results.length > 0) {
            skillHours = this.props.skillHours.data.results;
        }

        const skillModal = <SkillModal />;
        let body = <span></span>;
        if (resume_owner_is_current_user(this.props.resume, this.props.currentUser)) {
            // Don't show this helper box if recruiter viewing.
            body = (
                <div style={{backgroundColor: "#FFC72C", borderRadius: "3px", padding: "5px", marginTop: "5px"}}>
                    <h5>Enter your Skills</h5>
                </div>
            );
        }

        if (this.props.skillGroups.data && this.props.skillGroups.data.results.length > 0) {
            // Null case when we have skill group with no skill under it.
            skillGroups = this.props.skillGroups.data.results;
            const resumeId = this.props.match.params.pk;
            body = skillGroups.map((skillGroup) => {
                return (
                    <div>
                        <p><strong>{ skillGroup.skillGroupName }</strong></p>
                        <hr className="hrstyle" />
                    </div>
                );
            });

            if (this.props.userSkillMap.data && this.props.userSkillMap.data.results.length > 0) {
                // The non-null case when we actually have userSkillMap instances to render for this skill group.
                userSkillMap = this.props.userSkillMap.data.results;
                body = skillGroups.map((skillGroup) => {
                    const bundledSkills = userSkillMap.filter(obj => {
                        return obj.skillGroup === skillGroup.pk
                    });
                    return (
                        <RenderSkillGroup
                            skillGroup={skillGroup}
                            bundledSkills={bundledSkills}
                            skillHours={skillHours}
                            experiences={experiences}
                            resumeId={resumeId}
                        />
                    );
                });
            }
        }

        const title = (
            <Row>
                <strong className="col-sm-4">Skills</strong>
                <p className="col-sm-2" style={{ fontSize: '12px' }}>Total Yrs</p>
                <p className="col-sm-2" style={{ fontSize: '12px' }}>Total Hrs</p>
                <p className="col-sm-2" style={{ fontSize: '12px' }}>Last Used</p>
                <p className="col-sm-2" style={{ fontSize: '12px' }}>Ability</p>
            </Row>
        );

        const shouldDisableSkills = (
            !this.hasExperiences(this.props.experiences)
            && resume_owner_is_current_user(this.props.resume, this.props.currentUser)
            // Don't show this helper box if recruiter viewing.
        );

        return (
            <div>
                <div
                    className="resumeformatSkillsBox col-sm-12"
                    style={{
                        opacity: shouldDisableSkills ? 0.7 : 1,
                        pointerEvents: shouldDisableSkills ? "none" : "initial"
                    }}
                    onClick={() => this.props.toggleSkillModal()}>
                    <div className="row">
                        <div className="col-sm-12 lightGrayHeader grayBoxElementUnderline">{title}</div>
                    </div>
                    <div className="resumeformatContentArea">
                        { shouldDisableSkills ? this.renderNoExperience() : body }
                    </div>
                </div>
                { skillModal }
            </div>
        );
    }


    createResume() {
        if (this.props.resume === null) {
            return (
              <div className='col-sm-9 col-sm-offset-1'>Loading...</div>
            );
        } else {
            return (
                <div className="row">
                    <div className='col-sm-7half col-sm-offset-ThreeQuarter mainColumnParent'>
                        { this.renderSummary() }
                        { this.renderHighlights() }
                        { this.renderExperience() }
                        { this.renderReferences() }
                        { this.renderEducations() }
                        { this.renderLanguages() }
                    </div>
                    <div className='col-sm-3 mainColumnParent'>
                        { this.renderSkills() }
                    </div>
                </div>
            )
        }
    }

    render() {
        if (this.props.hasAccessToResume) {
            return (
                <>
                    {this.createResume()}
                    <DeleteResumeItemModal/>
                </>
            );
        }
        return (
            <h4>You do not have access to this resumes</h4>
        )
    }
}


function mapStateToProps(state) {
    return {
        hasAccessToResume: state.resumeReducer.hasAccessToResume,
        resume: state.resumeReducer.resume,
        summary: state.resumeReducer.summary,
        coverLetter: state.resumeReducer.coverLetter,
        highlights: state.resumeReducer.highlights,
        educations: state.resumeReducer.educations,
        schools: state.resumeReducer.schools,
        languages: state.resumeReducer.languages,
        references: state.resumeReducer.references,
        experiences: state.resumeReducer.experiences,
        skillGroups: state.resumeReducer.skillGroups,
        userSkillMap: state.resumeReducer.userSkillMap,
        skillHours: state.resumeReducer.skillHours,
        currentUser: state.sharedReducer.currentUser,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            getCurrentUser,
            getResume,
            getHasAccessToResume,
            getSummary,
            getCoverLetter,
            getHighlights,
            getEducations,
            getSchools,
            getLanguages,
            getReferences,
            getExperiences,
            getSkillGroups,
            getUserSkillMap,
            getSkillHours,
            toggleSkillModal,
            toggleSummaryModal,
            toggleHighlightsModal,
            toggleLanguageModal,
            toggleEducationModal,
            toggleReferencesModal,
            toggleExperienceModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume);