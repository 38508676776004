
import { combineReducers } from 'redux';
import Resume from './ResumeReducer';
import Shared from './SharedReducer';
import Employer from './EmployerReducer';

import { reducer as formReducer } from 'redux-form';


const allReducers = combineReducers({
    employerReducer: Employer,
    resumeReducer: Resume,
    sharedReducer: Shared,
    form: formReducer, /*use this key pair naming verbatim for redux forms to work*/
}); // Combines all imported reducers, maps them into a class that will be used application-wide

export default allReducers;
