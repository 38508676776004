
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import { toggleReferencesModal } from '../../actions';
import ReferencesPanel from './ReferencesPanel';
import ReferencesNewPanel from  './ReferencesNewPanel';

// Bootstrap Resources
import { Button, Modal, Row } from 'react-bootstrap';

// Import Helpers
import { getCompanyName } from '../../shared/utils';

// Import Shared
import { resume_owner_is_current_user } from '../../shared/utils';


class ReferencesModal extends Component {

    addReference = (hasExistingData) => {
        return (
            <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                <Row>
                    <ReferencesNewPanel reference={null} companyName={null} hasExistingData={hasExistingData} />
                </Row>
            </div>
        );
    };

    render() {
        const { isOpen } = this.props.referencesModal;
        let body = [];
        const { references, experiences } = this.props;
        const hasExistingData = !!(references && references.data && references.data.results && references.data.results.length);

        if (hasExistingData) {
            body = references.data.results.map((reference) => {
                const companyName = getCompanyName(reference, experiences);
                return (
                    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <Row>
                            <ReferencesPanel reference={reference} companyName={companyName} />
                        </Row>
                    </div>
                );
            });
        }

        const title = (
            <strong>References</strong>
        );

        return (
            <div>
                <Modal
                  show={ isOpen }
                  bsSize="large"
                  onHide={() => this.props.toggleReferencesModal()}
                  backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            resume_owner_is_current_user(this.props.resume, this.props.currentUser) ?
                                this.addReference(hasExistingData) :
                                null
                        }
                        { body }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleReferencesModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resume: state.resumeReducer.resume,
        currentUser: state.sharedReducer.currentUser,
        referencesModal: state.resumeReducer.referencesModal,
        references: state.resumeReducer.references,
        experiences: state.resumeReducer.experiences
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleReferencesModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesModal);
