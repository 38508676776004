
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';

// Component Specific Imports
import RenderInput from '../shared/RenderInput';
import {
    getCurrentUser,
    getJobOpenings,
    createJobOpening,
    deleteJobOpening,
    postCompany,
    postSkill,
    getSkills,
    getEmployerForEmployee,
} from '../actions';

// Bootstrap
import { Button, Row, Glyphicon, Modal, Col } from 'react-bootstrap';

// Img Import
import yellowtag from '../../../assets/img/yellowtag.png';
import { Typeahead } from 'react-bootstrap-typeahead';


const imgStyle = {
   height: '15px',
   position: 'absolute',
   right: '0'
};

class ResumeTable extends Component {

    state = {
        confirmDeleteModalOpen: false,
        job_opening_id: null,
        createNewResumeOpen: false,
        role: '',
        description: '',
        skill_ids: new Set(),
        employer_internal_job_id: null
    };

    async componentDidMount() {
        await this.props.getCurrentUser();
        await this.props.getJobOpenings();
        await this.props.getSkills();
        await this.props.getEmployerForEmployee();
    }

    toggleConfirmDelete = (job_opening_id=null) => {
        this.setState({
            confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
            job_opening_id
        })
    };

    toggleCreateJobDescription = () => {
        this.setState({
            createNewResumeOpen: !this.state.createNewResumeOpen,
        })
    };
    
    async deleteJobOpening () {
        await this.props.deleteJobOpening(this.state.job_opening_id);
        this.toggleConfirmDelete();
        this.setState({ job_opening_id: null })
    }
    
    updateField = (field, value) => {
        if (field === 'skill_id') {
            if (Array.isArray(value) && value[value.length - 1].customOption) {
                const skillName = value[value.length - 1].label;
                if (skillName.length) {
                    this.props.postSkill({
                        skillName
                    }).then(res => {
                        value = res.payload.data.pk;
                        // update multi select state, can't update state directly, so use clonedeep
                        const modified_skill_ids = cloneDeep(this.state.skill_ids);
                        modified_skill_ids.add(value);
                        this.setState({ skill_ids: modified_skill_ids });
                        return;
                    });
                }
            } else {
                // update multi select state, can't update state directly, so use clonedeep
                const modified_skill_ids = cloneDeep(this.state.skill_ids);
                modified_skill_ids.add(value);
                this.setState({ skill_ids: modified_skill_ids });
                return;
            }
        }

        this.setState({ [field]: value })
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    async createJobOpening () {
        await this.props.createJobOpening({
            role: this.state.role,
            description: this.state.description,
            company_id: this.props.employer_of_recruiter.pk,
            employer_internal_job_id: this.state.employer_internal_job_id,
            skill_ids: [...this.state.skill_ids]  // Serialize set to array for passing over RESTful API
        });
        this.toggleCreateJobDescription();
        // clean up state to a fresh slate after this job opening is created.
        this.setState({
            role: '',
            description: '',
            employer_internal_job_id: null,
            skill_ids: new Set()
        })
    }

    createModal () {
        const skillsOptionsList = this.props.skills ? this.props.skills : [];

        return (
            <Modal show={this.state.createNewResumeOpen} bsSize="large" onHide={() => this.toggleCreateJobDescription()}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Job Opening</Modal.Title>
                </Modal.Header>
                {/*Company, Role, Description, Skill/*/}
                <Modal.Body>
                    <Row>
                        <Col md={10}>
                            <RenderInput
                              label='Role'
                              name='role'
                              placeholder={this.state.role}
                              type="input"
                              onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <RenderInput
                              label='Description'
                              name='description'
                              type='textarea'
                              placeholder={this.state.description}
                              onChange={this.handleChange}
                              rows={3}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div style={{ paddingBottom: '5px' }}><strong>Skills</strong></div>
                            <Typeahead
                              multiple
                              allowNew
                              labelKey={option => `${option.skillName}`}
                              options={skillsOptionsList}
                              placeholder='Start typing some skill names'
                              style={{ marginBottom: '10px' }}
                              onChange={selected => {
                                if (selected.length > 0) {
                                    const new_added_item_index = selected.length - 1;
                                    if (selected[new_added_item_index].pk) {
                                        this.updateField('skill_id', selected[new_added_item_index].pk);
                                    } else {
                                        // Handle creating new item (ie it doesn't have a pk yet)
                                        this.updateField('skill_id', selected);
                                    }
                                  }
                                }
                              }
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="primary" onClick={() => this.createJobOpening()}>Create</Button>
                    <Button bsStyle="default" style={{ marginLeft: '5px' }} onClick={() => this.toggleCreateJobDescription()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    confirmDeleteModal () {
        return (
            <Modal show={this.state.confirmDeleteModalOpen} onHide={() => this.toggleConfirmDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to delete this job opening?</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-space-between-confirm-buttons">
                    <Button bsStyle="danger" onClick={() => this.deleteJobOpening()}>Yes</Button>
                    <Button bsStyle="default" style={{ marginLeft: '5px' }} onClick={() => this.toggleConfirmDelete()}>Cancel</Button>
                </Modal.Body>
            </Modal>
        )
    }

    renderTable () {
        if (!this.props.jobOpenings || !this.props.jobOpenings.length ) {
            return (
                <div />
            );
        } else {
            return this.props.jobOpenings.map((jobOpening) => {
                return(
                    <div key={jobOpening.job_opening_pk}>
                        <Row style={{ height: "60px" }}>
                            <Link key={jobOpening.job_opening_pk} to={ `/home/employer/jobopening/${jobOpening.job_opening_pk}` }>
                                <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                    <img src={yellowtag} alt="yellowtag" style={imgStyle}/>
                                </div>
                                <div className="col-sm-9 vhr">
                                    <h5 style={{ color: "#000"}}>
                                       { jobOpening.companyName }
                                    </h5>
                                    <p style={{ color: "#7C7C7C" }}>{ jobOpening.role }</p>
                                </div>
                            </Link>
                            <Link key={jobOpening.job_opening_pk} to={ `/home/employer/jobopening/${jobOpening.job_opening_pk}` }>
                                <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                    <Button bsStyle="primary" bsSize="small" href={ `/home/resume/${jobOpening.job_opening_pk}` }>View</Button>
                                </div>
                            </Link>
                            <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                <Glyphicon glyph="remove" onClick={() => this.toggleConfirmDelete(jobOpening.job_opening_pk)} />
                            </div>
                        </Row>
                        <hr className="hrZeroMargin" />
                    </div>
                );
            });
        }
    }

    render () {
        return(
            <div className="col-sm-12">
                <div className="grayBoxElementUnderline">
                    <h4>
                        Your Job Openings
                    </h4>
                </div>
                <div className="resumeformatContentArea">
                    { this.renderTable() }
                    { this.confirmDeleteModal() }
                    { this.createModal() }
                    <Button bsStyle="primary" style={{ marginTop: "10px" }} onClick={() => this.toggleCreateJobDescription()}>
                        <Glyphicon glyph="plus" />
                        &nbsp;Create or Import New Job
                    </Button>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        currentUser: state.sharedReducer.currentUser,
        jobOpenings: state.employerReducer.jobOpenings,
        companies: state.resumeReducer.companies,
        skills: state.employerReducer.skills,
        employer_of_recruiter: state.employerReducer.employer_of_recruiter,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getCurrentUser,
        getJobOpenings,
        createJobOpening,
        deleteJobOpening,
        postCompany,
        postSkill,
        getSkills,
        getEmployerForEmployee,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeTable);
