import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SchoolSettings from "./SchoolSettings";

// Component Specific Imports
import { getUsers, getCurrentUser, getProfile, getSchoolByInstitutionAdminUser, createJobFair } from "../actions";

// Bootstrap Resources
import {
  Tabs,
  Tab,
  Button,
  Modal,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import SchoolJobFairTable from "./SchoolJobFairTable";
import RenderInput from "../shared/RenderInput";
import moment from "moment";
import { cloneDeep } from "lodash";
import DatePicker from "react-datepicker/es";
import {is_form_valid} from "../shared/utils";

const SCHOOL_ORGANIZER_TYPE = 1; // TODO lift to shared location

const contentWrapper = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
};

const logoStyle = {
  width: "10%",
};

const defaultLogo = require(`${"../../../assets/img/school_mascot/default.png"}`);
// TODO actually create a default picture here

class School extends Component {
  state = {
    showUpload: false,
    images: [],
    createNewJobFairModalOpen: false,
    startDate: Date.now(), // local state independent of localNewJobFair's nested date field only used for the calendar
    endDate: Date.now(), // local state independent of localNewJobFair's nested date field only used for the calendar
    localNewJobFair: {
      name: "",
      startDate: moment(Date.now()).format("YYYY-MM-DD"),
      endDate: moment(Date.now()).format("YYYY-MM-DD"),
      organizer_id: null,
      organizer_type: SCHOOL_ORGANIZER_TYPE,
    },
  };

  componentDidMount() {
    this.props.getUsers(); // loaded by map state to props
    this.props.getProfile();
    this.props.getCurrentUser();
    this.props.getSchoolByInstitutionAdminUser();
  }

  resetForms = () => {
    /* Resets state back to initial */
    this.setState({
      localNewJobFair: {
        name: "",
        startDate: moment(Date.now()).format("YYYY-MM-DD"),
        endDate: moment(Date.now()).format("YYYY-MM-DD"),
        organizer_id: this.props.school.pk,
        organizer_type: SCHOOL_ORGANIZER_TYPE,
      },
      startDate: Date.now(), // local state independent of setState's nested date field only used for the calendar
      endDate: Date.now(), // see above comment
    });
  };

  updateField = (field, value) => {
    const modifiedLocalJobFair = cloneDeep(this.state.localNewJobFair);
    modifiedLocalJobFair[field] = value;
    this.setState({ localNewJobFair: modifiedLocalJobFair });
  };

  handleChange = () => {
      this.updateField(event.target.name, event.target.value);
  };

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
    }); // save  this to local datepicker component state.
    const dateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("startDate", dateFormatted);
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    }); // save  this to local datepicker component state.
    const dateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("endDate", dateFormatted);
  };

  toggleCreateNew = () => {
    this.setState({
      createNewJobFairModalOpen: !this.state.createNewJobFairModalOpen,
    });
    this.resetForms();
  };
  
  onSubmit = () => {
    // validate forms.
    const {
      name,
      startDate,
      endDate,
    } = this.state.localNewJobFair;

    if (
        !is_form_valid([
          name,
          startDate,
          endDate,
        ])
    ) {
        alert("Errors in form - please correct before saving.");
        // don't save to db and don't toggle/erase data.
    } else if (endDate && endDate < startDate) {
        alert("End Date must be after your Start Date");
    } else {
      this.props.createJobFair({...this.state.localNewJobFair, organizer_id: this.props.school.pk});
      this.toggleCreateNew();
    }
  };

  createNewJobFairModal() {
    return (
      <Modal
        show={this.state.createNewJobFairModalOpen}
        onHide={() => this.toggleCreateNew()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a new Job Fair</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <RenderInput
                label="Job Fair Name"
                name="name"
                placeholder={this.state.localNewJobFair.name}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup controlId="startDate">
                <ControlLabel>Start Date*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  className="form-control"
                  maxDate={moment().toDate()}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup controlId="endDate">
                <ControlLabel>End Date*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  className="form-control"
                  maxDate={moment().toDate()}
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.onSubmit()}>
            Create
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleCreateNew()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  main() {
    let logoPath = defaultLogo; // Handles case when image is not in database and not in media folder.
    if (this.props.school?.mascot) {
      logoPath = `../../../media/mascot${
        this.props.school.pk
      }.${this.props.school.mascot.split(".").pop()}`;
    }

    return (
      <div style={contentWrapper}>
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src={logoPath}
              alt="logo"
              onError={this.addDefaultImage}
              style={logoStyle}
              onClick={() => {
                this.toggleProfileImage();
              }}
            />

            <h3>
              {this.props.school ? this.props.school.schoolName : ""} Careers
            </h3>
            {/* Fetch the school this admin user is bound to. admin user signs up with email the school tagged them in
          thus that email is their login id.*/}
          </div>

          <Tabs
            defaultActiveKey="jobFair"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="jobFair" title="Job Fairs">
              <Button
                bsStyle="primary"
                style={{ textAlign: "left", marginTop: "10px" }}
                onClick={() => this.toggleCreateNew()}
              >
                Create a New Job Fair
              </Button>
              <SchoolJobFairTable school={this.props.school}/>
            </Tab>
            <Tab eventKey="admin" title="Admin">
              <br />
              <p>Contact Findfit.io to add new admin users.</p>
            </Tab>
            <Tab eventKey="schoolSettings" title="School Settings">
              <SchoolSettings logoPath={logoPath} />
            </Tab>
            {/*<Tab eventKey="contact" title="Students">*/}
            {/*  <p>Students</p>*/}
            {/*</Tab>*/}
          </Tabs>
        </div>
        {this.createNewJobFairModal()}
      </div>
    );
  }

  render() {
    const { profile } = this.props;

    if (profile?.isSchoolUser) {
      return this.main();
    }
    return <p>This feature is for schools only.</p>;
  }
}

function mapStateToProps(state) {
  return {
    users: state.sharedReducer.users,
    currentUser: state.sharedReducer.currentUser,
    profile: state.sharedReducer.profile,
    school: state.employerReducer.school,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getUsers, getProfile, getCurrentUser, getSchoolByInstitutionAdminUser, createJobFair },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(School);
