
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App Resources
import RenderInput from '../../shared/RenderInput';
import {
    toggleSummaryModal,
    postSummary,
    putSummary,
    getCurrentUser
} from '../../actions';

// Bootstrap Resources
import { Button, Form, Modal } from 'react-bootstrap';

// Import Helpers
import { resume_owner_is_current_user } from '../../shared/utils';


class SummaryModal extends Component {

    state = {
        // cannot copy props here because this component, unlike other are rendered on page load, s.t.
        // home.js has not loaded state when constructor is called
        localSummary: '',  // for edit mode
        showUpload: false,
    };

    async componentDidMount() {
        // Fetch user 1x in lifecycle, won't change once resume modal mounted.
        await this.props.getCurrentUser();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.summaryModal !== this.props.summaryModal) {
        // Sync edit form state with fresh version of summary from db on modal open
        this.setState({
            localSummary: this.props?.summary?.summaryText || '',
        });
      }
    }

    updateField = (field, value) => {
        if (field === 'summaryText') {
            this.setState({ localSummary: value })
        }
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    onSubmit = () => {
        const summaryId = this.props.summary ? this.props.summary.pk : null;

        if (summaryId) {
            this.props.putSummary({
                summaryText: this.state.localSummary,
                resumeVersion: this.props.resume.pk
            }, summaryId)
        } else {
            this.props.postSummary({
                summaryText: this.state.localSummary,
                resumeVersion: this.props.resume.pk
            });
        }

        this.props.toggleSummaryModal();
    };

    renderPreview = () => {
        return (
            <>
                <h5>Summary</h5>
                <p className="keep-whitespace">{this.props.summary ? this.props.summary.summaryText : ''}</p>
            </>
        );
    };

    renderEdit = () => {
        return (
            <Form>
                <RenderInput
                  label='Summary'
                  name='summaryText'
                  placeholder='Write a few sentences about why you will make a good hire.'
                  initializedValue={this.state.localSummary}
                  rows={5}
                  type="textarea"
                  onChange={this.handleChange}
                />
                <div className="modal-space-between-confirm-buttons" style={{ marginTop: "20px" }}>
                    <Button bsStyle="primary" onClick={() => this.onSubmit()}>Save</Button>
                </div>
            </Form>
        );
    };

    render() {
        const { isOpen } = this.props.summaryModal;
        const body = resume_owner_is_current_user(this.props.resume, this.props.currentUser) ? this.renderEdit() : this.renderPreview();

        const title = (
            <strong>Summary</strong>
        );

        return (
            <div>
                <Modal show={ isOpen } bsSize="large" onHide={() => this.props.toggleSummaryModal()} backdrop={resume_owner_is_current_user(this.props.resume, this.props.currentUser) ? "static" : undefined}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {body}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.props.toggleSummaryModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        summaryModal: state.resumeReducer.summaryModal,
        resume: ownProps.resume,
        summary: ownProps.summary,
        currentUser: state.sharedReducer.currentUser
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators(
        {
            toggleSummaryModal,
            postSummary,
            putSummary,
            getCurrentUser
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(SummaryModal);
