import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import DownloadResumeModal from "./Dialogs/DownloadResumeModal";
import ResumeUploadModal from "./Dialogs/ResumeUploadModal";

// Component Specific Imports
import RenderInput from "../shared/RenderInput";
import {
  getCurrentUser,
  getResumes,
  createResume,
  editResume,
  deleteResume,
} from "../actions";

// Bootstrap
import { Button, Row, Glyphicon, Modal, Col } from "react-bootstrap";

// Img Import
import yellowtag from "../../../assets/img/yellowtag.png";
import moment from "moment";

const imgStyle = {
  height: "15px",
  position: "absolute",
  right: "0",
};

class ResumeTable extends Component {
  state = {
    confirmDeleteModalOpen: false,
    resume_id: null,
    createNewResumeOpen: false,
    uploadResumeOpen: false,
    resumeName: "New Resume", // if clone then show 'New Resume' else show default resume name
    cloneNewResumeOpen: false,
    editResumeOpen: false,
    downloadResumeOpen: false,
    showAllResume: false,
  };

  async componentDidMount() {
    await this.props.getCurrentUser();
    await this.props.getResumes();
  }

  cloneResume = (resume_id) => {
    const url = `/resume/clone_resume/${resume_id}/${this.props.currentUser.id}`;
    // const token = localStorage.getItem('token');
    // TODO: put resume_id and current_user id into the values request dict below.
    const values = {
      resumeName: this.state.resumeName,
    };
    // axios.post(url, values, { headers: {"Authorization" : `Bearer ${token}`} }).then(() => {
    // this doesn't work because it is a post, and post is more strict:
    axios.post(url, values).then(() => {
      // Clean state.
      this.setState({
        resumeName: "New Resume",
        resume_id: null,
      });
      window.location.reload();
    });
  };

  async editResume(resume_id) {
    await this.props.editResume({
      lastEdited: moment().format("YYYY-MM-DD"),
      resumeName: this.state.resumeName,
      resume_id,
    });
    this.toggleEditResumeName();
    this.setState({ resumeName: "New Resume" });
  }

  toggleConfirmDelete = (resume_id = null) => {
    this.setState({
      confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
      resume_id,
    });
  };

  toggleCreateNewResume = () => {
    this.setState({
      createNewResumeOpen: !this.state.createNewResumeOpen,
    });
  };

  toggleUploadResume = () => {
    this.setState({
      uploadResumeOpen: !this.state.uploadResumeOpen,
    });
  };


  viewAllResumes = () => {
    this.setState({
      showAllResume: true,
    });
  };

  toggleCloneNewResume = (resume_id = null) => {
    this.setState({
      cloneNewResumeOpen: !this.state.cloneNewResumeOpen,
      resume_id,
    });
  };

  toggleEditResumeName = (resume_id = null) => {
    this.setState({
      editResumeOpen: !this.state.editResumeOpen,
      resume_id,
    });
  };

  toggleDownloadResume = (resume_id = null) => {
    this.setState({
      downloadResumeOpen: !this.state.downloadResumeOpen,
      resume_id,
    });
  };

  async deleteResume() {
    await this.props.deleteResume(this.state.resume_id);
    this.toggleConfirmDelete();
    this.setState({ resume_id: null });
  }

  updateField = (field, value) => {
    this.setState({ [field]: value });
  };

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  async createResume() {
    await this.props.createResume({
      lastEdited: moment().format("YYYY-MM-DD"),
      resumeName: this.state.resumeName,
    });
    this.toggleCreateNewResume();
    this.setState({ resumeName: "New Resume" });
  }

  createNewResumeModal() {
    return (
      <Modal
        show={this.state.createNewResumeOpen}
        bsSize="large"
        onHide={() => this.toggleCreateNewResume()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Resume</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <RenderInput
                label="New Resume Name"
                name="resumeName"
                placeholder={this.state.resumeName}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => this.createResume()}>
            Create
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleCreateNewResume()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  editResumeModal() {
    return (
      <Modal
        show={this.state.editResumeOpen}
        bsSize="large"
        onHide={() => this.toggleEditResumeName()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Resume Name</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <RenderInput
                label="Resume Name"
                name="resumeName"
                placeholder={this.state.resumeName}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => this.editResume(this.state.resume_id)}
          >
            Save Changes
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleEditResumeName()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  cloneNewResumeModal() {
    return (
      <Modal
        show={this.state.cloneNewResumeOpen}
        bsSize="large"
        onHide={() => this.toggleCloneNewResume()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Resume From An Existing One</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <RenderInput
                label="New Resume Copy's Name"
                name="resumeName"
                placeholder={this.state.resumeName}
                type="input"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => this.cloneResume(this.state.resume_id)}
          >
            Create
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleCloneNewResume()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  confirmDeleteModal() {
    return (
      <Modal
        show={this.state.confirmDeleteModalOpen}
        onHide={() => this.toggleConfirmDelete()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this resume?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-space-between-confirm-buttons">
          <Button bsStyle="danger" onClick={() => this.deleteResume()}>
            Yes
          </Button>
          <Button
            bsStyle="default"
            style={{ marginLeft: "5px" }}
            onClick={() => this.toggleConfirmDelete()}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  createResumeTable() {
    if (!this.props.resumes || !this.props.resumes.length) {
      return <p>Loading...</p>;
    } else {
      const resumes =
        this.state.showAllResume || this.props.resumes.length <= 10
          ? this.props.resumes
          : this.props.resumes.slice(0, 10);

      return resumes.map((resume) => {
        return (
          <div key={resume.pk}>
            <Row style={{ height: "40px" }}>
              <Link to={`/home/resume/${resume.pk}`}>
                <div
                  className="col-sm-1 vertical-align"
                  style={{ height: "100%" }}
                >
                  <img src={yellowtag} alt="yellowtag" style={imgStyle} />
                </div>
                <div className="col-sm-4 row-fluid vertical-align vhr hoverable">
                  <h5 style={{ color: "#000" }}>{resume.resumeName}</h5>
                  {/*<p style={{ margin: "10px 0px", color: "#7C7C7C" }}>*/}
                  {/*    &nbsp;/&nbsp;{ resume.description }*/}
                  {/*</p>*/}
                </div>
              </Link>
              <div
                className="col-sm-7 vertical-align vhr"
                style={{ height: "100%", justifyContent: "space-between" }}
              >
                <Button
                  bsStyle="warning"
                  bsSize="small"
                  onClick={() => this.toggleEditResumeName(resume.pk)}
                >
                  Update Resume Name
                </Button>
                <Button
                  bsStyle="info"
                  bsSize="small"
                  onClick={() => this.toggleCloneNewResume(resume.pk)}
                >
                  Copy Resume <Glyphicon glyph="plus" />
                </Button>
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  onClick={() => this.toggleDownloadResume(resume.pk)}
                >
                  Download Resume &nbsp;
                  <Glyphicon glyph="download" />
                </Button>
                <Link to={`/home/resume/${resume.pk}`}>
                  <Button
                    bsStyle="success"
                    bsSize="small"
                    href={`/home/resume/${resume.pk}`}
                  >
                    Edit Resume <Glyphicon glyph="pencil" />
                  </Button>
                </Link>
                <Glyphicon
                  glyph="trash"
                  onClick={() => this.toggleConfirmDelete(resume.pk)}
                  style={{ color: "#f55d42" }}
                />
              </div>
            </Row>
            <hr className="hrZeroMargin" />
          </div>
        );
      });
    }
  }

  render() {
    return (
      <div className="col-sm-12">
        <div className="grayBoxElementUnderline">
          <h4>Resumes Versions</h4>

          <Button
            bsStyle="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => this.toggleCreateNewResume()}
          >
            <Glyphicon glyph="plus" />
            &nbsp;New Resume
          </Button>
          <Button
            bsStyle="success"
            style={{ marginBottom: "10px", marginLeft: "10px" }}
            onClick={() => this.toggleUploadResume()}
          >
            <Glyphicon glyph="upload" />
            &nbsp;Upload an Existing Resume
          </Button>

        </div>
        <div className="resumeformatContentArea">
          {this.createResumeTable()}
          {this.confirmDeleteModal()}
          {this.createNewResumeModal()}
          {this.editResumeModal()}
          {this.cloneNewResumeModal()}

          <DownloadResumeModal
            isOpen={this.state.downloadResumeOpen}
            toggleOpen={this.toggleDownloadResume}
            resumeId={this.state.resume_id}
            currentUserId={this.props.currentUser.id}
          />

          <ResumeUploadModal
            show={this.state.uploadResumeOpen}
            onHide={this.toggleUploadResume}
          />

          {!this.state.showAllResume && this.props.resumes.length > 10 && (
            <div style={{ flexDirection: "row", justifyContent: "flexStart" }}>
              <Button
                bsStyle="warning"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={() => this.viewAllResumes()}
              >
                View All Resumes
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    resumes: state.resumeReducer.resumes, // Shallow vs deep values is our issue here...
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      getResumes,
      createResume,
      editResume,
      deleteResume,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeTable);
