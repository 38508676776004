
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Component Specific Imports
import { getJobOpeningApplicantMap, unApplyToJob, getCompanyApplicantMap, deleteCompanyApplicantMap } from '../actions';

// Bootstrap
import { Button, Row, Glyphicon, Modal } from 'react-bootstrap';

// Img Import
// import recruiter from '../../../assets/img/recruiter.png';
import greentag from "../../../assets/img/greentag.png";


const imgStyle = {
   height: '15px',
   position: 'absolute',
   right: '0'
};

class ActivityTable extends Component {

    state = {
        confirmUnApplyModalOpen: false,
        job_application_id: null,
        confirmUnConnectModalOpen: false,
        connection_id: null,
    };
    
    componentDidMount() {
        this.props.getJobOpeningApplicantMap(null);
        this.props.getCompanyApplicantMap();
    }
    
    toggleConfirmUnApply = (job_application_id=null) => {
        this.setState({
            confirmUnApplyModalOpen: !this.state.confirmUnApplyModalOpen,
            job_application_id
        })
    };

    toggleConfirmUnConnect = (connection_id=null) => {
        this.setState({
            confirmUnConnectModalOpen: !this.state.confirmUnConnectModalOpen,
            connection_id
        })
    };

    async unApplyToJob () {
        await this.props.unApplyToJob(this.state.job_application_id);
        this.toggleConfirmUnApply();
        this.setState({ job_application_id: null });
    };

    async unConnectCompany () {
        await this.props.deleteCompanyApplicantMap(this.state.connection_id); // may need to use state.
        this.toggleConfirmUnConnect();
        this.setState({ connection_id: null });
    };

    confirmDeleteModal () {
        return (
            <Modal show={this.state.confirmUnApplyModalOpen} onHide={() => this.toggleConfirmUnApply()}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to un-apply to this job opening?</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-space-between-confirm-buttons">
                    <Button bsStyle="danger" onClick={() => this.unApplyToJob()}>Yes</Button>
                    <Button bsStyle="default" style={{ marginLeft: '5px' }} onClick={() => this.toggleConfirmUnApply()}>Cancel</Button>
                </Modal.Body>
            </Modal>
        )
    }

    confirmCompaniesConnectedDeleteModal () {
        return (
            <Modal show={this.state.confirmUnConnectModalOpen} onHide={() => this.toggleConfirmUnConnect()}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to rescind your resume from this company?</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-space-between-confirm-buttons">
                    <Button bsStyle="danger" onClick={() => this.unConnectCompany()}>Yes</Button>
                    <Button bsStyle="default" style={{ marginLeft: '5px' }} onClick={() => this.toggleConfirmUnConnect()}>Cancel</Button>
                </Modal.Body>
            </Modal>
        )
    }
    
    createActivityTable () {
        if (!this.props.jobOpeningApplicantMap?.length) {
            return;
        } else {
            return this.props.jobOpeningApplicantMap.map((each) => {
                return (
                    <div key={each.pk + each.job_opening}>
                        <Row style={{ height: "60px" }}>
                            <Link to={ `/home/employer/jobopening/${each.job_opening}` }>
                                <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                    <img src={greentag} alt="yellowtag" style={imgStyle}/>
                                </div>
                                <div className="col-sm-5 vhr" style={{ height: "100%" }}>
                                    <h5 style={{ color: "#000"}}>{ each.job_opening_company_serialized.company.companyName }</h5>
                                    <p style={{ color: "#7C7C7C" }}>{ each.job_opening_company_serialized.role }</p>
                                </div>
                                <div className="col-sm-4 vhr">
                                    <h5 style={{ color: "#000"}}>Applied with</h5>
                                    <p style={{ color: "#7C7C7C" }}>{ each.resume_serialized.resumeName }</p>
                                </div>
                            </Link>
                            <Link to={ `/home/employer/jobopening/${each.job_opening}` }>
                                <div className="col-sm-1 vertical-align vhr" style={{ height: "100%" }}>
                                    <Button bsStyle="primary" bsSize="small" >
                                        View
                                    </Button>
                                </div>
                            </Link>
                            <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                <Glyphicon glyph="trash" onClick={() => this.toggleConfirmUnApply(each.pk)} style={{ color: "#f55d42" }} />
                            </div>
                        </Row>
                        <hr className="hrZeroMargin" />
                    </div>
                );
            });
        }
    }

    
    createCompaniesConnectedTable () {
        if (!this.props.companyApplicantMap.length) {
            return;
        } else {
            return this.props.companyApplicantMap.map((each) => {
                return (
                    <div key={each.pk}>
                        <Row style={{ height: "60px" }}>
                            <Link to={ `/home/jobfaircompany/${each.company.pk}` }>
                                <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                    <img src={greentag} alt="greentag" style={imgStyle}/>
                                </div>
                                <div className="col-sm-5 vhr" style={{ height: "100%" }}>
                                    <h5 style={{ color: "#000"}}>{ each.company.companyName }</h5>
                                </div>
                                <div className="col-sm-4 vhr">
                                    <h5 style={{ color: "#000"}}>Applied with</h5>
                                    <p style={{ color: "#7C7C7C" }}>{ each.resume.resumeName }</p>
                                </div>
                            </Link>
                            <Link to={ `/home/jobfaircompany/${each.company.pk}` }>
                                <div className="col-sm-1 vertical-align vhr" style={{ height: "100%" }}>
                                    <Button bsStyle="primary" bsSize="small" >
                                        View
                                    </Button>
                                </div>
                            </Link>
                            <div className="col-sm-1 vertical-align" style={{ height: "100%" }}>
                                <Glyphicon glyph="trash" onClick={() => this.toggleConfirmUnConnect(each.pk)} style={{ color: "#f55d42" }} />
                            </div>
                        </Row>
                        <hr className="hrZeroMargin" />
                    </div>
                );
            });
        }
    }

    render () {
        return(
            <div className="col-sm-12">
                <div className="grayBoxElementUnderline">
                    <h4>
                        Jobs Openings Applications
                    </h4>
                    <Button bsStyle="primary" style={{ marginBottom: "10px" }} onClick={() => this.props.history.push('/home/CandidateJobFairBrowseList/')}>
                        <Glyphicon glyph="plus" />
                        &nbsp;Find Jobs
                    </Button>
                </div>
                <div className="resumeformatContentArea">
                    { this.createActivityTable() }
                    { this.confirmDeleteModal() }
                </div>

                <div className="grayBoxElementUnderline">
                    <h4>
                        Companies Connected With
                    </h4>
                </div>
                <div className="resumeformatContentArea">
                    { this.createCompaniesConnectedTable() }
                    { this.confirmCompaniesConnectedDeleteModal() }
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        jobOpeningApplicantMap: state.employerReducer.jobOpeningApplicantMap,
        companyApplicantMap: state.employerReducer.companyApplicantMap,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ getJobOpeningApplicantMap, unApplyToJob, getCompanyApplicantMap, deleteCompanyApplicantMap }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable);