// React Resources
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep, debounce } from "lodash";
import DatePicker from "react-datepicker";

// Libs
import moment from "moment";

// Shared Resources
import { is_form_valid } from "../../shared/utils";

// App Resources
import RenderInput from "../../shared/RenderInput";
import {
    postExperience,
    putExperience,
    postCompany,
    getSkillHours,
    getUserSkillMap,
    getCompanies,
} from "../../actions";
import "react-datepicker/dist/react-datepicker.css";

// Bootstrap Resources
import {
  Button,
  ControlLabel,
  Form,
  FormGroup,
  Panel,
  Row,
  Col,
  Checkbox,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

class ExperienceEditPanel extends Component {
  // Constructor
  state = {
    // input placeholders are different from the default values used in dropdowns
    localExperience: cloneDeep(this.props.experience),
    startDate: new Date(this.props.experience.startDate),
    endDate: new Date(this.props.experience.endDate),
    summaryPlaceholder: "Summary",
  };


  updateField = (field, value) => {
    const modifiedExperience = cloneDeep(this.state.localExperience);

    if (field === "company" && Array.isArray(value) && value[0].customOption) {
      const companyName = value[0].label;
      if (companyName.length) {
        this.props
          .postCompany({
            companyName: companyName,
          })
          .then((res) => {
            value = res.payload.data.pk;
            modifiedExperience[field] = value;
            this.setState({ localExperience: modifiedExperience });
            return;
          });
      }
    }

    modifiedExperience[field] = value;
    this.setState({ localExperience: modifiedExperience });
  };

  async onSubmit() {
    // validate forms.
    const { company, startDate, endDate, ifCurrentExperience, position } =
      this.state.localExperience;

    if (
      !is_form_valid([
        company,
        startDate,
        endDate || ifCurrentExperience,
        position,
      ])
    ) {
      alert("Errors in form - please correct before saving.");
      // don't save to db and don't toggle/erase data.
    } else if (endDate && endDate < startDate) {
      alert("End Date must be after your Start Date");
    } else {
      // If an experience doesn't exist create one.
      this.state.localExperience
        ? this.props.putExperience(
            this.state.localExperience,
            this.state.localExperience.pk,
          )
        : this.props.postExperience(this.state.localExperience);
      this.props.toggleEdit();
      await this.props.getSkillHours(this.props.resume.pk);
      await this.props.getUserSkillMap(this.props.resume.pk);
    }
  }

  handleChange = () => {
    this.updateField(event.target.name, event.target.value);
  };

  handleToggleCheckbox = () => {
    // similar to handleChange
    this.updateField(
      event.target.name,
      !this.state.localExperience.ifCurrentExperience,
    );
  };

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
    }); // save  this to local datepicker component state.
    const startDateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("startDate", startDateFormatted);
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    }); // save  this to local datepicker component state.
    const endDateFormatted = moment(date).format("YYYY-MM-DD");
    this.updateField("endDate", endDateFormatted);
  };

  async companyFilter(input) {
    const debouncedFilter = debounce(() => {
      console.log("====>", input);
    }, 500);
    await this.props.getCompanies(input, null); // take an option to filter by name
    debouncedFilter();
  }

  renderForm = () => {
    const { summary, position, ifCurrentExperience } =
      this.state.localExperience;

    // const options = [{ pk: 1, name: 'A' }, { pk: 3, name: 'B' }];
    // https://github.com/ericgio/react-bootstrap-typeahead/issues/353
    // https://github.com/ericgio/react-bootstrap-typeahead/blob/master/docs/Usage.md
    // note onchange is triggered only when clicking the menu item, not by just typing and triggering
    // a rendered dropdown.
    // not case sensitive

    return (
      <Form>
        <Row>
          <Col md={3}>
            <div style={{ paddingBottom: "5px" }}>
              <strong>Company*</strong>
            </div>
            <Typeahead
              allowNew
              labelKey={(option) => `${option.companyName}`}
              options={this.props.companies}
              placeholder="Start typing a company name"
              style={{ marginBottom: "10px" }}
              onInputChange={(input) => this.companyFilter(input)}
              defaultSelected={this.props.defaultSelected}
              onChange={(selected) => {
                if (selected.length > 0) {
                  if (selected[0].pk) {
                    this.updateField("company", selected[0].pk);
                  } else {
                    this.updateField("company", selected);
                  }
                }
              }}
            />
          </Col>
          <Col md={3}>
            <RenderInput
              label="Position*"
              name="position"
              type="input"
              placeholder="Position"
              initializedValue={position}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          {/* Form fields for city role and company
                    Company will come from a company list from the
                    company table.

                    titles and roles, does LI/ANgellist give a dropdown
                    s/t it will be easier to query

                    Location, that will be a dropdown so we can map that
                    into the google maps API.

                    // need to populate those  values into state
                    // populate those dbs and then load it into the form in
                    // this component.
                    */}
          <Col md={3}>
            <FormGroup controlId="startDate">
              <ControlLabel>Start Date*</ControlLabel>
              <br />
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                className="form-control"
                maxDate={moment().toDate()}
              />
            </FormGroup>
          </Col>
          {ifCurrentExperience ? null : (
            <Col md={3}>
              <FormGroup controlId="endDate">
                <ControlLabel>End Date*</ControlLabel>
                <br />
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  className="form-control"
                  maxDate={moment().toDate()}
                />
              </FormGroup>
            </Col>
          )}
          <Col md={3}>
            <Checkbox
              name="ifCurrentExperience"
              checked={ifCurrentExperience}
              // ensure checked state reflects current state of ifCurrentExperience correctly
              onChange={this.handleToggleCheckbox}
            >
              I currently work here
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <RenderInput
              label="Summary"
              name="summary"
              type="textarea"
              placeholder={this.state.summaryPlaceholder}
              initializedValue={summary}
              onChange={this.handleChange}
              rows={3}
            />
          </Col>
        </Row>
        <div className="modal-space-between-confirm-buttons">
          <Button bsStyle="primary" onClick={() => this.onSubmit()}>
            Save
          </Button>
          <Button
            bsStyle="warning"
            style={{ marginLeft: "5px" }}
            onClick={() => this.props.toggleEdit()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    const { experience } = this.props;
    const startDateFormatted = moment(experience.startDate).isValid()
      ? moment(experience.startDate).format("MM/YYYY")
      : "";

      const endDateFormatted = experience.ifCurrentExperience
        ? "Present"
        : moment(experience.endDate).isValid()
          ? moment(experience.endDate).format("MM/YYYY")
          : "";


    return (
      <Panel>
        <Panel.Heading>
          <Row>
            <p className="col-sm-5">
              <em>
                {startDateFormatted} - {endDateFormatted}
              </em>{" "}
              <strong>{experience.companyName || null}</strong>
            </p>
            <p className="col-sm-3">
              <strong>{experience.companyCity || null}</strong>
            </p>
            <p className="col-sm-3">
              <strong>{experience.position || null}</strong>
            </p>
          </Row>
        </Panel.Heading>
        <Panel.Body>
            {this.renderForm()}
        </Panel.Body>
      </Panel>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    companies: state.resumeReducer.companies,
    experience: ownProps.experience,
    resume: state.resumeReducer.resume,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postExperience,
      putExperience,
      getCompanies,
      postCompany,
      getSkillHours,
      getUserSkillMap,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExperienceEditPanel);
