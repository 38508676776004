import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Component Specific Imports
import { getJobOpeningApplicantMap } from "../actions";

// Bootstrap
import { Button, Glyphicon, Row } from "react-bootstrap";

// Shared Resources
import DownloadResumeModal from "./Dialogs/DownloadResumeModal";

// Img Import
import greentag from "../../../assets/img/greentag.png";

const imgStyle = {
  height: "15px",
  position: "absolute",
  right: "0",
};

class EmployerActivityTable extends Component {
  state = {
    confirmUnApplyModalOpen: false,
    job_application_id: null,
    downloadResumeOpen: false,
    shouldExportSkillBars: true,
    resume_id: null,
  };

  async componentDidMount() {
    await this.props.getJobOpeningApplicantMap(null);
  }

  toggleDownloadResume = (resume_id = null) => {
    this.setState({
      downloadResumeOpen: !this.state.downloadResumeOpen,
      resume_id,
    });
  };

  handleShouldShowSkillBars = () => {
    this.setState({
      shouldExportSkillBars: !this.state.shouldExportSkillBars,
    });
  };

  createActivityTable() {
    if (!this.props.jobOpeningApplicantMap?.length) {
      return;
    } else {
      return this.props.jobOpeningApplicantMap.map((each) => {
        return (
          <div key={each.pk + each.job_opening}>
            <Row style={{ height: "60px" }}>
              <Link to={`/home/resume/${each.resume}`}>
                <div
                  className="col-sm-1 vertical-align"
                  style={{ height: "100%" }}
                >
                  <img src={greentag} alt="greentag" style={imgStyle} />
                </div>
                <div className="col-sm-3 vhr" style={{ height: "100%" }}>
                  <h5 style={{ color: "#000" }}>
                    {each.job_opening_company_serialized.company.companyName}
                  </h5>
                  <p style={{ color: "#7C7C7C" }}>
                    {each.job_opening_company_serialized.role}
                  </p>
                </div>
                <div className="col-sm-3 vhr">
                  <h5 style={{ color: "#000" }}>Applicant</h5>
                  <p style={{ color: "#7C7C7C" }}>
                    {each.applicant.first_name} {each.applicant.last_name}
                  </p>
                </div>
              </Link>
              <Link to={`/home/resume/${each.resume}`}>
                <div
                  className="col-sm-1 vertical-align vhr"
                  style={{ height: "100%" }}
                >
                  <Button bsStyle="primary" bsSize="small">
                    View Resume
                  </Button>
                </div>
              </Link>
              <div
                className="col-sm-1 vertical-align"
                style={{ height: "100%" }}
              >
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  onClick={() => this.toggleDownloadResume(each.resume)}
                >
                  Export &nbsp;
                  <Glyphicon glyph="download" />
                </Button>
              </div>
              <Link to={`/home/employer/jobopening/${each.job_opening}`}>
                <div
                  className="col-sm-2 vertical-align vhr"
                  style={{ height: "100%" }}
                >
                  <Button
                    bsStyle="primary"
                    bsSize="small"
                    href={`/home/employer/jobopening/${each.job_opening}`}
                  >
                    View Job Opening
                  </Button>
                </div>
              </Link>
              <div
                className="col-sm-1 vertical-align"
                style={{ height: "100%" }}
              >
                {/*<Glyphicon glyph="remove" onClick={() => this.toggleConfirmUnApply(each.pk)} />*/}
              </div>
            </Row>
            <hr className="hrZeroMargin" />
          </div>
        );
      });
    }
  }

  render() {
    return (
      <div className="col-sm-12">
        <div className="grayBoxElementUnderline">
          <h4>Activity</h4>
        </div>
        <div className="resumeformatContentArea">
          {this.createActivityTable()}
        </div>
        <DownloadResumeModal
          isOpen={this.state.downloadResumeOpen}
          toggleOpen={this.toggleDownloadResume}
          resumeId={this.state.resume_id}
          currentUserId={this.props.currentUser.id}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.sharedReducer.currentUser,
    jobOpeningApplicantMap: state.employerReducer.jobOpeningApplicantMap,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getJobOpeningApplicantMap /* unApplyToJob */ },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerActivityTable);
