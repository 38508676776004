import React from "react";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import axios from "axios";

export const getCompanyName = (field, experiences, useComma = false) => {
  let companyName = "";
  if (experiences.data && experiences.data.results.length > 0) {
    experiences.data.results.forEach((experience) => {
      if (field.experience === experience.pk) {
        companyName = useComma
          ? experience.companyName + ", "
          : experience.companyName;
        // don't return here it will be swallowed by the forEach implicit return
      }
    });
  }
  return companyName;
};

export const getCompanyNameExperienceArray = (
  field,
  experienceArray,
  useComma = false
) => {
  /*
        Newer version of above function that takes in an already formatted experience array
        instead of the raw experience request object.
    */
  let companyName = "";
  if (experienceArray) {
    experienceArray.forEach((experience) => {
      if (field.experience === experience.pk) {
        companyName = useComma
          ? experience.companyName + ", "
          : experience.companyName;
        // don't return here it will be swallowed by the forEach implicit return
      }
    });
  }
  return companyName;
};

export const truncate = (num, places) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
};

export const barOptions = [
  // Options much match those on server for this model (resume/models.py)
  {
    pk: 1,
    level: "Basic Knowledge",
  },
  {
    pk: 2,
    level: "Conversational",
  },
  {
    pk: 3,
    level: "Working Proficiency",
  },
  {
    pk: 4,
    level: "Fluent",
  },
  {
    pk: 5,
    level: "Expert",
  },
];

export const resume_owner_is_current_user = (resume, currentUser) => {
  if (resume && currentUser) {
    return resume.user_id === currentUser.id;
  }
  return false;
};

// Education Stuff
export const degreeOptions = [
  // Options much match those on server for this model (resume/models.py)
  {
    pk: 0,
    degree: "Associate Degree",
  },
  {
    pk: 1,
    degree: "Bachelors Degree",
  },
  {
    pk: 2,
    degree: "Masters Degree",
  },
  {
    pk: 3,
    degree: "PhD",
  },
  {
    pk: 4,
    degree: "MD",
  },
  {
    pk: 5,
    degree: "High School Diploma",
  },
  {
    pk: 6,
    degree: "Vocational School",
  },
  {
    pk: 7,
    degree: "Online Course",
  },
  {
    pk: 8,
    degree: "Training",
  },
  {
    pk: 9,
    degree: "Other",
  },
];

export const majorOptions = [
  "General Agriculture",
  "Agriculture Production And Management",
  "Agricultural Economics",
  "Animal Sciences",
  "Food Science",
  "Plant Science And Agronomy",
  "Soil Science",
  "Miscellaneous Agriculture",
  "Forestry",
  "Natural Resources Management",
  "Fine Arts",
  "Drama And Theater Arts",
  "Music",
  "Visual And Performing Arts",
  "Commercial Art And Graphic Design",
  "Film Video And Photographic Arts",
  "Studio Arts",
  "Miscellaneous Fine Arts",
  "Environmental Science",
  "Biology",
  "Biochemical Sciences",
  "Botany",
  "Molecular Biology",
  "Ecology",
  "Genetics",
  "Microbiology",
  "Pharmacology",
  "Physiology",
  "Zoology",
  "Neuroscience",
  "Miscellaneous Biology",
  "Cognitive Science And Biopsychology",
  "General Business",
  "Accounting",
  "Actuarial Science",
  "Business Management And Administration",
  "Operations Logistics And E-Commerce",
  "Business Economics",
  "Marketing And Marketing Research",
  "Finance",
  "Human Resources And Personnel Management",
  "International Business",
  "Hospitality Management",
  "Management Information Systems And Statistics",
  "Miscellaneous Business & Medical Administration",
  "Communications",
  "Journalism",
  "Mass Media",
  "Advertising And Public Relations",
  "Communication Technologies",
  "Computer And Information Systems",
  "Computer Programming And Data Processing",
  "Computer Science",
  "Information Sciences",
  "Computer Administration Management And Security",
  "Computer Networking And Telecommunications",
  "Mathematics",
  "Applied Mathematics",
  "Statistics And Decision Science",
  "Mathematics And Computer Science",
  "General Education",
  "Educational Administration And Supervision",
  "School Student Counseling",
  "Elementary Education",
  "Mathematics Teacher Education",
  "Physical And Health Education Teaching",
  "Early Childhood Education",
  "Science And Computer Teacher Education",
  "Secondary Teacher Education",
  "Special Needs Education",
  "Social Science Or History Teacher Education",
  "Teacher Education: Multiple Levels",
  "Language And Drama Education",
  "Art And Music Education",
  "Miscellaneous Education",
  "Library Science",
  "Architecture",
  "General Engineering",
  "Aerospace Engineering",
  "Biological Engineering",
  "Architectural Engineering",
  "Biomedical Engineering",
  "Chemical Engineering",
  "Civil Engineering",
  "Computer Engineering",
  "Electrical Engineering",
  "Engineering Mechanics Physics And Science",
  "Environmental Engineering",
  "Geological And Geophysical Engineering",
  "Industrial And Manufacturing Engineering",
  "Materials Engineering And Materials Science",
  "Mechanical Engineering",
  "Metallurgical Engineering",
  "Mining And Mineral Engineering",
  "Naval Architecture And Marine Engineering",
  "Nuclear Engineering",
  "Petroleum Engineering",
  "Miscellaneous Engineering",
  "Engineering Technologies",
  "Engineering And Industrial Management",
  "Electrical Engineering Technology",
  "Industrial Production Technologies",
  "Mechanical Engineering Related Technologies",
  "Miscellaneous Engineering Technologies",
  "Materials Science",
  "Nutrition Sciences",
  "General Medical And Health Services",
  "Communication Disorders Sciences And Services",
  "Health And Medical Administrative Services",
  "Medical Assisting Services",
  "Medical Technologies Technicians",
  "Health And Medical Preparatory Programs",
  "Nursing",
  "Pharmacy Pharmaceutical Sciences And Administration",
  "Treatment Therapy Professions",
  "Community And Public Health",
  "Miscellaneous Health Medical Professions",
  "Area Ethnic And Civilization Studies",
  "Linguistics And Comparative Language And Literature",
  "French German Latin And Other Common Foreign Language Studies",
  "Other Foreign Languages",
  "English Language And Literature",
  "Composition And Rhetoric",
  "Liberal Arts",
  "Humanities",
  "Intercultural And International Studies",
  "Philosophy And Religious Studies",
  "Theology And Religious Vocations",
  "Anthropology And Archeology",
  "Art History And Criticism",
  "History",
  "United States History",
  "Cosmetology Services And Culinary Arts",
  "Family And Consumer Sciences",
  "Military Technologies",
  "Physical Fitness Parks Recreation And Leisure",
  "Construction Services",
  "Electrical, Mechanical, And Precision Technologies And Production",
  "Transportation Sciences And Technologies",
  "Multi/Interdisciplinary Studies",
  "Court Reporting",
  "Pre-Law And Legal Studies",
  "Criminal Justice And Fire Protection",
  "Public Administration",
  "Public Policy",
  "N/A",
  "Physical Sciences",
  "Astronomy And Astrophysics",
  "Atmospheric Sciences And Meteorology",
  "Chemistry",
  "Geology And Earth Science",
  "Geosciences",
  "Oceanography",
  "Physics",
  "Multi-Disciplinary Or General Science",
  "Nuclear, Industrial Radiology, And Biological Technologies",
  "Psychology",
  "Educational Psychology",
  "Clinical Psychology",
  "Counseling Psychology",
  "Industrial And Organizational Psychology",
  "Social Psychology",
  "Miscellaneous Psychology",
  "Human Services And Community Organization",
  "Social Work",
  "Interdisciplinary Social Sciences",
  "General Social Sciences",
  "Economics",
  "Criminology",
  "Geography",
  "International Relations",
  "Political Science And Government",
  "Sociology",
  "Miscellaneous Social Sciences",
].sort();

export const COMPANY_EMPLOYEE_SIZE_ENUM_MAP = {
  0: "No Employees",
  1: "1 - 10",
  2: "11-50",
  3: "50-200",
  4: "300-500",
  5: "500-1000",
  6: "1000+",
};

export const RenderMajorSelect = ({
  label,
  name,
  initializedValue,
  type,
  onChange,
  options = null,
}) => {
  // TODO: Add error checking

  return (
    <FormGroup controlId="name">
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        componentClass={type}
        name={name}
        value={initializedValue ? initializedValue : null}
        onChange={onChange}
      >
        <option value={-1} key={-1}>
          Major
        </option>
        {options.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </FormControl>
    </FormGroup>
  );
};

export const RenderDegreeSelect = ({
  label,
  name,
  initializedValue,
  type,
  onChange,
  options = null,
}) => {
  // TODO: Add error checking

  return (
    <FormGroup controlId="name">
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        componentClass={type}
        name={name}
        value={initializedValue ? initializedValue : null}
        onChange={onChange}
      >
        <option value={-1} key={-1}>
          Degree
        </option>
        {options.map((item) => (
          <option value={item.degree} key={item.pk}>
            {item.degree}
          </option>
        ))}
      </FormControl>
    </FormGroup>
  );
};

export const is_form_valid = (requiredFields) => {
  for (let field of requiredFields) {
    if (!field) {
      alert("Please make sure all required fields are filled out.");
      return false;
    }
  }
  return true;
};

export const downloadResume = (resume_id, user_id) => {
  const url = `/resume/generate_pdf/${resume_id}/${user_id}`;
  const method = "GET";

  axios
    .request({
      url,
      method,
      responseType: "blob",
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "resume.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const downloadCoverLetter = (resume_version, cover_letter_id) => {
  const url = `/cover_upload/?resume_version=${resume_version}&cover_letter_id=${cover_letter_id}`;
  const token = localStorage.getItem("token");
  axios
    .get(url, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;

      let extension = null;
      if (data.type === "application/pdf") {
        extension = "pdf";
      } else if (data.type === "application/msword") {
        extension = "doc";
      } else if (
        data.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        extension = "docx";
      }

      if (!extension) {
        return null;
      }

      link.setAttribute("download", `cover_letter.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const fixTimezoneOffset = (timestampStr) => {
  // str time eg: "2022-04-09"
  if (!timestampStr) return null;
  return new Date(
    new Date(timestampStr).getTime() +
      new Date(timestampStr).getTimezoneOffset() * 60 * 1000
  );
};

const unitedStates = {
  AA: "Armed Forces Americas",
  AE: "Armed Forces Europe",
  AK: "Alaska",
  AL: "Alabama",
  AP: "Armed Forces Pacific",
  AR: "Arkansas",
  AS: "American Samoa",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District Of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MH: "Marshall Islands",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NP: "Northern Mariana Islands",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VI: "US Virgin Islands",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
};

// So happy that Canada and the US have distinct abbreviations
const provinces = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NF: "Newfoundland",
  NS: "Nova Scotia",
  NT: "Northwest Territory",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export const convertStateProvincesToFormOptionList = () => {
  // Converts to this array of dict format -> [{ id: 'AK', name: "Alaska" }, {}]
  // This way we have both a raw dict of states/provinces and a version that's form select parsable.

  const statesProvincesArray = [];

  for (let key in unitedStates) {
    statesProvincesArray.push(
        {id: key, name: unitedStates[key]}
    );
  }

  for (let key in provinces) {
    statesProvincesArray.push(
        {id: key, name: provinces[key]}
    );
  }
  return statesProvincesArray;
};


export const companySizeSelection = [
    // Frontend representation of COMPANYSIZE_CHOICES in the Company model.
    { id: 0, name: "No Employees" },
    { id: 1, name: "1 - 10" },
    { id: 2, name: "11-50" },
    { id: 3, name: "50-200" },
    { id: 4, name: "300-500" },
    { id: 5, name: "500-1000" },
    { id: 6, name: "1000+" },
];

// Changing values here require changing this on the backend utils as well.
export const referenceRelationshipOptions = [
    { id: 0, name: "--" },
    { id: 1, name: "Client" },
    { id: 2, name: "Colleague" },
    { id: 3, name: "Coworker" },
    { id: 4, name: "Customer" },
    { id: 5, name: "Manager" },
    { id: 6, name: "Mentee" },
    { id: 7, name: "Mentor" },
    { id: 8, name: "Relative" },
    { id: 9, name: "Student" },
    { id: 10, name: "Supervisor" },
    { id: 11, name: "Teacher" },
    { id: 12, name: "Team Lead" },
    { id: 13, name: "Teammate" },
    { id: 14, name: "Other" },
]

const updateField = (field, value) => {
  this.setState({ [field]: value });
};

export const handleChange = () => {
  this.updateField(event.target.name, event.target.value);
};

export const uploadResume = () => {
  // Upload a resume and call chatchat in gpt parse-resume-ai
  const url = `/resume/parse-resume-ai`;
  const token = localStorage.getItem('token');
  axios.get(url, { headers: {"Authorization" : `Bearer ${token}`} });
};
