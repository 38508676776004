
import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// Component Specific Imports
import {
    getUsers,
    getCurrentUser,
    getProfile,
    updateProfileField,
    patchProfile,
    getCareerTracks,
} from '../actions';

// Bootstrap Resources
import {Button, Row, Col, Panel, Form, ControlLabel} from 'react-bootstrap';
import RenderInput from '../shared/RenderInput';
import RenderSelect from '../shared/RenderSelect';


class ProfileSettings extends Component {

    componentDidMount () {
        this.props.getUsers();
        this.props.getProfile();
        this.props.getCurrentUser();
        this.props.getCareerTracks();
    }

    async onSubmit () {
        if (this.props.profile) {
            const {
                city,
                state,
                country,
                idealPosition,
                ifCurrentlySeeking,
                ifSponsorship,
                phoneNumber,
                careerTrack,
            } = this.props.profile;

            await this.props.patchProfile({
                city,
                state,
                country,
                idealPosition,
                ifCurrentlySeeking,
                ifSponsorship,
                phoneNumber,
                careerTrack,
            }, this.props.profile.pk);

            // Reload data below after saving above
            await this.props.getProfile();
            alert("Successfully Saved!");
        }
    };

    updateField = (field, value) => {
        // have this update redux store based on field in profile state via action directly
        this.props.updateProfileField(field, value);
    };

    handleChange = () => {
        this.updateField(event.target.name, event.target.value);
    };

    profileForm = () => {
        if ( _.isEmpty(this.props.profile)) return null;

        const {
            city,
            state,
            country,
            idealPosition,
            ifCurrentlySeeking,
            ifSponsorship,
            phoneNumber,
            careerTrack,
        } = this.props.profile;

        return (
            <Form>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='City*'
                          name='city'
                          placeholder='City'
                          initializedValue={city}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderInput
                          label='State*'
                          name='state'
                          placeholder='State'
                          initializedValue={state}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderSelect
                          label='Country*'
                          name='country'
                          type='select'
                          initializedValue={country}
                          onChange={this.handleChange}
                          options={[
                              {id:1, name: 'USA'}
                          ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderInput
                          label='Ideal Position*'
                          name='idealPosition'
                          placeholder='Ideal Position'
                          initializedValue={idealPosition}
                          type="input"
                          onChange={this.handleChange}
                        />
                    </Col>
                    <Col md={4}>
                        <ControlLabel>Phone Number*</ControlLabel>
                        <PhoneInput
                          placeholder='Phone Number'
                          country={'us'}
                          value={phoneNumber}
                          onChange={this.handleChange}
                          inputProps={{
                              name: 'phoneNumber'
                          }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderSelect
                          label='Actively searching for jobs?*'
                          name='ifCurrentlySeeking'
                          type='select'
                          initializedValue={ifCurrentlySeeking}
                          onChange={this.handleChange}
                          options={[
                              {id: false, name: 'No'},
                              {id: true, name: 'Yes'}
                          ]}
                        />
                    </Col>
                    <Col md={4}>
                        <RenderSelect
                          label='Career Track*'
                          name='careerTrack'
                          type='select'
                          initializedValue={careerTrack}
                          onChange={this.handleChange}
                          options={this.props.careerTracks}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <RenderSelect
                          label='Do you require sponsorship to work in this country?*'
                          name='ifSponsorship'
                          type='select'
                          initializedValue={ifSponsorship}
                          onChange={this.handleChange}
                          options={[
                              {id: false, name: 'No'},
                              {id: true, name: 'Yes'}
                          ]}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        return (
            <Panel style={{ marginBottom: '15px' }}>
                <Panel.Heading>
                    <Row>
                        <Col md={11}>
                            <p><strong>Profile Settings</strong></p>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    { this.profileForm() }
                </Panel.Body>
                <Panel.Footer>
                    <Button
                      onClick={() => this.onSubmit()}
                      style={{ marginLeft: '5px' }}
                    >
                        Save
                    </Button>
                </Panel.Footer>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        users: state.sharedReducer.users,
        currentUser: state.sharedReducer.currentUser,
        profile: state.sharedReducer.profile,
        careerTracks: state.sharedReducer.careerTracks,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getUsers,
        getProfile,
        getCurrentUser,
        updateProfileField,
        patchProfile,
        getCareerTracks,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
