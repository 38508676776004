
import React from 'react';

// Bootstrap Resources
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';


const RenderInput = ({ label, name, placeholder, value, type, onChange, rows=0, initializedValue=null, onBlur=null}) => {
    // TODO: Add error checking

    return (
        <FormGroup controlId='name'>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
              componentClass={type}
              name={name}
              placeholder={placeholder}
              rows={rows}
              defaultValue={initializedValue || null}
              value={value || null}
              onChange={onChange}
              onBlur={onBlur || (() => {})}
            />
        </FormGroup>
    );
};

export default RenderInput;
