import _, { cloneDeep } from 'lodash';
import * as events from '../actions/events';

// we can define state = intialState
// and define initial state json here.
const initialState = {
    resume: {}, // individual resume currently active
    resumes: [],  // list of resumes
    summary: {
        data: {
            results: null
        }
    },
    coverLetter: {
        data: {
            results: null
        }
    },
    hasAccessToResume: false,
    highlights: {},
    educations: {},
    schools: {},
    rawLanguages: {},
    languages: {},
    references: {},
    experiences: {},
    skillGroups: {},
    userSkillMap: {},
    skillHours: {},
    companies: [],
    company: {},
    skillModal: {
        isOpen: false
    },
    summaryModal: {
        isOpen: false
    },
    highlightsModal: {
        isOpen: false
    },
    languageModal: {
        isOpen: false
    },
    educationModal: {
        isOpen: false
    },
    referencesModal: {
        isOpen: false
    },
    experienceModal: {
        isOpen: false
    },
    deleteResumeItemModal: {
        isOpen: false,
        itemName: null,
        deleteItemFn: null,
        relatedItems: null,
        cascadeDeleteItem: null
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case events.DELETE_RESUME: {
            const modified = state.resumes.filter((resume) => resume.pk !== action.payload);
            return {
                ...state,
                resumes: modified
            };
        }
        case events.CREATE_RESUME: {
            const newResume = action.payload.data ? action.payload.data : null;
            let modified = [];

            if (state.resumes) {
                modified = state.resumes;
            }

            if (newResume && modified) {
                modified.push(newResume);
            }

            return {
                ...state,
                resumes: modified
            };
        }
        case events.EDIT_RESUME: {
            const editResume = action.payload.data ? action.payload.data : null;
            let modified = [];

            if (state.resumes) {
                modified = state.resumes;
            }

            modified.find((eachResume, i) => {
                if (eachResume.pk === editResume.pk) {
                    modified[i] = editResume;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                resumes: modified
            };
        }
        case events.GET_RESUMES:
            return {
                ...state,
                resumes: action?.payload?.data?.results ? action.payload.data.results : []
            };
        // we have both resume and resumes simultaneously.
        // then we won't be able to show all resume and one resume simultaneously.
        case events.GET_RESUME:
            return {
                ...state,
                resume: action.payload && action.payload.data ?
                    action.payload.data :
                    {}
            };
        case events.GET_ACCESS_TO_RESUME:
                return {
                ...state,
                hasAccessToResume: action.payload && action.payload.data ?
                    action.payload.data :
                    false
            };
        case events.GET_SUMMARY:
            return {
                ...state,
                summary: action?.payload?.data?.results?.length > 0 ? action.payload.data.results[0] : null
            };
        case events.SUMMARY_UPDATE_SUCCESS:
            return {
                ...state,
                summary: action.payload.data ? action.payload.data : null
            };
        case events.GET_COVER_LETTER:
            return {
                ...state,
                coverLetter: action?.payload?.data?.results?.length > 0 ? action.payload.data.results[0] : null
            };
        case events.COVER_LETTER_UPDATE_SUCCESS:
            return {
                ...state,
                coverLetter: action.payload.data ? action.payload.data : null
            };
        case events.GET_HIGHLIGHTS:
            return {
                ...state,
                highlights: action.payload
            };
        case events.HIGHLIGHT_CREATE_SUCCESS: {
            const highlight = action.payload.data ? action.payload.data : null;
            const modifiedHighlight = state.highlights.data.results;

            if (highlight) {
                modifiedHighlight.push(highlight);
            }

            return {
                ...state,
                highlights: {
                    ...state.highlights,
                    data: {
                        ...state.highlights.data,
                        results: modifiedHighlight
                    }
                }
            }
        }
        case events.HIGHLIGHT_UPDATE_SUCCESS: {
            const highlight = action.payload.data ? action.payload.data : null;
            const modifiedHighlights = state.highlights.data.results;

            modifiedHighlights.find((eachHighlight, i) => { // dump updated reference back into modifiedReferences array
                if (eachHighlight.pk === highlight.pk) {
                    modifiedHighlights[i] = highlight;
                    return true; // stop searching
                }
            });

            return {
                // TODO: Verify we next highlight state like this and not flattened
                ...state,
                highlights: {
                    ...state.highlights,
                    data: {
                        ...state.highlights.data,
                        results: modifiedHighlights
                    }
                }
            }
        }
        case events.DELETE_HIGHLIGHT:
            let tempHighlights = cloneDeep(state.highlights.data ? state.highlights.data.results : []);
            tempHighlights.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from tempHighlights
                    tempHighlights.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                highlights: {
                    ...state.highlights,
                    data: {
                        ...state.highlights.data,
                        results: tempHighlights
                    }
                }
            };
        case events.GET_EDUCATIONS:
            return {
                ...state,
                educations: action.payload
            };
        case events.GET_SCHOOLS:
            return {
                ...state,
                schools: action.payload ?
                    action.payload.data.results :
                    []
            };
        case events.SCHOOL_CREATE_SUCCESS: {
            const school = action.payload.data ? action.payload.data : null;
            const modified = state.schools;

            if (school) {
                modified.push(school);
            }

            return {
                ...state,
                schools: modified
            }
        }
        case events.GET_RAW_LANGUAGES:
            return {
                ...state,
                rawLanguages: action.payload
            };
        case events.GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };
        case events.LANGUAGE_CREATE_SUCCESS: {
            const language = action.payload.data ? action.payload.data : null;
            const modified = state.languages.data.results;

            if (language) {
                modified.push(language);
            }

            return {
                ...state,
                languages: {
                    ...state.languages,
                    data: {
                        ...state.languages.data,
                        results: modified
                    }
                }
            }
        }
        case events.LANGUAGE_UPDATE_SUCCESS:
            const language = action.payload.data ? action.payload.data : null;
            const modifiedLanguage = state.languages.data.results;

            modifiedLanguage.find((eachRef, i) => {
                if (eachRef.pk === language.pk) {
                    modifiedLanguage[i] = language;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                languages: {
                    ...state.languages,
                    data: {
                        ...state.languages.data,
                        results: modifiedLanguage
                    }
                }
            };
        case events.DELETE_LANGUAGE:
            let tempLanguages = cloneDeep(state.languages.data ? state.languages.data.results : []);
            tempLanguages.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from tempLanguages
                    tempLanguages.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                languages: {
                    ...state.languages,
                    data: {
                        ...state.languages.data,
                        results: tempLanguages
                    }
                }
            };
        case events.EDUCATION_CREATE_SUCCESS: {
            const education = action.payload.data ? action.payload.data : null;
            const modifiedEducation = state.educations.data.results;

            if (education) {
                modifiedEducation.push(education);
            }

            return {
                ...state,
                educations: {
                    ...state.educations,
                    data: {
                        ...state.educations.data,
                        results: modifiedEducation
                    }
                }
            }
        }
        case events.EDUCATION_UPDATE_SUCCESS: {
            const education = action.payload.data ? action.payload.data : null;
            const modifiedEducation = state.educations.data.results;

            modifiedEducation.find((eachRef, i) => {
                if (eachRef.pk === education.pk) {
                    modifiedEducation[i] = education;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                educations: {
                    ...state.educations,
                    data: {
                        ...state.educations.data,
                        results: modifiedEducation
                    }
                }
            }
        }
        case events.DELETE_EDUCATION:
            let tempEducations = cloneDeep(state.educations.data ? state.educations.data.results : []);
            tempEducations.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from tempEducations
                    tempEducations.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                educations: {
                    ...state.educations,
                    data: {
                        ...state.educations.data,
                        results: tempEducations
                    }
                }
            };
        case events.GET_REFERENCES:
            return {
                ...state,
                references: action.payload
            };
        case events.REFERENCE_CREATE_SUCCESS: {
            const reference = action.payload.data ? action.payload.data : null;
            const modifiedReferences = state.references.data.results;

            if (reference) {
                modifiedReferences.push(reference);
            }

            return {
                ...state,
                references: {
                    ...state.references,
                    data: {
                        ...state.references.data,
                        results: modifiedReferences
                    }
                }
            }
        }
        case events.REFERENCE_UPDATE_SUCCESS: {
            const reference = action.payload.data ? action.payload.data : null;
            const modifiedReferences = state.references.data.results;

            modifiedReferences.find((eachRef, i) => { // dump updated reference back into modifiedReferences array
                if (eachRef.pk === reference.pk) {
                    modifiedReferences[i] = reference;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                references: {
                    ...state.references,
                    data: {
                        ...state.references.data,
                        results: modifiedReferences
                    }
                }
            }
        }
        case events.DELETE_REFERENCE:
            let tempReferences = cloneDeep(state.references.data ? state.references.data.results : []);
            tempReferences.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from tempReferences
                    tempReferences.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                references: {
                    ...state.references,
                    data: {
                        ...state.references.data,
                        results: tempReferences
                    }
                }
            };
        case events.GET_EXPERIENCES:
            return {
                ...state,
                experiences: action.payload
            };
        case events.EXPERIENCE_CREATE_SUCCESS: {
            const experience = action.payload.data ? action.payload.data : null;
            const modifiedExperience = state.experiences.data.results;

            if (experience) {
                modifiedExperience.push(experience);
            }

            modifiedExperience.sort(
                (experience_a, experience_b) => {
                    if (experience_a.startDate !== experience_b.startDate) {
                        // primary sort - ascending order
                        return experience_a.startDate < experience_b.startDate ? 1 : -1
                    }
                    else {
                        // use secondary sort if start dates are equal - ascending order
                        if (experience_a.endDate !== experience_b.endDate) {
                            return experience_a.endDate < experience_b.endDate ? 1 : -1
                        }
                        else {
                            // tertiary sort if both above are equal - ascending order
                            return experience_a.company.companyName > experience_b.company.companyName ? 1 : -1
                        }
                    }
                }
            );

            return {
                ...state,
                experiences: {
                    ...state.experiences,
                    data: {
                        ...state.experiences.data,
                        results: modifiedExperience
                    }
                }
            };
        }
        case events.EXPERIENCE_UPDATE_SUCCESS: {
            const experience = action.payload.data ? action.payload.data : null;
            const modifiedExperience = state.experiences.data.results;

            modifiedExperience.find((eachRef, i) => {
                if (eachRef.pk === experience.pk) {
                    modifiedExperience[i] = experience;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                experiences: {
                    ...state.experiences,
                    data: {
                        ...state.experiences.data,
                        results: modifiedExperience
                    }
                }
            };
        }
        case events.DELETE_EXPERIENCE:
            let tempExperiences = cloneDeep(state.experiences.data ? state.experiences.data.results : []);
            tempExperiences.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from tempExperiences
                    tempExperiences.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                experiences: {
                    ...state.experiences,
                    data: {
                        ...state.experiences.data,
                        results: tempExperiences
                    }
                }
            };
        case events.SKILL_GROUP_CREATE_SUCCESS: {
            const skillGroup = action.payload.data ? action.payload.data : null;
            const modifiedSkillGroup = state.skillGroups.data.results;

            if (skillGroup) {
                modifiedSkillGroup.push(skillGroup);
            }

            return {
                ...state,
                skillGroups: {
                    ...state.skillGroups,
                    data: {
                        ...state.skillGroups.data,
                        results: modifiedSkillGroup
                    }
                }
            };
        }
        case events.GET_SKILL_GROUPS:
            return {
                ...state,
                skillGroups: action.payload
            };
        case events.SKILL_GROUP_UPDATE_SUCCESS: {
            const skillGroup = action.payload.data ? action.payload.data : null;
            const modified = state.skillGroups.data.results;

            modified.find((eachRef, i) => {
                if (eachRef.pk === skillGroup.pk) {
                    modified[i] = skillGroup;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                skillGroups: {
                    ...state.skillGroups,
                    data: {
                        ...state.skillGroups.data,
                        results: modified
                    }
                }
            }
        }
        case events.DELETE_SKILL_GROUP: {
            let modified = cloneDeep(state.skillGroups.data ? state.skillGroups.data.results : []);

            modified.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from modified
                    modified.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                skillGroups: {
                    ...state.skillGroups,
                    data: {
                        ...state.skillGroups.data,
                        results: modified
                    }
                }
            }
        }
        case events.GET_USER_SKILL_MAP:
            return {
                ...state,
                userSkillMap: action.payload
            };
        case events.USER_SKILL_MAP_CREATE_SUCCESS: {
            const userSkillMap = action.payload.data ? action.payload.data : null;
            const modified = state.userSkillMap.data.results;

            if (userSkillMap) {
                modified.push(userSkillMap);
            }

            return {
                ...state,
                userSkillMap: {
                    ...state.userSkillMap,
                    data: {
                        ...state.userSkillMap.data,
                        results: modified
                    }
                }
            }
        }
        case events.USER_SKILL_MAP_UPDATE_SUCCESS: {
            const userSkillMap = action.payload.data ? action.payload.data : null;
            const modified = state.userSkillMap.data.results;

            modified.find((eachRef, i) => {
                if (eachRef.pk === userSkillMap.pk) {
                    modified[i] = userSkillMap;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                userSkillMap: {
                    ...state.userSkillMap,
                    data: {
                        ...state.userSkillMap.data,
                        results: modified
                    }
                }
            }
        }
        case events.DELETE_USER_SKILL_MAP: {
            let modified = cloneDeep(state.userSkillMap.data ? state.userSkillMap.data.results : []);
            modified.map((eachRef, i) => {
                if (eachRef.pk === action.payload) {
                    // delete it from modified
                    modified.splice(i, 1);
                }
            });

            // remove this from state.
            return {
                ...state,
                userSkillMap: {
                    ...state.userSkillMap,
                    data: {
                        ...state.userSkillMap.data,
                        results: modified
                    }
                }
            }
        }
        case events.GET_SKILL_HOURS:
            return {
                ...state,
                skillHours: action.payload
            };
        case events.POST_SKILL_HOURS:
            // ensure new item added to skillHours array.
            const newSkillHrEx = action.payload.data ? action.payload.data : null;
            const modifiedPostResults = state.skillHours.data.results;

            if (newSkillHrEx) {
                modifiedPostResults.push(newSkillHrEx);
            }

            return {
                ...state,
                skillHours: {
                    ...state.skillHours,
                    data: {
                        ...state.skillHours.data,
                        results: modifiedPostResults
                    }
                }
            };
        case events.PUT_SKILL_HOURS:
            const updatedSkillHrEx = action.payload.data ? action.payload.data : null;
            const modifiedResults = state.skillHours.data.results;

            modifiedResults.find((eachRef, i) => {
                if (eachRef.pk === updatedSkillHrEx.pk) {
                    modifiedResults[i] = updatedSkillHrEx;
                    return true; // stop searching
                }
            });

            return {
                ...state,
                skillHours: {
                    ...state.skillHours,
                    data: {
                        ...state.skillHours.data,
                        results: modifiedResults
                    }
                }
            };
        // TODO: consider lifting below to the SharedReducer file
        case events.GET_COMPANIES:
            return {
                ...state,
                companies: action.payload ?
                    action.payload.data.results :
                    []
            };
                case events.GET_COMPANIES:
        case events.GET_COMPANY:
            return {
                ...state,
                company: action.payload && action.payload.data ?
                    action.payload.data :
                    {}
            };
        case events.COMPANY_CREATE_SUCCESS: {
            const company = action.payload.data ? action.payload.data : null;
            const modified = state.companies;

            if (company) {
                modified.push(company);
            }

            return {
                ...state,
                companies: modified
            }
        }

        /* Dialog States */
        case events.TOGGLE_SKILL_MODAL:
            return {
                ...state,
                skillModal: {
                    isOpen: !state.skillModal.isOpen
                }
            };
        case events.TOGGLE_SUMMARY_MODAL:
            return {
                ...state,
                summaryModal: {
                    isOpen: !state.summaryModal.isOpen
                }
            };
        case events.TOGGLE_HIGHLIGHTS_MODAL:
            return {
                ...state,
                highlightsModal: {
                    isOpen: !state.highlightsModal.isOpen
                }
            };
        case events.TOGGLE_LANGUAGE_MODAL:
            return {
                ...state,
                languageModal: {
                    isOpen: !state.languageModal.isOpen
                }
            };
        case events.TOGGLE_EDUCATION_MODAL:
            return {
                ...state,
                educationModal: {
                    isOpen: !state.educationModal.isOpen
                }
            };
        case events.TOGGLE_REFERENCES_MODAL:
            return {
                ...state,
                referencesModal: {
                    isOpen: !state.referencesModal.isOpen
                }
            };
        case events.TOGGLE_EXPERIENCE_MODAL:
            return {
                ...state,
                experienceModal: {
                    isOpen: !state.experienceModal.isOpen
                }
            };
        case events.TOGGLE_DELETE_RESUME_ITEM_MODAL:
            return {
                ...state,
                deleteResumeItemModal: {
                    isOpen: !state.deleteResumeItemModal.isOpen,
                    itemName: action.payload.itemName,
                    deleteItemFn: action.payload.deleteItemFn,
                    relatedItems: action.payload.relatedItems,
                    cascadeDeleteItem: action.payload.cascadeDeleteItem
                }
            };
        default:
            return state;
    }
}
