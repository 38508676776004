
// React Resources
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Libs
import moment from 'moment'

// Bootstrap Resources
import { Glyphicon, Panel } from 'react-bootstrap';


// const weekdays = [
//     'null',
//     'Monday',
//     'Tuesday',
//     'Wednesday',
//     'Thursday',
//     'Friday',
//     'Saturday',
//     'Sunday'
// ];
//
// const ampm = [
//     'am',
//     'pm'
// ];

const ReferencesPreviewPanel = ({ reference, companyName }) => (
    <Panel.Body>
        {/*<Glyphicon glyph="time" />*/}
        {/*<p>&nbsp;{ weekdays[reference.timeAvailableStartDate] } - { weekdays[reference.timeAvailableEndDate] }*/}
        {/*   &nbsp;between { reference.timeAvailableStartTime }{ ampm[reference.startTimeAMPM] } to { reference.timeAvailableEndTime }{ ampm[reference.endTimeAMPM] }*/}
        {/*</p>*/}
        {/*<br/>*/}
        <Glyphicon glyph="envelope" /> <p>{ reference.refEmail }</p>
        <br/>
        <Glyphicon glyph="phone" /> <p>{ reference.refPhone }</p>
    </Panel.Body>
);

export default ReferencesPreviewPanel;