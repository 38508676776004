import _, { cloneDeep } from 'lodash';
import * as events from '../actions/events';

// we can define state = intialState
// and define initial state json here.
const initialState = {
    jobOpenings: [],
    jobOpening: {
        role: '',
        description: '',
        employer_internal_job_id: null,
        company: {
            city: '',
            companyEmail: '',
            companyName: '',
            companyWebsite: '',
            companySize: 0
        }
    },
    skills: [],
    jobOpeningSkillMap: [],
    jobOpeningApplicantMap: [],
    companyApplicantMap: [],
    school: {},
    jobFairs: [],
    enrolledJobFairs: [],
    jobFair: null,
    employersJobFair: {},
    employer_of_recruiter: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case events.GET_JOB_OPENINGS:
            return {
                ...state,
                jobOpenings: (action.payload && action.payload.data) ?
                    action.payload.data :
                    []
            };
        case events.GET_JOB_OPENING:
            return {
                ...state,
                jobOpening: (action.payload && action.payload.data) ?
                    action.payload.data :
                    null
            };
        case events.CREATE_JOB_OPENING: {
            const new_job_opening = action.payload.data ? action.payload.data : null;
            let modified = [];

            if (state.jobOpenings) {
                modified = state.jobOpenings;
            }

            if (new_job_opening) {
                modified.push(new_job_opening);
            }

            return {
                ...state,
                jobOpenings: modified
            };
        }
        case events.JOB_OPENING_UPDATE_SUCCESS: {
            return {
                ...state,
                jobOpening: (action.payload && action.payload.data) ?
                    action.payload.data :
                    null
            };
        }
        case events.DELETE_JOB_OPENING: {
            // job opening pk different than the pk here where jobOpening state here stores jobOpeningMap, thus its pk iis
            // job_opening_map pk
            const modified = state.jobOpenings.filter((jobOpening) => jobOpening.job_opening_pk !== action.payload);
            return {
                ...state,
                jobOpenings: modified
            };
        }
        case events.GET_SKILLS:
            return {
                ...state,
                skills: action.payload ?
                    action.payload.data.results :
                    []
            };
        case events.SKILL_CREATE_SUCCESS: {
            const new_skill = action.payload.data ? action.payload.data : null;
            const modified = state.skills;

            if (new_skill) {
                modified.push(new_skill);
            }

            return {
                ...state,
                skills: modified
            }
        }
        case events.GET_JOB_OPENING_SKILL_MAP:
            return {
                ...state,
                jobOpeningSkillMap: action.payload ?
                    action.payload.data.results :
                    []
            };
        case events.GET_JOB_OPENING_APPLICANT_MAP:
            // todo do action.payload.data.results with results instead
            return {
                ...state,
                jobOpeningApplicantMap: action.payload ?
                    action.payload.data :
                    []
            };
        case events.UN_APPLY_TO_JOB_SUCCESS: {
            const modified = state.jobOpeningApplicantMap.filter((each) => each.pk !== action.payload);
            return {
                ...state,
                jobOpeningApplicantMap: modified
            };
        }
        case events.GET_SCHOOL:
            return {
                ...state,
                school: action.payload ?
                    action.payload.data :
                    {}
            };
        case events.GET_JOB_FAIRS:
            return {
                ...state,
                jobFairs: action.payload ?
                    action.payload.data :
                    {}
            };
        case events.GET_ENROLLED_JOB_FAIRS:
            return {
                ...state,
                enrolledJobFairs: action.payload ?
                    action.payload.data :
                    {}
            };
        case events.GET_JOB_FAIR:
            return {
                ...state,
                jobFair: action.payload ?
                    action.payload.data :
                    {}
            };
        case events.GET_EMPLOYERS_ATTENDING_JOB_FAIRS:
            return {
                ...state,
                employersJobFair: (action.payload && action.payload.data) ?
                    action.payload.data :
                    {}
            };
        case events.GET_COMPANY_APPLICANT_MAP:
            return {
                ...state,
                companyApplicantMap: (action.payload && action.payload.data) ?
                    action.payload.data.results :
                    []
            };
        case events.GET_EMPLOYER_FOR_EMPLOYEE:
            return {
                ...state,
                employer_of_recruiter: action.payload ?
                    action.payload.data :
                    {}
            };
        default:
            return state;
    }
}
